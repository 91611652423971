import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";


class Terms extends React.Component {
    render() {
        return(
            <div className="App Terms">
                <Header  hide='true' hideSearch={true} />
                <main>
                    <p>
                    <strong><u>תקנון הזמנה ותנאי שימוש – "עפנו"</u></strong><br />
                            <br />
                            הי! שמחים שבחרתם בנו ואפילו טרחתם להיכנס לדף תנאי השימוש שלנו!<br />
                            <br />
                            <strong>השימוש באתר ובשירותים שלנו מהווה הסכמתכם לתנאי שימוש אלו. אם אינכם מסכימים להם, אינכם רשאים לעשות שימוש באתר או בשירותים שלנו. שימו לב, על השירותים עצמם אותם תזמינו דרכינו חלים התנאים של ספקי השירותים.</strong><br />
                            <br />
                            השתדלנו שתנאי השימוש שלנו יהיו נוחים לקריאה ומובנים ככל האפשר, אז אנא קחו מספר רגעים וקראו אותם היטב.<br />
                            <br />
                            אנחנו כמובן כאן לכל שאלה.<br />
                            <br />
                            <strong><u>מי אנחנו</u></strong><br />
                            <br />
                            אנחנו עפנו, אתר לשירותי תיירות המופעל על ידי חברת טריפ גייטור בע"מ, תאגיד מס' 516111507 ("<strong>עפנו</strong>", "<strong>אנחנו</strong>", "<strong>אנו</strong>" וביטויים דומים). אנו סוכנות נסיעות שמתווכת ביניכם, הנוסעים, לבין ספקים שונים שמספקים לכם את השירותים שהזמנתם או שאתם שוקלים להזמין, כגון חברות התעופה, חברות השכרת רב, בתי מלון, וכיו"ב ("<strong>הספקים</strong>, או "<strong>ספקי השירותים</strong>").<br />
                            <br />
                            את דרכי הקשר עמנו ניתן למצוא בהמשך תנאי שימוש אלה תחת סעיף "יצירת קשר".<br />
                            <br />
                            <strong>הספקים הם אלה שמספקים לנו את המידע אודות השירותים שלהם וזמינותם, הם אלה שמספקים לכם בפועל את השירותים השונים, והם בלבד, ולא אנחנו, אלה שאחראים לאספקתם, לאיכותם, ולתקלות במתן השירותים. </strong><br />
                            <br />
                            <strong><u>הזמנות</u></strong><br />
                            <br />
                            ההזמנות המבוצעות דרך אתר עפנו הן בקשות להזמנה של שירותים שונים של ספקים, ואישור ההזמנה על ידי עפנו במועד ביצוע ההזמנה הוא אישור זמני, שכפוף לאישורי ההזמנה של ספקי השירות כגון חברות התעופה שמהן נרכשות כרטיסי הטיסה, חברות השכרת הרכב שמשכירות לכם את הרכבים, בתי המלון בהם תלונו, ועוד.<br />
                            <br />
                            הרכישה באתר מותרת לגילאי 18 שנים ומעלה בעלי אזרחות ישראלית ו/או תושבות ישראלית בלבד.<br />
                            <br />
                            אישור סופי להזמנתכם יינתן על ידינו רק לאחר קבלת אישור סופי מאת הספקים ומאת חברת כרטיס האשראי בו נעשה שימוש בביצוע ההזמנה. <strong>הזמנות שלא אושרו באופן סופי ושלא שולמו במלואן אינן מחייבות אותנו או את הספקים בשום צורה. בשימוש באתר ו/או בביצוע הזמנות או שימוש אחר בשירותים שלנו אתם מוותרים על כל זכות קיזוז כלפינו.</strong><br />
                            <br />
                            לתשומת ליבכם, ייתכן פער זמנים מסוים בין ביצוע הזמנה באתר עפנו לבין הזנת בקשת ההזמנה על ידינו אצל הספקים השונים. אנו נשתדל שפער זמנים זה יהיה קטן ככל האפשר בנסיבות העניין, אך איננו יכולים להבטיח שהמוצרים והשירותים שביקשתם להזמין דרכנו יהיו תמיד זמינים במועד שבו הבקשה עברה לספקים.<br />
                            <br />
                            כחלק מתהליך ביצוע ההזמנות, תידרשו לספק לנו מידע כגון שם, פרטי קשר, פרטי כרטיס אשראי, ועוד. אנחנו מסתמכים על המידע שאתם מוסרים לנו וכך גם הספקים. עליכם לוודא שהמידע נכון, מלא ועדכני בעת מסירתו. מסירה לא נכונה של מידע על ידכם עלולה למנוע או להקשות עלינו לספק לכם את השירותים שלנו ועל הספקים את השירותים שלהם, או ליצור עמכם קשר במקרה הצורך.<br />
                            <br />
                            <strong><u>מחירים</u></strong><br />
                            <br />
                            המחירים באתר משתנים מעת לעת, לעיתים תוך זמן קצר מאד. איננו שומרים מחירים אלא אם הוסכם עמכם במפורש אחרת בכתב. עליות מחירים של הספקים אינן בשליטתנו והן חלות גם על הזמנות שכבר בוצעו אך טרם אושרו על ידינו סופית וחויבו במלואן. כל המחירים הם לתושבי ישראל האזרחים בלבד ותקפים להזמנות דרך האתר (און ליין).<br />
                            <br />
                            מיסי נמל והיטלים דומים אינן בשליטתנו, עלולים להשתנות לאחר אישור ההזמנה הסופי ובמקרה כזה עליכם לשלם את ההפרש. בערים מסוימות מוטלים מיסים על תיירים הלנים בבתי המלון בעיר בהתבסס על מספר הלילות, רמת בית המלון ועוד. איננו יכולים לגבות את המיסים הללו מראש בישראל, הם ייגבו על ידי בית המלון ישירות מכם ואינם כלולים במחיר הזמנתכם דרכנו.<br />
                            <br />
                            אנחנו מחייבים אתכם בשקלים חדשים. מחירים הנקובים במטבע זר יומרו לשקלים חדשים בעת ביצוע ההזמנה בהתבסס על שער העברות והמחאות מכירה הרלוונטי במועד החיוב לפי פרסומי בנק בישראל. בנוסף ייתכן שייגבו מכם עמלות המרה כנהוג אצל הבנק או ספק כרטיס האשראי שלכם.<br />
                            <br />
                            <strong><u>ביצוע תשלומים</u></strong><br />
                            <br />
                            לצורך ביצוע הזמנות באתר, תצטרכו בין היתר למסור את פרטי כרטיס האשראי שלכם שבאמצעותו תשלמו עבור השירותים אותם תרצו להזמין. חיוב בפועל יבוצע רק עם אישור הזמנה סופי ומותנה באישור החברה המנפיקה את כרטיס האשראי שלכם.<br />
                            <br />
                            <strong><u>מועדון לקוחות</u></strong><br />
                            <br />
                            תנאי מועדון הלקוחות שלנו נמצאים <a href="/members/terms.html">לחץ כאן</a>. תנאי מועדון הלקוחות חלים על החברים בהם בנוסף לתנאי שימוש אלה ואינם גורעים מהם. ייתכן שנציע לחברי מועדון הלקוחות שלנו מפעם לפעם הטבות ותנאים אחרים אשר אינם זמינים למי שאינם חברים בהם.<br />
                            <br />
                            <strong><u>אחריות והגבלת אחריות – שלנו ושל צדדים שלישיים</u></strong><br />
                            <br />
                            כאמור, הספקים ולא אנחנו הם אלו שמספקים לכם את השירותים שהוזמנו על ידכם והם אלו שאחראיים לגביהם. אנחנו מזמינים עבורכם שירותים מאת הספקים, וכמובן שננהג מולכם בשקיפות ובאחריות ונגלה לכם אודות פגמים משמעותיים בשירותים אם אלו ידועים לנו, אך <strong>אין לנו שליטה על הספקים, אנו מסתמכים על המידע שאתן והספקים מוסרים לנו ואיננו אחראיים למעשים ולמחדלים של הספקים</strong>.<br />
                            <br />
                            מבלי לגרוע מכלליות האמור, בין היתר, איננו אחראיים על שינויים וביטולים בטיסות, שינויים או ביטולים בבתי מלון, שינויים וביטולים מסיבות רפואיות או אישיות, על סוג או איכות הרכב אשר יסופק על ידי חברת השכרת הרכב, על רמת שירות לא נאותה של ספק כזה או אחר, על תקלות באספקת השירותים, על אי אספקת שירותים או על מידע לא נכון או מטעה שמסר ספק.<br />
                            <br />
                            בנוסף ומבלי לגרוע מכלליות האמור, <strong>איננו אחראיים לכל נסיבה או גורם אחרים אשר אינם נגרמים ישירות עקב מעשה או מחדל שלנו</strong>, גם אם אלו יכולים להשפיע על צריכת השירותים על ידיכם. כך, בין היתר, איננו אחראים על תוצאות מזג אוויר ואירועי כוח עליון אחרים, שביתות והשבתות, ימי חג, מצב ביטחוני, חקיקה ודרישות של רשויות ממשלתיות ושינויים בהם, היעדר הצטיידות או חוסר התאמה של אשרות כניסה, דרכונים ואישורים ומסמכים אחרים, אי הגעה במועד לטיסה או לבית המלון, סירוב לאפשר עלייה לטיסה מסיבות ביטחוניות, התנהגות או אחרת, ועוד.<br />
                            <br />
                            למעט היכן שהדין לא מאפשר לנו להגביל את או להתנות על אחריותנו, <strong>גם אם יימצא שאנו אחראיים כלפיכם בעניין מסוים, בשום מקרה לא נהיה אחראיים בגין נזקים עקיפים או תוצאתיים מכל סוג</strong>. בשום מקרה איננו אחראיים על שירותים שרכשתם מהספקים שלא דרכינו או בגין מעשי או מחדלי הספקים. הגבלות האחריות שלנו אינן חלות על אחריות לנזק גוף או למעשה זדון המוטלת עלינו על פי דין.<br />
                            <br />
                            ספקי השירותים נוהגים להכניס תניות פטור והגבלות אחריות בתנאי מתן השירותים שלהם, וייתכן שהחוק במדינה הרלוונטית פוטר אותם במקרים מסוימים מאחריות או מגביל את אחריותם. ייתכן שיש לכם זכויות כלפי ספקים תחת חוקי המדינה הרלוונטית שאינם מופיעים בתנאי מתן השירותים של הספקים.<br />
                            <br />
                            <strong><u>פרטיות</u></strong><br />
                            <br />
                            פרטיותכם חשובה לנו. מדיניות הפרטיות שלנו זמינה <a href="privacy.html">לחץ כאן</a>, והיא מהווה חלק מתנאי השימוש שלנו.<br />
                            <br />
                            <strong><u>ביטולים ודמי ביטול</u></strong><br />
                            <br />
                            אם חוק הגנת הצרכן, תשמ"א-1981 ותקנות הגנת הצרכן (ביטול עסקה), תשע"א-2010 ("<strong>חוק הגנת הצרכן</strong>" ו-"<strong>תקנות הגנת הצרכן</strong>", בהתאמה, וביחד "<strong>דיני הגנת הצרכן</strong>") חלים עליכם ועל הזמנתכם, ביטולי הזמנות יהיו על פי זכויותיכם תחת דיני הגנת הצרכן. לדרכי הקשר עמנו למתן הודעת ביטול, ראו סעיף "יצירת קשר" בהמשך תנאי שימוש אלו.<br />
                            <br />
                            במקרה כזה, <strong>אנו נגבה דמי ביטול בשיעורים המרביים המותרים לפי דיני הגנת הצרכן</strong> (נכון לכתיבת תנאי שימוש אלה, 5% משווי ההזמנה או 100 שקלים חדשים, הנמוך מביניהם). זאת, אלא אם הביטול נעשה בגין פגם, אי התאמה או הפרת הסכם כאמור בסעיף 14ה(א) לחוק הגנת הצרכן וחוק הגנת הצרכן אינו מאפשר גביית דמי ביטול.<br />
                            <br />
                            האמור אינו גורע מזכותכם לבטל הזמנה עקב הפרת חוזה על ידינו על פי חוק החוזים (תרופות בשל הפרת חוזה), תשל"א-1970 ובכפוף לאמור בו, ובלבד שנתתם לנו הודעה בכתב זמן סביר מראש – שלא יפחת מ-7 ימים – על כוונתכם לעשות כן אם לא תתוקן ההפרה (בין אם ההפרה יסודית ובין אם לאו), ולא תיקנו את ההפרה תוך אותו זמן סביר.<br />
                            <br />
                            במקרים בהם אין לכם זכות ביטול מכוח דיני הגנת הצרכן (או כפי שיוסבר להלן, בעסקת מכר מרחוק, מכוח מדיניות הביטול של נותן השירותים מחוץ לישראל אם הצענו לכם לבחור בחלופה זו ובחרתם בה), לא ניתן לבטל הזמנות באופן מלא או חלקי ולא יינתנו החזרים ללא אישורנו מראש ובהתאם לתנאי האישור ותשלום דמי ביטול שייגבו על ידינו ועל ידי הספקים.<br />
                            <br />
                            אנו רשאים לבטל או לשנות הזמנה או כל חלק ממנה במקרה שבו הפרתם את תנאי השימוש שלנו, במקרה של תקלה טכנית או תקלה אחרת אשר אינה מאפשרת לנו להעניק לכם שירותים, במקרה של טעות בביצוע הזמנה וכן במקרים שבה מותר לנו לפי חוק לבטל עסקאות.<br />
                            <br />
                            <strong>להלן עיקרי הוראות דיני הגנת הצרכן כפי תוקפן במועד כתיבת תנאי שימוש אלו, אשר מובאות כאן לידיעתכם למען הנוחות בלבד. איננו מתכוונים להתנות על דיני הגנת הצרכן או להוסיף עליהם, ולכן בכל סתירה בינם לבין תנאי שימוש אלו, דיני הגנת הצרכן הן אלו שתגברנה.</strong><br />
                            <br />
                            <u>רכישת שירותי הארחה, נסיעה, חופש ובילוי הניתנים בישראל</u><br />
                            <br />
                            ברכישת שירותי הארחה, נסיעה, חופש ובילוי, למעט אם הם מתבצעים במלואם מחוץ לישראל, צרכן רשאי על פי תקנות הגנת הצרכן לבטל עסקה תוך 14  ימים מיום עשיית ההסכם ובלבד שהביטול ייעשה 14 ימים שאינם ימי מנוחה לפחות, קודם למועד תחילת השירות.<br />
                            <br />
                            בעסקאות מכר מרחוק (כהגדרת מונח זה בחוק הגנת צרכן) של שירותי הארחה, נסיעה, חופש ובילוי, צרכן רשאי על פי חוק הגנת הצרכן לבטל עסקה  בתוך 14 ימים מיום עשיית העסקה או מיום קבלת המסמך המכיל את הפרטים האמורים בסעיף 14ג(ב) לחוק הגנת הצרכן, לפי המאוחר, אם מועד ביטול העסקה אינו חל בתוך 7 ימים שאינם ימי מנוחה, קודם למועד שבו אמור השירות להינתן.<br />
                            <br />
                            בנוסף, בעסקאות מכר מרחוק קיימת לצרכנים עם מוגבלות, צרכנים שהם אזרחים וותיקים (מעל גיל 65) וצרכנים שהם עולים חדשים, זכות ביטול למשך תקופה של 4 חודשים מיום עשיית העסקה, מיום קבלת הנכס או מיום קבלת המסמך המכיל את הפרטים הנדרשים למסירה לפי חוק הגנת הצרכן בעסקת מכר מרחוק, ובלבד שההתקשרות בעסקה כללה שיחה בין העוסק לצרכן, ובכלל זה שיחה באמצעות תקשורת אלקטרונית.<br />
                            <br />
                            חוק הגנת הצרכן מגדיר את המונח "<strong>עסקת מכר מרחוק</strong>" כהתקשרות בעסקה של מכר נכס או של מתן שירות, כאשר ההתקשרות נעשית בעקבות שיווק מרחוק, ללא נוכחות משותפת של הצדדים לעסקה;  ואת המונח "<strong>שיווק מרחוק</strong>" כפניה של עוסק לצרכן באמצעות דואר, טלפון, רדיו, טלויזיה, תקשורת אלקטרונית מכל סוג שהוא, פקסימיליה, פרסום קטלוגים או מודעות, או באמצעי כיוצא באלה, במטרה להתקשר בעסקה שלא בנוכחות משותפת של הצדדים, אלא באחד האמצעים האמורים.<br />
                            <br />
                            שימו לב, <strong>כשאתם נכנסים לאתר עפנו ומבצעים הזמנה מבלי שאנו פנינו אליכם לבצע את ההזמנה, אין מדובר בעסקת מכר מרחוק</strong>.<br />
                            <br />
                            <u>רכישת שירותי הארחה, נסיעה, חופש ובילוי הניתנים במלואם מחוץ לישראל</u><br />
                            <br />
                            בעסקאות מכר מרחוק (ראו ההסבר לעיל) למתן שירותי הארחה, נסיעה, חופש או בילוי הניתנים במלואם מחוץ לישראל באמצעות נותן שירותים מחוץ לישראל, אנו רשאים לפי דיני הגנת הצרכן להציע לכם לבחור בין זכויות הביטול הניתנות לכם לפי דיני הגנת הצרכן לבין מדיניות הביטול של נותן השירותים מחוץ לישראל.<br />
                            <br />
                            עליכם לבחור את האפשרות המועדפת עליכם <u>בטרם</u> ביצוע הזמנה.<br />
                            <br />
                            <strong>כל המחירים המוצגים באתר ביחס לשירותי הארחה, נסיעה, חופש  או בילוי הניתנים במלואם מחוץ לישראל <u>אינם</u> כוללים זכות ביטול לפי חוק הגנת הצרכן.</strong><br />
                            <br />
                            <strong>הצעות מחיר לשירותי תיירות הניתנים לביטול לפי חוק הגנת הצרכן יהיו בעלות של 150% מעסקה זהה למוצרים אלה המוצעים ללא זכות ביטול. אם תהיו מעוניינים ברכישת שירותי תיירות הניתנים במלואם מחוץ לישראל וניתנים לביטול לפי חוק הגנת הצרכן, עליכם לבצע הזמנה טלפונית מול שירות הלקוחות שלנו ולבקש להזמין את  שירותי התיירות שלעיל עם אפשרות ביטול  לפי חוק הגנת הצרכן.</strong><br />
                            <br />
                            ביתר המקרים, תקנות הגנת הצרכן אינן מעניקות זכות ביטול שירותי הארחה, נסיעה, חופש ובילוי המתבצעים במלואם מחוץ לישראל, לרבות (א) טיסת המשך לטיסה שיצאה מישראל והניתנת באמצעות חברת תעופה אחרת; ו-(ב) חבילת נופש מחוץ לישראל, למעט טיסה מחוץ לישראל ואליה, שאינה טיסת המשך; ובלבד שהעוסק גילה לצרכן, בטרם ההתקשרות את תנאי ביטול העסקה של נותן השירות מחוץ לישראל.<br />
                            <br />
                            <strong><u>שינויים</u></strong><br />
                            <br />
                            נשתדל להיענות לבקשותיכם הסבירות לביצוע שינויים בהזמנתכם אך איננו יכולים להתחייב שבקשותיכם תיענינה. ספקים רבים גובים דמי טיפול עבור בקשות שינוי. אנו נגבה מכם דמי טיפול בסך של 150 ₪ על בקשות לשינויים או ביטולים לאחר אישור הזמנה, וזאת בנוסף לחיובים שייתכן שיגבו מכם הספקים בגין שינויים או ביטולים, בין אם על ידי הלקוח ובין על ידי ספק השירותים.<br />
                            <br />
                            אם ביטלתם הזמנה כדין על פי דיני הגנת הצרכן ואתם זכאים להחזר כספי, אנו נשיב לכם את כספיכם במועדים הנדרשים לפי דיני הגנת הצרכן בניכוי דמי ביטול מרביים המותרים לפי דיני הגנת הצרכן.<br />
                            <br />
                            <strong><u>ביטוחים</u></strong><br />
                            <br />
                            איננו מבטחים אתכם ואיננו אחראיים לנזקים שייגרמו לכם מאי רכישת ביטוח או כיסוי שאינו מתאים לצרכיכם. עליכם בלבד חלה החובה לרכוש ביטוח המתאים לצרכיכם, לפני מועד הטיסה. אנו ממליצים לכם לשקול את הצורך בביטוח נסיעות הכולל כיסוי בגין ביטול הנסיעה, כיסוי רפואי וכיסוי נזק או אובדן רכוש. ייתכן שחברות הביטוח יסרבו לבטח נוסעים מסוימים או נוסעים ליעדים מסוימים ואנו ממליצים לכם לבדוק זאת בטרם ביצוע הזמנה. האחריות וההשלכות של אי רכישת ביטוח, או אי רכישת ביטוח מתאים, תחולנה עליכם.<br />
                            <br />
                            ייתכן שבמסגרת השירותים של עפנו, אנחנו נציע לכם לרכוש ביטוח דרך חברת ביטוח מסוימת, נפנה אתכם לחברות ביטוח, או לבקשתכם נרכוש עבורכם ביטוח שאותו ביקשתם לרכוש, אולם חוזה הביטוח הוא ביניכם המבוטחים לבין חברות הביטוח. אנו לא נותנים לכם שירותי ייעוץ בתחום הביטוח ולא אחראיים למוצר הביטוחי אותו רכשתם. עליכם לבחון את ההתאמה של המוצר הביטוחי לצרכיכם עם חברת הביטוח. במקרה של אירוע ביטוחי, עליכם לפנות ישירות לחברת הביטוח.<br />
                            <br />
                            <strong><u>דרכונים, אשרות כניסה וכיו"ב</u></strong><br />
                            <br />
                            <strong>עליכם לוודא שיש לכם דרכון בתוקף לפרק הזמן המינימאלי הדרוש במדינות היעד שלכם, אשרות כניסה מתאימות ככל שאלו נדרשות במדינת היעד, חיסונים, אישורים אודות ביטוחי חובה ובדיקות רפואיות ואישורים אחרים ככל שאלה נדרשים על ידי חברות התעופה, מדינות היעד, הספקים וכיו"ב. </strong><br />
                            <br />
                            לתשומת ליבכם, מדינות רבות דורשות כי דרכונכם יהיה בתוקף למשך שישה חודשים לפחות ממועד הכניסה למדינה – ואנו ממליצים שתטוסו תמיד עם דרכון שתקף ל-6 חודשים לפחות ממועד יציאתכם את הארץ – ולחלקן נדרשת אשרת כניסה. ייתכנו דרישות לאישורים נוספים, כגון תעודת לידה עבור כל קטין הנכנס למדינה או רישום הקטין בדרכון של ההורה הנכנס עמנו לאותה מדינה, או כתב אפוטרופסות או מסמך דומה. שימו לב, כי על אזרחים ישראליים להיכנס אל ולצאת ממדינת ישראל באמצעות דרכון ישראלי.<br />
                            <br />
                            <strong>רק אתם אחראים לוודא את עמידתכם בכל הדרישות שמציבות המדינות השונות לצורך כניסה אליהן – אנא בדקו אותם היטב וודאו שאתם עומדים בהן. לא נהיה אחראיים במקרה שכניסתכם למדינה מסוימת תסורב עקב אי עמידתכם בדרישותיהם.</strong><br />
                            <br />
                            <strong><u>COVID-19</u></strong><br />
                            <br />
                            מגפת הקורונה (covid-19) העולמית משפיעה רבות על החיים בכלל, ועל תחום התיירות בפרט, בכל רחבי תבל. ישנן מדינות הדורשות בעת כניסה ויציאה מהמדינה אישורים שונים הקשורים בנגיף הקורונה, כגון אישורים אודות ביצוע בדיקות קורונה בתקן בדיקה כזה או אחר, תעודות חיסון או החלמה, תעודות "תו ירוק" או תעודות דומות, או המחילות חובת רכישת ביטוח המכסה נזקי קורונה, חובת בידוד או אף איסור כניסה או יציאה מהמדינה. ייתכן שבמדינת היעד אליה תגיעו יהיו הגבלות על התקהלויות, סגרים ועוצרים, או הגבלות ודרישות אחרות. דרישות, הנחיות והגבלות אלו משתנות תדיר ויכולות להשתנות באופן משמעותי בין מועד הזמנתכם למועד נסיעתכם.<br />
                            <br />
                            <strong>גם בהקשר זה, רק אתם אחראים לוודא את עמידתכם בכל הדרישות, ההנחיות וההגבלות הללו – אנא בדקו אותם היטב וודאו שאתם עומדים בהן ושאתם מודעים למצב במדינת היעד שלכם. נסיעות בעידן מגפת הקורונה העולמית כרוכה בסיכון אינהרנטי, בין היתר, שתחלו במהלך הנסיעה או שתיתקלו בקושי לנוע, להשתתף באירועים, לקבל טיפול רפואי, להיכנס או לצאת למדינות מסוימות. אנו ממליצים לכם לבחון רכישת ביטוח מתאים. אנו לא נהיה אחראיים בגין נזקים הנגרמים עקב השפעות מגפת הקורונה.</strong><br />
                            <br />
                            <strong><u>חיסונים ותרופות</u></strong><br />
                            <br />
                            עליכם לבדוק האם עליכם לקחת חיסונים מסוימים לצורך הגעה למדינת יעד מסוימת, ולהצטייד בתרופות ובמרשמים להם אתם זקוקים ובמסמכים רפואיים מתאימים. סיווגן של תרופות כמותרות או כסמים אסורים במדינה מסוימת, או הצורך במרשם לגביהם, הוא בהתאם לדיני אותה מדינה.<br />
                            <br />
                            <strong><u>טיסות</u></strong><br />
                            <br />
                            <strong>טיסות מבוצעות על ידי חברות התעופה, על פי התנאים של חברות התעופה והרשויות הרלוונטיות, ואין לנו כל שליטה עליהן או אחריות בגינן. </strong><br />
                            <br />
                            ייתכנו שינויים אף ללא הודעה מוקדמת במועדים, בזמנים, במסלולים, בכבודה המותרת, בארוחות המוגשות (אם בכלל) וביתר תנאי הטיסה, וייתכנו טיסות code sharing כאשר הטיסה שהזמנתם מחברת תעופה אחת מופעלת על ידי חברת תעופה אחרת. ייתכנו מקרים של הרשמת יתר לטיסות על ידי חברות התעופה, אשר יוביל לכך שחלק מהנוסעים יידרשו לטוס בטיסה אחרת. פרטי טיסות שכר, כגון זהות חברת התעופה ומספר הטיסה, יכולים להשתנות.<br />
                            <br />
                            זמני המראה ונחיתה משתנים תדיר, ונמסרים לעיתים בצורה מרוכזת לבתי המלון. עליכם להישאר מעודכנים בזמני ההמראה והנחיתה. לרוב ניתן לעשות זאת דרך בית המלון, אתר האינטרנט של חברת התעופה או של שדה התעופה, או בפניה לחברת התעופה.<br />
                            <br />
                            עליכם לאשר ולאשרר את טיסותיכם בהתאם לתנאים של חברות התעופה, ולהגיע לשדה התעופה מספיק זמן מראש בהתאם להנחיות ההגעה הנהוגות במדינה הרלוונטית עם דרכון וכל המסמכים הדרושים לטיסה ועם כבודה מסוג, גודל ומשקל העומדת בתנאי ההזמנה שלכם ויתר הנהלים והחוקים החלים בעניין. בטיסות בינלאומיות ובהיעדר עומסים או תנאים חריגים אחרים, אנו ממליצים לכם להגיע לשדה התעופה 3 שעות לפני מועד הטיסה. שדרוגים, שינויים בפרטי הזמנה, משקל או גודל כבודה החורגת מההזמנה ושירותים ושינויים נוספים כרוכים לרוב בתוספת תשלום. לא כל חברות התעופה מגישות אוכל כשר, צמחוני, טבעוני או המותאם לצרכיכם מכל בחינה אחרת, בקווים מסוימים או בכלל, ולא בכל טיסה מוגשות ארוחות. טיסות שכר אינן כוללות ארוחות ומשקאות אלא עם הוסכם במפורש אחרת בכתב.<br />
                            <br />
                            אנא קראו היטב את התנאים של חברות התעופה למתן שירותיהם ואת תנאי ההזמנה שלכם.<br />
                            <br />
                            לתשומת ליבכם, אם טיסתכם נדחתה או התבטלה, ייתכן שיש לכם זכות לקבלת פיצוי וזכויות נוספות מכוח חקיקה או מכוח מחויבות חברות התעופה כלפיכם. מידע אודות זכויות אלה ניתן לקבל אצל חברת התעופה.<br />
                            <br />
                            <strong><u>בתי מלון</u></strong><br />
                            <br />
                            <strong>הזמנות לבתי מלון מתבצעות מול בתי המלון, על פי התנאים של בתי המלון והדין במדינה בה ממוקם בית המלון, ואין לנו כל שליטה עליהן או אחריות בגינן. </strong><br />
                            <br />
                            בתי המלון אחראיים על הפעלת בית המלון על כל היבטיו, לרבות השירותים הניתנים במסגרתו (כולל חניה, ספא, גישה לרשת אינטרנט, ערוצי טלוויזיה, ועוד), ניקיונו והחלפת המצעים, איכות החדרים, המזון, שירות החדרים, מיזוג האוויר ומועדי הפעלתו, תקינות המערכות והמתקנים וכיו"ב. ישנם בתי מלון אשר לא מפעילים מיזוג אוויר בעונות מסוימות או בשעות מסוימות או שאינם מספקים שירותים מסוימים בעונות מסוימות. לרוב לא ניתן להבטיח כי תקבלו סוג מסוים של מיטה או מיטות אף אם ביקשתם זאת, ועל הזמנת עריסה או מיטת תינוק או מיטות נוספות ייגבה פעמים רבות תשלום נוסף. חדר לשלושה הוא לרוב חדר זוגי עם מיטה נוספת. מיטות נוספות הן הרבה פעמים מיטות מתקפלות.<br />
                            <br />
                            ייתכנו מקרים שבהם המלון ביצע רישום יתר ותופנו למלון אחר.<br />
                            <br />
                            עליכם להתנהל עצמאית מול קבלת בית המלון או הספק הרלוונטי כבר במהלך החופשה על מנת שיוכלו לתת לכם מענה מידי והולם בכל עניין.<br />
                            <br />
                            דרגות בתי המלון נקבעות על ידי המשרד או הרשות הרלוונטית במדינה בה ממוקם בית המלון, ולא על ידינו, והקריטריונים לדירוג משתנים ממדינה למדינה כך שאין להשוות בין דירוגים במדינה אחת לאלו שבמדינה אחרת. אנו מציגים את הדירוגים כפי שמתקבלים על ידינו ואין לנו יכולת לשנות את הדירוג של בית המלון או את אופן הצגתו.<br />
                            <br />
                            על פי רוב הזמנת חדר היא לסוג של חדרים מהמפרט המוצע על ידי בית המלון ולא לחדר ספציפי. שיבוצכם לחדרים ספציפיים מתבצע על ידי בית המלון. ישנם בתי מלון הדורשים בעת ההגעה למלון כי יימסר להם פרטי כרטיס אשראי בינלאומי ממנו הם תופסים מסגרת אשראי מסוימת כפיקדון.<br />
                            <br />
                            עליכם לברר את שעות הכניסה לחדרים (לרוב 15:00, אם כי שעת הכניסה המדויקת תלוי בבית המלון) ואת שעות היציאה מהחדרים (לרוב 11:00, אם כי שעת היציאה המדויקת תלויה בבית המלון). שעות היציאה מהמלון אינן תלויות בשעת הטיסה חזרה. בישראל ייתכנו שעות כניסה ויציאה שונות במועדי ישראל ובשבתות. לא כל בתי המלון יישמרו לכם את חדריכם אם לא הגעתם למלון עד שעה מסוימת ביום הכניסה לחדר. לבירורים עליכם לעמוד בקשר ישירות עם בית המלון. כניסה מוקדמת או עזיבה מאוחרת אפשרית לעיתים בתיאום מראש עם המלון. כניסה מוקדמת או עזיבה מאוחרת עלולה להיות כרוכה בתוספת תשלום.<br />
                            <br />
                            אנו ממליצים לכם לדאוג לאחסנה נאותה של דברי הערך שלכם בכספות בית המלון ככל שיש כאלה ולברר עם בית המלון אודות אפשרות שמירת מזוודות ביום העזיבה.<br />
                            <br />
                            <strong><u>השכרת רכב</u></strong><br />
                            <br />
                            <strong>השכרות רכב מתבצעות מול חברת השכרת הרכב, על פי התנאים של חברת השכרת הרכב והדין במדינה הרלוונטית, ואין לנו כל שליטה עליהן או אחריות בגינן. </strong><br />
                            <br />
                            עליכם לבדוק מהו גיל המינימום להשכרת רכב במדינת היעד ואצל הספק הרלוונטי, ובאילו מסמכים עליכם להצטייד לצורך כך. תידרשו לכל הפחות להציג רישיון נהיגה תקף ואמצעי תשלום, לרוב כרטיס אשראי בינלאומי. ייתכן גם שתצטרכו להציג רישיון נהיגה בינלאומי. עליכם לברר את הדרישות החלות עליכם.<br />
                            <br />
                            חברות השכרת הרכב מציעות קטגוריות של רכבים שמתוכם הן יספקו לכם רכב לפי שיקול דעתן מאותה קטגוריה או מקטגוריה גבוהה יותר ואינן מתחייבות לרכב מדגם מסוים. ייתכן שיגבו מכם תשלום נוסף על חריגה ממכסת קילומטרים יומית הנקבעת על ידי חברת השכרת הרכב. על פי רוב, אלא אם חברת השכרת הרכב אישרה זאת, אינכם יכולים לצאת עם הרכב אותו שכרתם מגבולות אותה מדינה.  חברות השכרת רכב נוהגות לגבות תוספת תשלום משמעותית על תדלוק רכבים שלא הוחזרו עם מיכל מלא (או לפי כמות הדלק שסוכמה עם חברת השכרת הרכב אם פחות ממיכל מלא) כך שמומלץ לתדלק את הרכב לפני מסירתו חזרה לחברת ההשכרה.<br />
                            <br />
                            <strong><u>קניין רוחני</u></strong><br />
                            <br />
                            כל זכויות הקניין הרוחני שלנו, לרבות הביטוי "עפנו", הלוגו שלנו, העיצובים באתר או בחומרים או מסמכים אחרים שלנו וכל זכות קניין רוחני אחרת (בין אם רשמה ובין אם לאו), שמורות לנו, והיכן שרלוונטי, לצדדים שלישיים שהעניקו לנו רישיונות שימוש בקניין הרוחני שלהם. אינכם רשאים לעשות בהם כל שימוש שלא הותר על ידינו מראש ובכתב.<br />
                            <br />
                            <strong><u>יצירת קשר </u></strong><br />
                            <br />
                            ניתן ליצור אתנו קשר בדרכים הבאות:<br />
                            <ol><br />
                                <li>דוא"ל: <img src="/images/contact_us_mail.jpg" style={{display:'block',height:'1.2rem'}} alt="email"></img></li><br />
                                <li>טלפון: 050-7801801.</li><br />
                                <li>דרך האתר: <a href="/contact.html">לחץ כאן</a></li><br />
                                <li>דואר: ת.ד 600 עתלית 3033957 </li><br />
                            </ol><br />
                            <br />
                            <strong><u>שונות</u></strong><br />
                            <br />
                            האתר מוגבל לשימוש על ידי בגירים תושבי ישראל בלבד.<br />
                            <br />
                            הכותרות שבתנאי שימוש אלו הן למען הנוחות בלבד.<br />
                            <br />
                            על תנאי שימוש אלו חלים דיני מדינת ישראל. כל סכסוך בנוגע לשימוש באתר ו/או לשירותים הניתנים על ידינו או הנרכשים דרכינו יידון אך ורק בבית המשפט המוסמך בעיר תל-אביב-יפו.<br />
                            <br />
                            אנו יכולים לשנות את תנאי השימוש (לרבות מדיניות הפרטיות) מעת לעת על פי שיקול דעתנו הבלעדי. השינויים ייכנסו לתוקף עם פרסום תנאי שימוש מעודכנים באתר. השינויים לא יחולו על הזמנות שאושרו באופן סופי, אלא אם כן השינוי נדרש לפי דין, אינו פוגע בזכויות מזמיני ההזמנה שאושרה סופית או שניתנה להם אפשרות לסרב לשינוי והם לא עשו זאת.<br />
                            <br />
                            תאריך עדכון אחרון: 23 בדצמבר, 2021
                    
                    </p>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;