import React from "react";
import configData from '../../config/config.json';
import './index.css';
import Modal from 'react-responsive-modal';

class Banner extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            open_promotion: false
        }
    }

    showPromotionModal = (event, open) => {
        if (event)
            event.stopPropagation()

        //console.log(open)
        this.setState({ 
            open_promotion: open,
            open: false
         });
    };

    render() {
        /*
        if (this.props?.campaign==='crazyweekend') {
                
            return (
                <>
                    <div style={{maxWidth:'90%',margin:'1em auto 2em'}} className={this.props?.hide?'hide':'mobile-only'} onClick={(e)=> this.showPromotionModal(e,true)}>
                        <img src="/images/promotions/crazyweekend/banner.jpg" width="100%" alt='סופש מטורף' />
                    </div>
                    <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                        <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                            <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                            חם חם חם לנו! אז זה בדיוק הרגע לעוף לנופש בחו"ל ולקפוץ הישר לבריכה!
                            </p>

                            <p>מזמינים חופשה לקיץ במהלך סוף השבוע, מזינים את הקופון CRAZY40 ונהנים ממתנה מיידית של $40 הנחה בחיוב.</p>
                            <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                            <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $350 לנוסע, שיבוצעו במהלך סוף השבוע בלבד</p>

                        </div>
                    </Modal>
                </>
            )    
        } else if (this.props?.campaign==='weekend30') {
            return (
                <>
                    <div style={{maxWidth:'90%',margin:'1em auto 2em'}} className={this.props?.hide?'hide':'mobile-only'} onClick={(e)=> this.showPromotionModal(e,true)}>
                        <img src="/images/promotions/weekend30/1200x628-weekend-website-mobile.png" width="100%" alt='סופש מטורף' />
                    </div>
                    <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                    <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                        <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                        חם חם חם לנו! אז זה בדיוק הרגע לעוף ולהזמין חופשה לחול במחיר זול עוד יותר !
                        </p>

                        <p>מזמינים חופשה במלונות הכל-כלול במהלך סוף השבוע, מזינים את הקופון CRAZY30 ונהנים ממתנה מיידית של $30 הנחה בחיוב.</p>
                        <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                        <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $250 לנוסע, שיבוצעו במהלך סוף השבוע בלבד. ללא כפל מבצעים</p>

                    </div>
                    </Modal>
                </>
            )
        } else if (this.props?.campaign==='rosh25') {
            return (
                <>
                    <div style={{maxWidth:'90%',margin:'1em auto 2em'}} className={this.props?.hide?'hide':'mobile-only'} onClick={(e)=> this.showPromotionModal(e,true)}>
                        <img src="/images/promotions/rosh25/1200x628-website-mobile.png" width="100%" alt='מבצע ראש השנה' />
                    </div>
                    <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                    <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                    <p><br /><strong>ראש השנה מטורף בעפנו!</strong><br />
                                    </p>

                        <p>מזמינים חופשה במהלך החג, מזינים את הקופון ROSH25 ונהנים ממתנה מיידית של $25.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות בערך של לפחות $200 לנוסע, שיבוצעו במהלך החג בלבד. ללא כפל מבצעים</p>

                    </div>
                    </Modal>
                </>
            )
        }
        else {
            return (<></>)
        }
        */

        return(<></>)
    }
}
export default Banner