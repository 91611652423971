import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";
import {PageView, initGA} from '../../features/Tracking';


class Terms extends React.Component {
    componentDidMount() {
        initGA();
        PageView();
  
    }
    render() {
        return(
            <div className="App MemberTerms">
                <Header  hide='true' hideSearch={true} />
                <main>
                    <h1>תקנון מועדון לקוחות</h1>
                    <p><u>כללי</u></p>
                    <p>תקנון זה נועד להסדיר את תוכנית ההטבות המיועדת ללקוחות אתר "עפנו" ו/או כל דרך התקשרות אחרת, המעוניינים להיות חברים במועדון (להלן: "מועדון הלקוחות(" והכל בכפוף להוראות תקנון זה (להלן: "התקנון").</p>
                    <p>הרישום לחברות במועדון והשימוש בהטבות המועדון כפופים לתנאי התקנון הבאים (“תנאי תקנון”). בעצם הרישום לחברות במועדון, הינך מסכים כי תנאי שימוש אלה יחייבו אותך ולמלא אחר כל החוקים החלים. אם אינך מסכים לתנאי שימוש אלה, ללא סייג או הסתייגות, נא הימנע מרישום לחברות במועדון. “עפנו” רשאית לשנות את תנאי השימוש בכל זמן על ידי עדכון פרסום זה. עדכונים אלה יחייבו אותך ולכן עליך לבקר בעמוד זה מעת לעת על מנת לבדוק את תנאי השימוש המעודכנים החלים עליך.</p>
                    <p>אחריות המלאה להכרת התנאים להלן וקבלת ההטבות על פי תנאים אלו חלה בכל עת על החברים במועדון הלקוחות כך שתקנון זה מהווה תנאי לחברות במועדון וכל חבר הנרשם למועדון נחשב כמי שקרא והסכים להוראות תקנון זה, על כל סעיפיו ו/או חלקים מהם .</p>
                    <p>חלקים מהאתר ו/או בכל דרך התקשרות אחרת ו/או מתקנון זה מנוסחים בלשון זכר מטעמי נוחות בלבד אך כל האמור מיועד לנשים וגברים כאחד .</p>

                    <u>זכאות לחברות במועדון</u>
                    <p>זכאים להימנות על חברי מועדון הלקוחות יחידים בעלי אזרחות ישראלית בלבד (לא בתי עסק, ארגונים או סוכנים) המנצלים את חברותם לשימוש פרטי, אשר מילאו את פרטיהם בטופס בקשת ההצטרפות למועדון הלקוחות במלואו.</p>

                    <u>שינוי ההטבות במועדון הלקוחות או הפסקתן</u>
                    <p>החברה (להלן: "עפנו") שומרת לעצמה את הזכות לשנות או להפסיק את ההטבות הניתנות ו/או כל חלק מההטבות הניתנות ללא הודעה מוקדמת. החברה לא תישא בשום אחריות במידה ותחליט לנהוג כך. כמו כן, החברה לא תישא בשום אחריות כלפי החברים במועדון הלקוחות ו/או כלפי צד ג' כלשהו אם תשנה ו/או תפסיק את ההטבות הניתנות במועדון הלקוחות, לזמן מוגבל ו/או לצמיתות, מכל סיבה שהיא. הודעה על שינוי ו/או הפסקת השירות , לצמיתות או לזמן מוגבל כלשהו, תימסר לחברים במועדון הלקוחות באמצעות הודעה בכתב . הודעות המועדון לקוחות בכל דבר ועניין הנוגע לפעילות המועדון לקוחות לרבות, אך לא רק, בעניין שינוי ו/או הוספה ו/או גריעה שייעשו ו/או שבכוונת החברה לבצעם, בתקנון ו/או בהנחות ו/או בכל דבר ועניין הנוגע לזכאות החבר ו/או לפעילות המועדון לקוחות ו/או הפסקת פעילותו וכיו"ב, ייעשו ע"י המועדון לקוחות באופן ובדרך כפי שהחברה תמצא לנכון לפי שיקול דעתה הבלעדי. לחברים לא תהיה כל טענה ו/או דרישה ו/או תביעה ו/או תלונה בנוגע לאופן העברת ההודעות כאמור .</p>
                    

                    <u>קבלת הודעות מהמועדון ו/או חומר פרסומי</u>
                    <p>חבר המועדון מצהיר בזאת שהינו מאשר לחברה ו/או מי מטעמה, לשלוח אליו מידע בקשר להודעות מועדון ו/או מבצעים שונים ו/או חומר פרסומי ו/או כל מידע אחר ע"פ שיקול דעתה של החברה באמצעות דואר ו/או דואר אלקטרוני ו/או SMS, או באמצעי דיוור אחר. אפשרות "הסרה" תופיע על-גבי ההודעות שישלחו.</p>

                    <u>אמצעי אבטחה</u>
                    <p>“עפנו” נוקטת בצעדים סבירים על מנת לאבטח את האתר ו/או את מאגר מועדון הלקוחות ולהבטיח את פעילותו התקינה, אך בכל מקרה “עפנו” לא תיהיה אחראית לכל הפסקה ו/או האטה ו/או אובדן מידע ו/או גניבת מידע ו/או נזק למחשב ו/או נזק לתשתית ו/או הפרעה ו/או וירוס ו/או סוס טרויאני ו/או תוכנה מזיקה ו/או פרצה ו/או תקלה ו/או כשל חומרה ו/או כשל תוכנה ו/או כשל קווי תקשורת ו/או פגם כלשהו באבטחה תהא סיבתם אשר תיהיה (להלן – “כשל אפשרי”), ככל שיגרמו לחבר המועדון ו/או למשתמש לרבות נזקיהם ו/או הוצאותיהם. החברות במועדון ו/או השימוש באתר ובשירותיו נעשה על אחריותו הבלעדית של חבר המועדון ו/או המשתמש. האמור יחול אף אם החברה צפתה ו/או היה ביכולתה לצפות את הכשל האפשרי ו/או את התוצאות כאמור. מבלי לגרוע מהאמור, “עפנו” ממליצה לחבר המועדון ו/או למשתמש לנקוט ולהפעיל אמצעי הגנה המתאימים לצרכיו, לרבות מערכות אנטי וירוס, בכל שימוש באתר ובכלל. כמו כן “עפנו” לא תיהיה אחראית על אופן אבטחת המידע אצל גורמי צד ג’ אליהם יופנה המשתמש לצורך קבלת מידע ו/או הזמנה ו/או כל הפניית משתמש אחרת.</p>

                    <u>פעילות על פי כל דין ותיקון הפרה על ידי החברה</u>
                    <p>“עפנו” מצהירה בזאת, כי הינה עושה מאמץ לפעול בהתאם להוראות כל דין. יחד עם זאת, אם וככל שיתברר שמעשי ו/או מחדלי החברה אינם עולים בקנה אחד עם הוראות כל דין כאמור ו/או האמור בתקנון זה, חבר המועדון ו/או יודיע על כך לחברה בכתב, וייתן לחברה 15 ימי עבודה לפחות לתקן את ההפרה, דרכי יצירת הקשר עם החברה מופיע בסוף תקנון זה. חבר המועדון מצהיר בזאת שהינו מוותר על כל סעד המגיע לו בגין הפרה כלשהי מצד “עפנו” ו/או מי מטעמה, אלא אם “עפנו” לא תיקנה את ההפרה תוך 25 ימי עבודה מהמועד שקיבלה הודעה בפועל ובכתב בגין ההפרה.</p>

                    <u>הפרת תקנון זה על ידי חבר המועדון</u>
                    <p>חבר המועדון מתחייב לפצות ו/או לשפות את “עפנו” מיד עם דרישה ראשונה בגין כל הפרת תקנון זה כדוגמת נזק ו/או הפסד ו/או אובדן רווח ו/או פגיעה בשם טוב ו/או פגיעה במוניטין ו/או תשלום הוצאות שיגרמו לחברה בקשר לגלישה באתר ו/או תשלום הוצאות שיגרמו לחברה בקשר לדף הפיסבוק ו/או שימוש בשירותי “עפנו” ו/או הפרת האמור בתקנון זה ו/או בכל הסכם אחר בין החברה לבין החבר המועדון לרבות הוצאות משפט ו/או שכר טרחה ו/או קנסות והכל בתוספת ריבית והצמדה כדין. למען הסר ספק, האמור בסעיף זה חל גם על כל טענה ו/או דרישה ו/או תביעה של צד ג’ כנגד החברה ו/או מי מטעמה. מבלי לגרוע מהאמור, במקרה של תביעה צד ג’ הנובעת מהפרת חבר מועדון של תקנון זה החברה תיהיה רשאית לשכור שירותי משפטיים על פי שיקול דעתה הבלעדית והחבר המפר יהיה מחיוב לשלם באופן בלעדי בגין שירות זה.</p>

                    <u>הפסקת הפעילות</u>
                    <p>“עפנו” תיהיה רשאית בכל זמן שהוא ובהתאם לשיקול דעתה הבלעדית, להפסיק את פעילות מועדון הלקוחות ו/או דף הפיסבוק ו/או האתר, באופן זמני ו/או לצמיתות, ללא כל הודעה מוקדמת. יובהר, במקרה והשירות יופסק “עפנו” ו/או מי מטעמה אינם מתחייבים להמשיך ולטפל בחברי מועדון ו/או משתמשים קיימים.</p>

                    <u>ברירת דין וסמכות שיפוט</u>
                    <p>מקום השיפוט הבלעדי בגין כל עניין הנובע מהחברות המועדון ו/או השימוש באתר ו/או כל דרך התקשרות אחרת, הוא אך ורק בבתי המשפט המוסמכים בעיר תל אביב ועל החברות המועדון ו/או השימוש ב”עפנו” יחולו אך ורק דיני מדינת ישראל.</p>

                    <u>יצירת קשר עם החברה</u>
                    <p>“עפנו” מתחייבת בזאת, לעשות מאמצים סבירים על מנת לספק תמיכה וליווי לאורך התהליך ככל הניתן. ניתן ליצור עמנו קשר בכל עת בדואר האלקטרוני שכתובתה 
                    <img src="/images/contact_us_mail.jpg"  alt="email"></img>
                    </p>


                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;