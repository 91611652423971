import React, { useContext, useState, useEffect} from "react";
import configData from '../../config/config.json';
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar";
import "./form.css";
import {  Grid, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
//import Rating from "@material-ui/lab/Rating";
import HotelIcon from "@material-ui/icons/Hotel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Input } from "antd";
import CB from "antd/lib/checkbox/Checkbox";
import { GlobalContext } from "../../contexts/GlobalState";
import axios from "axios";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
//import PriceChangeModal from "./PriceChangeModal";
import ErrorModal from "./ErrorModal";
import AncillariesModal from "./AncillariesModal";
import StarIcon from "@material-ui/icons/Star";
import { useToasts } from "react-toast-notifications";
import TermsModal from "./TermsModal.jsx";
import YesNoModal from "./YesNoModal";
//import { TramRounded } from "@material-ui/icons";
//import { string } from "yup/lib/locale";

// import 'antd/dist/antd.css';
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
    event: 'Pageview',
    'pageUrl': window.location,
    'pageTitle' : window.title,
    
});



// PaymentResponse Interface Equivilant
var ValidPaymentResponse = /** @class */ (function () {
  function ValidPaymentResponse(pi, pt, u) {
      this.processId = pi;
      this.processToken = pt;
      this.url = u;

  }

  return ValidPaymentResponse;
});

const API_ENDPOINT = configData.API_ENDPOINT;
//const PAYMENTS_API = configData.PAYMENTS_API;

const useStyles = makeStyles((theme) => ({
  root: {},
  inputField: {
    width: "100%",
  },
  Checkbox: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));

  /*const BaggageCheckbox = makeStyles({
    root: {
      color: 'red',
      '&$checked': {
        color: 'blue',
      },
    },
    checked: {},
  });
  */

  const Reservation = (props) => {

  const package_uid = props.match.params.deal_id;
  const quote_uid = props.match.params.quote_id;
  let room_uid, pax, rooms;
  console.log(props)
  const { addToast } = useToasts();
  const history = useHistory();
  let isQuotePage = false;
    /*if (history.location.pathname.indexOf('quote')>0) 
      isQuotePage=true;
  */

  const GetMealPlanName = (code) => {
    switch(code) {
      case 'HM': return 'חצי פנסיון'; // Breakfast and half board
      case 'MB': return 'חצי פנסיון + משקאות'; //Half board with drinks
      case 'MP': return 'חצי פנסיון'; //Half board
      case 'PC': return 'פנסיון מלא'; //Full board
      case 'PV': return 'פנסיון מלא + משקאות'; //Full board with drinks
      case 'SA': return ''; //Accommodation only
      case 'SP': return ''; //According to programme
      case 'TI': return 'הכל כלול'; //All inclusive
      case 'TP': return 'הכל כלול פרימיום'; //All inclusive premium
      case 'SC': return ''; //Self Catering
      case 'HD': return 'ארוחת בוקר '; //Bed and breakfast
    }
  };

  
    /**
     * Calculate the number of days / weeks / months between the departure date & today
     * @param {*} departure_date 
     */
    const GetTimeToDeparture = (departure_date) => {
      try {
          const today = new Date();
          const [datestr, timestr] = (departure_date+'').split("T");
          const [year, month, day] = datestr.split("-");
          const departureDate = new Date(+year, +month - 1, +day);

          const difference = departureDate.getTime() - today.getTime();

          const daysToDeparture = Math.ceil(difference / (1000 * 60 * 60 * 24));
          
          switch (true) {
              case daysToDeparture <= 2: return '1-2 days';break;
              case daysToDeparture <= 4: return '3-4 days';break;
              case daysToDeparture <= 7: return '5-7 days';break;
              case daysToDeparture <= 10: return '8-10 days';break;
              case daysToDeparture <= 13: return '11-13 days';break;
              case daysToDeparture <= 21: return '2 weeks';break;
              case daysToDeparture <= 28: return '3 weeks';break;
              case daysToDeparture <= 35: return '4 weeks';break;
              case daysToDeparture <= 42: return '5 weeks';break;
              case daysToDeparture <= 49: return '6 weeks';break;
              case daysToDeparture <= 56: return '7 weeks';break;
              case daysToDeparture <= 63: return '8 weeks';break;
              case daysToDeparture <= 70: return '9 weeks';break;
              case daysToDeparture <= 100: return '2 months';break;
              case daysToDeparture <= 130: return '3 months';break;
              case daysToDeparture <= 160: return '4 months';break;
              case daysToDeparture <= 190: return '5 months';break;
              case daysToDeparture <= 220: return '6 months';break;
              case daysToDeparture <= 250: return '7 months';break;
              case daysToDeparture <= 280: return '8 months';break;
              case daysToDeparture <= 310: return '9 months';break;
              case daysToDeparture > 310: return '10+ months';break;
              default: return '';
          }
      } catch(e) {
          return 'ERROR'
      }
      
    }

  const classes = useStyles();
  const {
    packageDetails,
    preserveData,
    reservationDetails,
    preserveReservation,
    changePassengerQuantity,
    changeSelectedRoom,
    selectedRoomContext
  } = useContext(GlobalContext);
  const [openPriceChange, setOpenPriceChange] = React.useState(false);
  const [openError, setOpenError] = React.useState({});
  const [openAncillaries, setOpenAncillaries] = React.useState(false);
  const [ancillariesState, setAncillariesState] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  //const [priceChangeModal, setPriceChangeModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [yesNoMessage, setYesNoMessage] = useState("");
  const [ancillariesMessage, setAncillariesMessage] = useState("");
  const [priceChangeMessage, setPriceChangeMessage] = useState("");
  const [termLink, setTermLink] = useState("");
  const [termText, setTermText] = useState("");
  let [reservationState, setReservationState] = useState({});
  let [detailState, setDetailState] = useState({});
  const [count, setCount] = useState(5000);
  let [baggageState, setBaggageState] = useState([]);
  let [totalBaggagePrice, setTotalBaggagePrice] = useState(0)
  const [openYesNo, setOpenYesNo] = React.useState(false);
  const [detailRoomState, setDetailRoomState] = useState([]);
  const [selectedRoomUID, setRoomUID] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});
  const [finishProgress, setFinishProgress] = useState(false)
  const [quoteDetails, setQuoteDetails] = useState({})
  //const [packageDetails, setPackageDetails] = useState({});
  /*let [loadingCount, setLoadingCount] = useState(1);
  let baggageArr = [];
  let timeout5;
  let timeout15;*/

  /*
  const intervalCheckFlightAndHotel = async function(){
    console.log('Start...')
    //console.log(window.location.pathname)
    //console.log(props.match.path)
    // if user leave the reservation form => halt real-time check
    if (window.location.pathname == props.match.path) {
      // window.location.pathname => will always return the path of the real existing page being views
      // propts.match.path => returns the path of the page on first load (in our case, the reservation form)
      return;
    }
    
    let resFlight;
    let flightRequestRetries = 3;

    try {
      resFlight = await axios.get(
        `${API_ENDPOINT}/search/packages/${package_uid}/check/flight/?room=${room_uid}&adults=${pax?.adults}&children=${pax?.children}&infants=${pax?.infants}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          timeout: 10000
        }
      ); 
    } catch(e) {
      // If connection timed out, let try again
      if (e.code==`ECONNABORTED`) {
        // retry 3 times max
        console.log('Connection Error')
        if (flightRequestRetries-->0) {
          intervalCheckFlightAndHotel();
          return;
        }
      }
    }
    if (resFlight?.data?.status == "invalid" || resFlight?.data?.status == "error") {

      setErrorMessage({
        title: `שגיאה`,
        message: resFlight?.data?.error?.description
      });
      setOpenError({
       status: true,
       go_back: true
      });
      return false;
    } else if (resFlight?.data?.status == "loading") {
        window.setTimeout(intervalCheckFlightAndHotel, 5000);
    } else if (resFlight?.data?.status == "valid") {
       
        setReservationState({ 
          status: "valid",
          package: reservationState.package,
          flight: resFlight?.data?.flight,
          //property: resHotel?.data?.property,
          session: resFlight?.data?.session,
          job: resFlight?.data?.job,
        });

        window.setTimeout(intervalCheckFlightAndHotel, 15000);
    } else {
      // unexpected response from server
      setErrorMessage({
        title: `שגיאה`,
        message: 'תקלה לא צפויה, אנא נסה שנית בעוד מספר דקות'
      });
      setOpenError({
        status: true,
        go_back: true
      });
      
      return false;
    }
  } 
  */

  useEffect(async () => {
    console.log('useEffect')

    // Force page refresh after 60 minutes
    setTimeout(() => {
      console.log('Expired... reloading')
      window.location.reload(false);
     },
     60*60*1000
     );

   /**
   * Auto hide payment iframe on error
   */
    window.addEventListener('message', function (result) {
      
      // MESHULAM CALLBACK!
      if (result.origin === 'https://meshulam.co.il' || result.origin === 'https://sandbox.meshulam.co.il'){
       
      switch (result.data.action){
          case 'close' :{ 
              document.getElementById('ifrm_wrapper').style.setProperty('display','none');
              document.getElementsByTagName('iframe')[0].style.setProperty('display','none');
              setFinishProgress(false);
              break;
          }
          
          //success
          case 'payment' :{
              if(result.data.status == 1){
                console.log(result.data)
                console.log(result)
                //history.push('')
                //alert('payment received')
                //setFinishProgress(false);
                //document.getElementsByTagName('iframe')[0].style.setProperty('display','none');
                // We're not going to show the original screen anymore, since the iframe took over and if need will show the error or confirmation dialog

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'PaymentAuthorized',
                    'pageUrl': window.location,
                    'pageTitle' : window.title
                });
              
              
              }
              break;
          }
          case 'failed_to_load_page':{
            document.getElementById('ifrm_wrapper').style.setProperty('display','none');
            document.getElementsByTagName('iframe')[0].style.setProperty('display','none');
            setFinishProgress(false)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'PaymentFailed',
                'pageUrl': window.location,
                'pageTitle' : window.title
            });
          
          
            break;
          }
      }
    }
    });


    try {
      // Load data from db if page was refreshed or accessed directly
      if (detailState.uid==undefined ) {
        console.log('Loading data from remote server...')

        let url = `${API_ENDPOINT}/search/packages/${package_uid}/`; 
        let res = await axios.get(
          url,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true  
          }
        );

        room_uid = res?.data?.property?.room?.uid;
        pax = res?.data?.pax;
        rooms = res?.data?.property?.rooms_occupancies;

        if (res?.data && !res.data?.error)  {
          setDetailState(res?.data);

          // Load quote details
          let resQuote = null;

          try {
            resQuote = await axios.get(
              `${API_ENDPOINT}/quote/${quote_uid}`,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true,
                timeout: 10000
              }
            );   
          } catch(e) {
            console.log('Failed to load quote')
            if (e=='Error: Request failed with status code 410') {
                setErrorMessage({
                  title: ``,
                  message: 'הצעת המחיר כבר לא בתוקף. מיד תוחזרו לדיל לצפיה במחיר העדכני'
                });
                setOpenError({
                  status: true,
                  go_back: false
                });

              return
            }

          }
          if (resQuote?.data && !res?.data?.error) {
            setQuoteDetails(resQuote?.data);
          } 

          // Overwrite deal price with quote price
          if (resQuote.data?.uid) {
            res.data.price = resQuote.data.price;
            

            // Check flight to get baggage
            let resFlight;
      
            resFlight = await axios.get(
              `${API_ENDPOINT}/search/packages/${package_uid}/check/flight/?room=${room_uid}&adults=${pax?.adults}&children=${pax?.children}&infants=${pax?.infants}`,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true,
                timeout: 10000
              }
            ); 

            setReservationState({ 
              status: "valid",
              package: res?.data,
              flight: resFlight?.data?.flight,
              property: res?.data?.property,
              session: res?.data?.session,
              job: res?.data?.job,
            });

            // import 'antd/dist/antd.css';
            // Update GAM tag => GA Ecommerce
            //console.log(quoteDetails);
            //console.log(res?.data)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event:  quoteDetails?.is_flex?'ReserveFlexDeal':'ReserveBasicDeal',
                ecommerce: {
                    currencyCode: res?.data?.currency,
                    checkout: {
                      actionField: {
                        step: 1
                      },
                      products: [{
                          id: res?.data?.uid,
                          name: res?.data?.code,
                          category: res?.data?.destination?.country?.name+'/'+res?.data?.destination?.city?.name,
                          price: res?.data?.price,
                          brand: 'afnu',
                          variant: quoteDetails?.is_flex?'flex':'standard',
                          quantity: 1
                      }]
                    }
                }  
            });
/*
            window.dataLayer.push({
              event: "add_to_cart",
              ecommerce: {
                currency: quoteDetails?.currency,
                value: quoteDetails?.price,
                items: [
                {
                  item_id: res?.data?.uid,
                  item_name: res?.data?.code,
                  index: 0,
                  item_brand: quoteDetails?.destination?.city_name,
                  item_category: quoteDetails?.is_flex?'flex':'standard',
                  item_category2: "stars: " + (quoteDetails.properties && quoteDetails?.properties.length>0 ? quoteDetails.properties[0]?.stars : 0),
                  item_category3: "nights: " + quoteDetails?.nights,
                  item_category4: "weekend: " + quoteDetails?.weekend_jewish,
                  item_category5: "departure: "+ quoteDetails?.from_date,
                  item_list_id:   "deals",
                  item_list_name: "Deals",
                  item_variant:   "basic",
                  location_id: quoteDetails?.destination?.city_id,
                  price: res?.data?.price,
                  quantity: 1
                }
                ]
              }
            });
*/

        } else {
            // if quote not found raise an error
            setErrorMessage({
              title: `לא הצלחנו למצוא את הדיל המבוקש`,
              message: 'שגיאה 1'
            });
            setOpenError({
              status: true,
              go_back: true
            });

          } 
        } else {
          setErrorMessage({
            title: `לא הצלחנו למצוא את הדיל המבוקש`,
            message: 'שגיאה 2'
          });
          setOpenError({
            status: true,
            go_back: true
          });
        }
        
        // !!!!!
        // WARNING: IF I DELETE THE FOLLOWING SECTION IT BREAKS THE BAGGAGE SELECTION IN THE FORM!!!! (although it only seems to be related to the redundant rooms)
        const res2 = await axios.get(
          `${API_ENDPOINT}/search/packages/${res?.data.uid}/rooms/${res?.data?.property?.room?.uid}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }
        );
    
        //console.log(`${API_ENDPOINT}/search/packages/${res?.data.uid}/rooms/${res?.data?.property?.room?.uid}`);
        //console.log(res2?.data)
        //console.log(res?.data)
        
        setDetailRoomState(res2?.data);
        
        let sr = res2?.data.find((item) => {
          setRoomUID(item.uid);
          return item?.best_offer == true;
        });
        setSelectedRoom(sr);        
        
        
        console.log('PRESERVE DATA')
        preserveData(res?.data, sr);
        
        // If page gets blank at this point it means we're facing an incompatibility issue with React version. 
        // aparently, you cant pass async() to useEffect is react version lower than 17.
        // So either make sure we're running v17+ or use the fix described in the GitHub thread (but not it should be fixed for all pages that call useEffect):
        /*
          The following is a fix for a bug that happens in React v16 (fixed in v17)
          Updated according to https://github.com/facebook/react/issues/14838#issuecomment-463154298
          In order to fix the "Uncaught TypeError: destroy is not a function" error message
        */

      } else {
          room_uid = packageDetails?.selectedRoom?.uid
          pax = packageDetails?.data?.pax;
          rooms = packageDetails?.data?.property?.rooms_occupancies;
        // intervalCheckFlightAndHotel();
      }
    //alert(packageDetails.data==undefined)
    //console.log(packageDetails)
    //setDetailState(packageDetails.data, packageDetails.selectedRoom);

      if (package_uid) {
          console.log('Performing real time check...')
          /*
          The following function will perform real time check with flights supplier and only after a response was received it will check the properties supplier.
          After supplier's response it'll loop every 15 seconds and second a new query to the flights supplier.

          If at any point the supplier failed to respond within the timeout limit, it will retry sending the request again up to 3 times.
          */
      }
    } catch(e) {
      console.log('EXCEPTION')
      console.log(e)
      // if quote not found raise an error
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ModalQuoteNotFound',
          'pageUrl': window.location,
          'pageTitle' : window.title
      });


        setErrorMessage({
          title: `לא הצלחנו למצוא את הדיל המבוקש`,
          message: e?.message
        });

        setOpenError({
          status: true,
          go_back: true
        });
    }
  }, [packageDetails]);

  ///////////////////////////////

  const handleClose = () => {
    setOpenPriceChange(false);
    setOpenError(false);
    setFinishProgress(false)

    //history.push("/");
  };


  const handleCloseReturnToDeal = ()  => {
    setOpenPriceChange(false);
    setOpenError(false);
    setFinishProgress(false)
    //console.log(window.location.pathname)
    const url_array = window.location.pathname.split('/')
    //console.log(url_array)
    //console.log(url_array[0]+'/'+url_array[1]+'/'+url_array[2])
    history.push(url_array[0]+'/'+url_array[1]+'/'+url_array[2]);
  }

  const handleOk = () => {
    setOpenPriceChange(false);
  }

  const handleCloseAncillaries = () => {
    setOpenAncillaries(false);
  };
  const handleCloseTerms = () => {
    setTermsModal(false);
  };

  /*const handleClosePriceChange = () => {
    setPriceChangeModal(false);
  };*/

  const GetMealPlanCode = (code) => {
    let local_meal_plan_code='RO';
    
    switch(code) {
        case 'SA'||'SP'||'SC': local_meal_plan_code='RO';break; // Accommodation only
        case 'HD': local_meal_plan_code='BB';break;             // B&B 
        case 'HM'||'MB'||'MP': local_meal_plan_code='HB';break; // HAlf Board
        case 'PC'||'PV': local_meal_plan_code='FB';break;       // Full board
        case 'TI'||'TP': local_meal_plan_code='AI';break;       // All Inclusive
        default: local_meal_plan_code='RO'
    }

    return local_meal_plan_code;
  }

  const onFinish = async (values) => {
    console.log("Success:", values);
    console.log("arr:", baggageState);

    if (values?.email_verification == values?.email) {
      let dataToSend = {
        package_uid: packageDetails?.data?.uid,
        pax: packageDetails?.pax,
        rooms: packageDetails?.data?.property?.rooms_occupancies,
        //flight_uid: packageDetails?.flight?.uid,
        //flight_inventory_uid: reservationState?.flight?.inventory_uid,
        //property_uid: reservationState?.property?.uid,
        //property_inventory_uid: reservationState?.property?.inventory_uid,
        owner: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone
        },
        passengers: values.passengers,
        pax: packageDetails?.data?.pax,
        ancillaries: reservationState?.package?.ancillaries, //{
          //...reservationState?.package?.ancillaries,
          //price: reservationState?.package?.ancillaries?.cost,
        //},
        baggage: baggageState,
      };
      
      setFinishProgress(true)

      console.log(dataToSend);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      };

      // Store reservation details in DB + get payment link and redirect user to supplier's payment page
      try {
        let res = await axios.post(
          `${API_ENDPOINT}/quote/${quote_uid}`,
          dataToSend,
          config
        );
  
        //console.log(res)
        //console.log(reservationState)
        
        if (res!=null && res.data) {
          // If quote update failed
          if (res?.data?.error!=null || res?.data?.status=="error") {
            setErrorMessage({
              message:  res?.data?.error?.message + '<br />' + res?.data?.description,
              title: `שגיאה ${res?.data?.error?.id}`
            });
            setOpenError({
              status: true,
              go_back: false
            });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'ErrorModal',
                'pageUrl': window.location,
                'pageTitle' : window.title,
                'message' : res?.data?.error?.message + '<br />' + res?.data?.description
            });
          } else {
            
            // redirect instead of using iframe
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });  
            window.dataLayer.push({
                event: 'ReservationFormSubmit',
                'pageUrl': window.location,
                'pageTitle' : window.title,
                
                ecommerce: {
                  checkout: {
                    actionField: {
                      step: 2,
                      option: 'Order Form Submit'
                    },
                    /*products: [{
                      id: res?.data?.uid,
                      name: res?.data?.code,
                      category: res?.data?.destination?.country?.name+'/'+res?.data?.destination?.city?.name,
                      price: res?.data?.price,
                      brand: 'afnu',
                      variant: quoteDetails?.is_flex?'flex':'standard',
                      quantity: 1
                    }]*/ 
                  }
                },
                /*
                ecommerce: {
                    currencyCode: res?.data?.currency,
                    checkout: {
                      actionField: {
                        step: 1
                      },
                      products: [{
                          id: res?.data?.uid,
                          name: res?.data?.code,
                          category: res?.data?.destination?.country?.name+'/'+res?.data?.destination?.city?.name,
                          price: res?.data?.price,
                          brand: 'afnu',
                          variant: quoteDetails?.is_flex?'flex':'standard',
                          quantity: 1
                      }]
                    }
                }  
                */
            });

            window.dataLayer.push({
              event: "begin_checkout",
              ecommerce: {
                currency: quoteDetails?.currency,
                value: quoteDetails?.price,
                items: [
                {
                  item_id: quoteDetails?.uid,
                  item_name: quoteDetails.flights[0]?.outbound?.airport_code_arrival+quoteDetails?.id, // deal.to_iata+deal.id
                  index: 0,
                  item_brand: quoteDetails?.destination?.city_name_en,
                  //item_category: quoteDetails?.is_flex?'flex':'standard',
                  item_category: `adults:${quoteDetails?.adults},children:${quoteDetails?.children},infants:${quoteDetails?.infants},rooms:${quoteDetails?.property?.rooms_count}`,
                  item_category2: "stars: " + (quoteDetails?.properties && quoteDetails?.properties.length>0 ? (quoteDetails?.properties[0]?.stars):0) + " meals: " + GetMealPlanCode(quoteDetails?.properties[0]?.inventory[0]?.rooms[0]?.meal_code),
                  item_category3: "nights: " + quoteDetails?.nights,
                  item_category4: "departure: " + GetTimeToDeparture(quoteDetails?.from_date),
                  item_category5: "month: "+ quoteDetails?.from_date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
                  item_list_id:   "deals",
                  item_list_name: "Deals",
                  item_variant:   quoteDetails?.is_flex?'flex':'standard',
                  location_id: quoteDetails?.destination?.city_id,
                  price:  quoteDetails?.price,
                  quantity: 1
                }
                ]
              },
              eventCallback: function() {
                document.location = res?.data?.url;
              }
            });

            setTimeout(function() {window.location = res?.data?.url}, 500);
            return

            // If quote updated sucessfully and got the payment link
            setReservationState({
              ...reservationState,
              reservation: {
                id: res?.data?.id,
                payment_link: res?.data?.url,
                payment_key: Math.random().toString()
              },
            });
            
            // wait 5 seconds before showing iframe content (let it some extra time to load)
            console.log('loading payment screen')
            console.log(new Date())
            setTimeout(function() {
              console.log(new Date())
              document.getElementById('ifrm_wrapper').style.setProperty('display','');
            }, 5000);

            setTimeout(function() {
              // Show whatsapp help button after 35 seconds
              document.getElementById('btn_whatsapp_help').style.setProperty('display','flex')
            }, 30000)
      
            console.log(reservationState)
          }
        } else {
          // If not response received while trying to update quote & fetch payment link
          setErrorMessage({
            message: `ארעה תקלה בנסיון החיבור לחברת האשראי. אנא נסו שוב בעוד מספר רגעים או כתבו ל support@afnu.co.il במידה והתקלה חוזרת על עצמה`,
            title: `תקלה תקשורת #50`
          });
          setOpenError({
            status: true,
            go_back: false
          });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              event: 'ErrorModal',
              'pageUrl': window.location,
              'pageTitle' : window.title,
              'message' : res?.data?.error?.message + '<br />' + res?.data?.description
          });
        }
      } catch(e) {
        console.error(e)
        setErrorMessage({
          message:  'לא הצלחנו ליצור קשר עם שרת התשלומים, אנא בדקו את חיבור האינטרנט שלכם ונסו שוב בעוד מספר רגעים',
          title: `תקלת תקשורת #52`
        });
        setOpenError({
          status: true,
          go_back: false
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ErrorModal',
            'pageUrl': window.location,
            'pageTitle' : window.title,
            'message' : 'Payments API connection error'
        });
      }
      

    } else {
      addToast("Emails should be same", { appearance: "error" });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    addToast("Check form fields", { appearance: "error" });
    setFinishProgress(false)
  };

  const handleAncillariesChange = (e, cost) => {
    console.log(e.target?.checked);
    setAncillariesState(e.target?.checked);
    console.log(cost);
    if (e.target?.checked == true) {
      setReservationState({
        ...reservationState,
        package: {
          ...reservationState.package,
          price: parseFloat(reservationState?.package?.price) + parseFloat(cost),
        },
      });
    } else {
      setReservationState({
        ...reservationState,
        package: {
          ...reservationState.package,
          price: parseFloat(reservationState?.package?.price) - parseFloat(cost),
        },
      });
    }
  };
  const onOpenLink = (e, link, text='') => {
    //window.open(link)
    e.preventDefault()
    
    setTermLink(link);
    setTermText(text)
    setTermsModal(true);
    
  };

  const gotoURL = (link) => {
    window.open(link)
  };

  const openAncillariesModal = (data) => {
    console.log(data);
    setAncillariesMessage(data);
    setOpenAncillaries(true);
  };

  const handleSubmitBasic = () => {
    setOpenYesNo(true);
  }

  const handleYes = () => {
    setOpenYesNo(false)
    // TODO ...
    //handleSubmitBasicProcess()
  }

  const handleNo = () => {
    setOpenYesNo(false)
  }

  const changeBaggage = (e, passenger_group, baggage, passenger, passenger_index, bag_index, direction, price) => {
    //const bagid = baggage.options.id;
    //console.log(direction)
    //console.log(bag_index)
    const force_two_way = baggage.baggage.two_way;

    const data = {
      ...baggage,
      key: bag_index + '|' + passenger + '|' + passenger_index + '|' + direction + '|' + price + '|' + baggage.passenger.index + '|' + baggage.baggage.code,
      //passenger_group: passenger_group,
      //passenger_index: index,
      //direction: direction,
    };
    //console.log(data)

  

    if (e=='checked' || e.target?.checked) {
      if (force_two_way) {
        setYesNoMessage({
          title: `הערה חשובה`,
          message: `<p>ניתן להזמין סוג זה של כבודה לשני הכוונים יחד בלבד (הלוך וחזור).
          <p>האם אתם מעוניינים להמשיך?</p>`
        });
        setOpenYesNo({
         status: true,
         go_back: true
        });
      }

      setTotalBaggagePrice(totalBaggagePrice+ price*1)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'AddBag',
          'pageUrl': window.location,
          'pageTitle' : window.title,
          'price': totalBaggagePrice+ price*1
      });

      // check if bag not already in array
      if (!(baggageState.find((item) => {
        return data.key == item.key
      }))) {
        //console.log('bag added')   
        //console.log(data)
        baggageState.push(data);
      } 
    } else {
      if (force_two_way) {
        setYesNoMessage({
          title: `הערה חשובה`,
          message: `<p>כבודה זו ניתן להזמין רק לשני הכוונים יחד (הלוך וחזור)</p>
          <p>האם אתם בטוחים שאתם רוצים להסיר אותה?`
        });
        setOpenYesNo({
         status: true,
         go_back: true
        });
      }

      setTotalBaggagePrice(totalBaggagePrice- price*1)

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'RemoveBag',
          'pageUrl': window.location,
          'pageTitle' : window.title,
          'totalBagsPrice' : totalBaggagePrice- price*1
      });

      baggageState = baggageState.filter((item) => {
        return data.key != item.key;
      });
    }
    //console.log(baggageState)
    setBaggageState(baggageState);
  };
  
  const handleValuesChange = (values) => {
    //console.log(values);
  };

  
  const generatePaxArray = (adultsCount,childrenArray) => {
    const pax = [];

    for (let i=0;i<adultsCount;i++) 
      pax.push(30);

    childrenArray.map(age=>pax.push(age))  ;

    return pax;
  };

  
  // get Guest Category codes
  const getGuestCategory = (age) => {
    if (age < 2)
      return {code:'I',name_he:'תינוק/ת (0-23 חודשים)',name:'infants'};

      if (age <= 18)
        return {code:'C',name_he:`ילד/ה  או נער/ה (עד גיל 17)`,name:'children'};
        //return {code:'C',name_he:`ילד/ה (בגיל ${age})`,name:'children'};
      
      return {code:'A',name_he:'מבוגר (18+)',name:'adults'}
  }
  
  console.log('ppp')
  //console.log(packageDetails?.data)
  
  let passengersPresentationArray = [];
  let roomsArray = [];
  let passengerIndex = 0;

  //let totalPassengers = 0;

  //console.log(packageDetails?.data?.rooms_occupancies)
  
  // NEW - ADDED WHEN WE'VE CREATED THE NEW SCAN WITH PACKAGE_GROUPS: Use the pax saved in the quote to generate the passengers form
  if (quoteDetails.room1_adults_count > 0) {
      
    for (let room_index = 1; room_index<=4 ; room_index++) {

      let room;

      switch(room_index) {
        case 1: if (quoteDetails.room1_adults_count > 0) {
                    room = generatePaxArray(quoteDetails.room1_adults_count, quoteDetails.room1_children_ages)
                } else continue;
                break;
        case 2: if (quoteDetails.room2_adults_count > 0) {
                    room = generatePaxArray(quoteDetails.room2_adults_count, quoteDetails.room2_children_ages)
                } else continue;
                break;
        case 3: if (quoteDetails.room3_adults_count > 0) {
                    room = generatePaxArray(quoteDetails.room3_adults_count, quoteDetails.room3_children_ages)
                } else continue;
                break;
        case 4: if (quoteDetails.room4_adults_count > 0) {
                    room = generatePaxArray(quoteDetails.room4_adults_count, quoteDetails.room4_children_ages)
                } else continue;
                break;
      }

      room = room.map((age, passenger_index)=>{
        return ({
          index: passengerIndex,
          name: passengerIndex,
          key: passengerIndex,
          isListField: true,
          fieldKey: passengerIndex++,
          passenger: getGuestCategory(age).name,
          passenger_he: getGuestCategory(age).name_he,
          passenger_category: getGuestCategory(age).code,
          age: age
        })
      })

      console.log(room)
      roomsArray.push({
        index: room_index,
        pax: room
      });

      console.log(roomsArray)
    }
  } else 
    if (packageDetails?.data?.property?.rooms_occupancies) { 
      const rooms_occupancies = packageDetails?.data?.property?.rooms_occupancies 
      //console.log(rooms_occupancies)    

      if (rooms_occupancies.room1_adults_count > 0) {
        
        // room1
        const room_index = 1;
        const room = generatePaxArray(
          rooms_occupancies.room1_adults_count, 
          rooms_occupancies.room1_children_ages
        ).map((age, passenger_index)=>{
          return ({
            index: passengerIndex,
            name: passengerIndex,
            key: passengerIndex,
            isListField: true,
            fieldKey: passengerIndex++,
            passenger: getGuestCategory(age).name,
            passenger_he: getGuestCategory(age).name_he,
            passenger_category: getGuestCategory(age).code,
            age: age
          })
        })

        roomsArray.push({
          index: room_index,
          pax: room
        });

        // room2
        if (rooms_occupancies.room2_adults_count > 0) {
          const room_index = 2;
          const room = generatePaxArray(
            rooms_occupancies.room2_adults_count, 
            rooms_occupancies.room2_children_ages
          ).map((age, passenger_index)=>{
            return ({
              index: passengerIndex,
              name: passengerIndex,
              key: passengerIndex,
              isListField: true,
              fieldKey: passengerIndex++,
              passenger: getGuestCategory(age).name,
              passenger_he: getGuestCategory(age).name_he,
              passenger_category: getGuestCategory(age).code,
              age: age
            })
          })

          roomsArray.push({
            index: room_index,
            pax: room
          });
        }

        //room3
        if (rooms_occupancies.room3_adults_count > 0) {
          const room_index = 3;
          const room = generatePaxArray(
            rooms_occupancies.room3_adults_count, 
            rooms_occupancies.room3_children_ages
          ).map((age, passenger_index)=>{
            return ({
              index: passengerIndex,
              name: passengerIndex,
              key: passengerIndex,
              isListField: true,
              fieldKey: passengerIndex++,
              passenger: getGuestCategory(age).name,
              passenger_he: getGuestCategory(age).name_he,
              passenger_category: getGuestCategory(age).code,
              age: age
            })
          })

          roomsArray.push({
            index: room_index,
            pax: room
          });
        }

        //room4
        if (rooms_occupancies.room4_adults_count > 0) {
          const room_index = 4;
          const room = generatePaxArray(
            rooms_occupancies.room4_adults_count, 
            rooms_occupancies.room4_children_ages
          ).map((age, passenger_index)=>{
            return ({
              index: passengerIndex,
              name: passengerIndex,
              key: passengerIndex,
              isListField: true,
              fieldKey: passengerIndex++,
              passenger: getGuestCategory(age).name,
              passenger_he: getGuestCategory(age).name_he,
              passenger_category: getGuestCategory(age).code,
              age: age
            })
          })

          roomsArray.push({
            index: room_index,
            pax: room
          });
        }

      }

      if (rooms_occupancies.room2_adults_count > 0) {
        const room_index = 2;
        const room = generatePaxArray(
          rooms_occupancies.room2_adults_count, 
          rooms_occupancies.room2_children_ages
        ).map((age, passenger_index)=>{
          return ({
            index: passengerIndex,
            name: passengerIndex,
            key: passengerIndex,
            isListField: true,
            fieldKey: passengerIndex++,
            passenger: getGuestCategory(age).name,
            passenger_he: getGuestCategory(age).name_he,
            passenger_category: getGuestCategory(age).code,
            age: age
          })
        })

        roomsArray.push({
          index: room_index,
          pax: room
        });
      }

      if (rooms_occupancies.room3_adults_count > 0) {
        const room_index = 3;
        const room = generatePaxArray(
          rooms_occupancies.room3_adults_count, 
          rooms_occupancies.room3_children_ages
        ).map((age, passenger_index)=>{
          return ({
            index: passengerIndex,
            name: passengerIndex,
            key: passengerIndex,
            isListField: true,
            fieldKey: passengerIndex++,
            passenger: getGuestCategory(age).name,
            passenger_he: getGuestCategory(age).name_he,
            passenger_category: getGuestCategory(age).code,
            age: age
          })
        })

        roomsArray.push({
          index: room_index,
          pax: room
        });
      }

      if (rooms_occupancies.room4_adults_count > 0) {
        const room_index = 4;
        const room = generatePaxArray(
          rooms_occupancies.room4_adults_count, 
          rooms_occupancies.room4_children_ages
        ).map((age, passenger_index)=>{
          return ({
            index: passengerIndex,
            name: passengerIndex,
            key: passengerIndex,
            isListField: true,
            fieldKey: passengerIndex++,
            passenger: getGuestCategory(age).name,
            passenger_he: getGuestCategory(age).name_he,
            passenger_category: getGuestCategory(age).code,
            age: age
          })
        })

        roomsArray.push({
          index: room_index,
          pax: room
        });
      }
        

      console.log(roomsArray)
    }
  /*
  if (packageDetails?.data?.pax) {
    //console.log(packageDetails?.data?.pax)
    //totalPassengers = 0;
    let passengersArray = [];

    // Loop passengers and build the presentation array
    Object.keys(packageDetails?.data?.pax).map((key) => {
      if (packageDetails?.data?.pax[key] > 0) {
        for (let inc = 0; inc < packageDetails?.data?.pax[key]; inc++) {
          passengersArray.push(key);
          console.log('KEY: ' + key)
          console.log(key)
        }
      }

      //totalPassengers =  totalPassengers + parseInt(packageDetails?.data?.pax[key]);
    });

    //passengersPresentationArray = [];
    
    passengersArray.map((item, index) => {
      let category_name_he = '';
      let category_code = '';
      switch(item) {
        case 'adults':    category_code='A';  category_name_he='מבוגר';break;
        case 'children':  category_code='C';  category_name_he='ילד';break;
        case 'infants':   category_code='I';  category_name_he='תינוק';break;
        case 'seniors':   category_code='S';  category_name_he='אזרח ותיק';break;
      };

      passengersPresentationArray.push({
        name: index,
        key: index,
        isListField: true,
        fieldKey: index,
        passenger: item,
        passenger_he: category_name_he,
        passenger_category: category_code
      });
    });
  }
  

  console.log(passengersPresentationArray)
  */
  let monthOptions = [];
  let dateOptions = [];
  let yearOptions = [];

  let ratingArr = [1, 2, 3, 4, 5, 6];

  for (let i = 1; i <= 31; i++) {
    dateOptions.push(i);
  }
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(i);
  }
  //console.log(errorMessage);

  passengerIndex = -1;

  return (
    <>
      <Navbar name="חזרה לדף הדיל" tabIndex={0}/>
      
      <ErrorModal
        errorMessage={errorMessage}
        openError={openError?.status}
        go_back={openError.go_back}
        onClose={handleClose}
        onCloseReturnToDeal={handleCloseReturnToDeal}
      />      
      <YesNoModal
        message={yesNoMessage}
        openYesNo={openYesNo}
        onYes={handleYes}
        onNo={handleNo}
      />

      <TermsModal
        link={termLink}
        text={termText}
        open={termsModal}
        onClose={handleCloseTerms}
      />{" "}
      <AncillariesModal
        open={openAncillaries}
        onClose={handleCloseAncillaries}
        data={ancillariesMessage}
      />
      <div style={{color:'#fff', fontSize:'1px'}} tabIndex={0}>במידה והינכם מתקשים לבצע את ההזמנה באתר, תוכלו ליצור קשר עם מחלקת השירות שלנו על ידי שליחת הודעת וואטסאפ למספר 050-7801801 ולציין את קוד ההזמנה: {packageDetails?.data?.code}</div>
      {reservationState && reservationState?.status == "valid" ? (
        <div className="ugly container">
          <div className="mt-4">
            <div className="form-body">
              <Form
                //  {...layout}
                name="dynamic_form_nest_item"
                initialValues={{
                  remember: true,
                }}
                scrollToFirstError={true}
                onFinish={onFinish}
                onValuesChange={handleValuesChange}
                onFinishFailed={onFinishFailed}
              >
                <div className="form-bg mb-4 pd_16">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-2 portionHeading">פרטים של מבצע ההזמנה</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="title-bar ">
                        התשלום יבוצע על ידי הלקוח שעליו להיות אחד הנוסעים
                        הכלולים בהזמנה
                      </div>
                    </div>
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={5}>
                      <label className="form-label" aria-label="שם פרטי של מבצע ההזמנה" tabIndex={0}>שם פרטי
                      <Form.Item
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                      <Input className="antd_input" size="large"  aria-label="שם פרטי של מבצע ההזמנה" />
                      </Form.Item>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <label className="form-label "  aria-label="שם משפחה של מבצע ההזמנה"   tabIndex={0}>שם משפחה
                      <Form.Item
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input className="antd_input" size="large"  aria-label="שם משפחה של מבצע ההזמנה" />
                      </Form.Item></label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={5}>
                      <label className="form-label " aria-label="כתובת אימייל של מבצע ההזמנה" tabIndex={0}>כתובת אימייל
                      <Form.Item
                        name="email"
                        title="כתובת אימייל של מבצע ההזמנה"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                          {
                            type: "email",
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input
                          className="antd_input"
                          type="email"
                          size="large"
                          aria-label="כתובת אימייל של מבצע ההזמנה"
                        />
                      </Form.Item></label>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <label className="form-label " aria-label="אימות כתובת אימייל של מבצע ההזמנה" tabIndex={0}>אימות כתובת אימייל
                      <Form.Item
                        name="email_verification"
                        aria-label="אימות כתובת אימייל של מבצע ההזמנה"
                        dependencies={["email"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("email") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "שגיאה: כתובת האימייל אינן זהות "
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="antd_input"
                          aria-label="אימות כתובת אימייל של מבצע ההזמנה"
                          type="email"
                          size="large"
                        />
                      </Form.Item></label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={5}>
                      <label className="form-label " aria-label="מספר סלולרי של מבצע ההזמנה" tabIndex={0}>מספר סלולרי (טלפון נייד)
                      <Form.Item
                        name="phone"
                        aria-label="מספר סלולרי של מבצע ההזמנה"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                          {
                            pattern: new RegExp(/^-?([0][5-6][0-9])?[\s.-]?([0-9]{7})?$/), // "^[\x20-\x7F]+$",
                            message: "חובה לרשום מספר סלולרי ישראל בלבד",
                          },
                        ]}
                      >
                        <Input
                          className="antd_input"
                          type="tel"
                          aria-label="מספר סלולרי של מבצע ההזמנה"
                          size="large"
                        />
                      </Form.Item></label>
                    </Grid>
                  </Grid>
                  {/*
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={6} lg={5}>
                      <label className="form-label ">רְחוֹב<
                      <Form.Item
                        name="street"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input className="antd_input" size="large" />
                      </Form.Item>/label>
                    </Grid>
                    <Grid item xs={4} sm={4} lg={3}>
                      <label className="form-label ">מספר הבית
                      <Form.Item
                        name="street_number"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input className="antd_input" size="large" />
                      </Form.Item></label>
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={6} lg={5}>
                      <label className="form-label ">ישוב
                      <Form.Item
                        name="settlement"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input className="antd_input" size="large" />
                      </Form.Item></label>
                    </Grid>
                    <Grid item xs={4} sm={4} lg={3}>
                      <label className="form-label ">מיקוד
                      <Form.Item
                        name="zipcode"
                        rules={[
                          {
                            required: true,
                            message: "שדה חובה",
                          },
                        ]}
                      >
                        <Input className="antd_input" size="large" />
                      </Form.Item></label>
                    </Grid>
                  </Grid>
                    */}
                </div>

                {
                roomsArray.map((room) => (
                <>
                  {roomsArray.length>1?<h1 style={{fontWeight:600}}>נוסעים בחדר #{room.index}</h1>:<></>}
                  <Form.List name="passengers">
                    {(room_pax, { add, remove }) => {
                      room_pax = room.pax;
                      return (
                        <>
                          {room_pax.map(
                            (
                              { key, name, passenger, passenger_he, passenger_category, fieldKey, age, ...restField },
                              index
                            ) => {
                              passengerIndex++;
                              return (
                              <div className="form-bg mb-4 pd_16" tabIndex={0}>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <h4 className="mb-2 portionHeading">
                                      נוסע {index+1}:  
                                      {" "}
                                      {passenger_he}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="title-bar ">
                                    כדי למנוע בעיות בעלייה למטוס, חשוב להשתמש בשם המלא (פרטי ומשפחה) בדיוק כפי שמופיע בדרכון
                                    </div>
                                  </div>
                                </div>
                                <Form.Item 
                                  {...restField}
                                  name={[name, "room_index"]}
                                  fieldKey={[fieldKey, "room_index"]}
                                  initialValue={room.index}
                                  style={{display:'none'}}
                                  >
                                  <Input />
                                </Form.Item>

                                <Form.Item 
                                  {...restField}
                                  name={[name, "passenger_index"]}
                                  fieldKey={[fieldKey, "passenger_index"]}
                                  initialValue={passengerIndex}
                                  style={{display:'none'}}
                                  >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "category"]}
                                  fieldKey={[fieldKey, "category"]}
                                  initialValue={passenger_category}
                                ></Form.Item>

                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6} lg={5}>
                                    <label className="form-label " aria-label="שם פרטי " tabIndex={0}>שם פרטי
                                    <Form.Item
                                      {...restField}
                                      name={[name, "first_name"]}
                                      aria-label="שם פרטי "
                                      fieldKey={[fieldKey, "first_name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "שדה חובה",
                                        },
                                        {
                                          pattern: "^[\x20-\x7F]+$",
                                          message: "חובה לרשום באנגלית (בדיוק כפי שמופיע בדרכון)",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="antd_input"
                                        aria-label="שם פרטי "
                                        size="large"
                                      />
                                    </Form.Item></label>
                                  </Grid>
                                  <Grid item xs={12} sm={6} lg={5}>
                                    <label className="form-label " aria-label="שם משפחה "  tabIndex={0}>
                                      שם משפחה
                                    
                                    <Form.Item
                                      {...restField}
                                      name={[name, "last_name"]}
                                      fieldKey={[fieldKey, "last_name"]}
                                      aria-label="שם משפחה "
                                      rules={[
                                        {
                                          required: true,
                                          message: "חובה לרשום באנגלית (בדיוק כפי שמופיע בדרכון)",
                                        },
                                        {
                                          pattern: "^[\x20-\x7F]+$",
                                          message: "חובה לרשום באנגלית (בדיוק כפי שמופיע בדרכון)",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="antd_input"
                                        aria-label="שם משפחה "
                                        size="large"
                                      />
                                    </Form.Item></label>
                                  </Grid>
                                
                                  <Grid item xs={3} sm={5} lg={5}>
                                    <label className="form-label " title="מין הנוסע/ת" tabIndex={0}>מין
                                    
                                    <Form.Item
                                      {...restField}
                                      name={[name, "gender"]}
                                      aria-label="מין הנוסע/ת "
                                      fieldKey={[fieldKey, "gender"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "שדה חובה",
                                        },
                                      ]}
                                    >
                                      <Select
                                        
                                        variant="outlined"
                                        aria-label="מין הנוסע/ת "
                                        style={{ }}
                                      >
                                        <MenuItem value="m" aria-label="זכר">זכר</MenuItem>
                                        <MenuItem value="f" aria-label="נקבה">נקבה</MenuItem>
                                      </Select>
                                    </Form.Item></label>
                                  </Grid>
                                  <Grid item xs={9} sm={5} lg={5} justifyContent="space-around">
                                    <Grid container spacing={0}>
                                      <Grid item xs={12}>
                                        <label className="form-label" tabIndex={0}>
                                        תאריך לידה
                                      </label>
                                      </Grid>
                                      <Grid item xs>
                                        <Grid container spacing={0} justifyContent="space-around" alignContent="stretch">
                                          <Grid item xs={4}>
                                          <label style={{width:'100%'}} tabIndex={0}>
                                            <Form.Item 
                                                    aria-label="שנת לידה"
                                                    {...restField}
                                                    name={[name, "birth_year"]}
                                                    fieldKey={[fieldKey, "birth_year"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "שדה חובה",
                                                      },
                                                    ]}
                                                    style={{ width: "100%" }}
                                                  >
                                                    
                                                    <Select
                                                      //defaultValue="1920"
                                                      variant="outlined"
                                                      aria-label="שנת לידה"
                                                      
                                                    >
                                                      {
                                                        // build years array
                                                        Array(
                                                          // start year
                                                          //new Date().getUTCFullYear() - (new Date().getUTCFullYear() - (age<18?(age<2?3:4):99-18))
                                                          new Date().getUTCFullYear() - (new Date().getUTCFullYear() - (age<18?20:92))
                                                        )
                                                          .fill('')
                                                          .map((v, idx) => 
                                                            //new Date().getUTCFullYear() - idx - (age<17?(age<2?0:age-1):17) 
                                                            new Date().getUTCFullYear() - idx
                                                          )
                                                          .map((year) => (
                                                              <MenuItem value={year}>
                                                                {year}
                                                              </MenuItem>
                                                          ))
                                                      }

                                                    </Select>
                                                    </Form.Item>
                                                    שנה
                                                    </label>
                                                  
                                                  
                                                  
                                          </Grid>
                                          <Grid item xs={4}>
                                            <label style={{width:'100%'}}  tabIndex={0}>
                                            <Form.Item
                                                    {...restField}
                                                    name={[name, "birth_month"]}
                                                    aria-label="חודש לידה"
                                                    fieldKey={[fieldKey, "birth_month"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "שדה חובה",
                                                      },
                                                    ]}
                                                    style={{width:'100%'}}
                                                  >
                                                    <Select
                                                      //defaultValue="1"
                                                      variant="outlined"
                                                      aria-label="חודש לידה"
                                                    
                                                    >
                                                      {yearOptions &&
                                                        monthOptions.map((month) => (
                                                          <MenuItem value={month}>
                                                            {month}
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                  </Form.Item>
                                                  חודש
                                                  </label>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <label style={{width:'100%'}}  tabIndex={0}>
                                            <Form.Item
                                                    {...restField}
                                                    aria-label="יום הלידה"
                                                    name={[name, "birth_day"]}
                                                    fieldKey={[fieldKey, "birth_day"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "שדה חובה",
                                                      },
                                                    ]}
                                                    style={{width:'100%'}}
                                                  >
                                                    <Select
                                                        aria-label="יום הלידה"
                                                      //defaultValue="1"
                                                      variant="outlined"
                                                    
                                                    >
                                                      {yearOptions &&
                                                        dateOptions.map((date) => (
                                                          <MenuItem value={date}>
                                                            {date}              
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                  </Form.Item>
                                                  יום
                                                  </label>
                                          </Grid>

                                      </Grid>
                                        
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                
                                <Grid container className="baggage">
                                  {reservationState?.flight?.baggage?.map(
                                    (item, i) => {

                                      const passenger_index = passengerIndex;

                                      //console.log(item)
                                        return (
                                          <Grid item xs={12} sm={12} md={8} key={'direction'+i}>
                                            <Grid container>
                                              <Grid item><h4 className="mt-3 mb-2 portionHeading">בחר {item?.direction=='out'?'כבודה בהלוך':'כבודה בחזור'}</h4></Grid>
                                            </Grid>
                                            <Grid container className="boxes" columnSpacing={1} justifyContent="space-between" alignItems="center" >
                                              {item?.options?.map(
                                                (option, ii) => {
                                                  let passenger_group_code;
                                                  switch(passenger) {
                                                    case 'adults': passenger_group_code= 'A'; break;
                                                    case 'children': passenger_group_code= 'C'; break;
                                                    case 'infants': passenger_group_code= 'I'; break;
                                                    case 'seniors': passenger_group_code= 'S'; break;
                                                  }

                                                  if (!option.passenger_groups_codes.find(element => element==passenger_group_code)) 
                                                    return  (<></>)

                                                  let baggage_category_name;
                                                  switch(option.category.code) {
                                                    case 'T': baggage_category_name='טרולי';break;
                                                    case 'C': baggage_category_name='מזוודה'; break;
                                                    case 'S': baggage_category_name='תיק גב'; break;
                                                  }


                                                  // add free bags to array
                                                  if (option.price.amount==0) {
                                                    //console.log(option)
                                                    changeBaggage(
                                                      'checked',
                                                      passenger_group_code,
                                                      {
                                                        direction: item.direction,
                                                        passenger: {
                                                          index: passenger_index,
                                                          type: passenger_group_code,
                                                        },
                                                        baggage: {
                                                          index: i,
                                                          code: option.id,
                                                          category: {
                                                            id: option.category.code,
                                                            name: baggage_category_name
                                                          },
                                                          two_way: option.two_way
                                                        }
                                                      },
                                                      passenger,
                                                      passenger_index,
                                                      i,
                                                      item.direction,
                                                      option.price.amount,
                                                    );
                                                  }

                                                  
                                                  
                                                  return (
                                                    <>
                                                       
                                                      <Grid container item xs={4} sm={4} spacing={1} className="box" bagId={option.id} key={'Option'+ii} >
                                                        <label tabIndex="0"  title={`${option.price.amount==0?'כלול בהזמנה':'הוסף/הסר'} ${baggage_category_name} ${item.direction=='out'?'בהלוך':'בחזור'} (משקל: ${option.weight.amount} קג מידות: ${option.dimensions.height}x${option.dimensions.width}x${option.dimensions.length}) מחיר: $${option?.price?.amount}`} tabIndex="0" style={{width:'100%'}}>
                                                        <Grid container justifyContent="space-between" alignItems="center"  className="top">
                                                          <Grid item>{option.category_name}{baggage_category_name}</Grid>
                                                          <Grid item>{option.weight.amount} {option.weight.unit=='kg'?'ק"ג':option.weight.unit}</Grid>
                                                        </Grid>
                                                        <Grid container justifyContent="center" className="center mb-2">
                                                          <Grid item>
                                                            <Grid container>
                                                              <Grid item xs={12} className={'picture '+option.category.code}></Grid>
                                                              <Grid item xs className="caption">{option.dimensions.height}x{option.dimensions.width}x{option.dimensions.length} {option.dimensions.unit=="cm"?'ס"מ':option.dimensions.unit}</Grid>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid container justifyContent="space-between" alignItems="center" className="bottom">
                                                          <Grid item>
                                                            <Checkbox 
                                                              title={`הסר/הוסף ${baggage_category_name} ${item.direction=='out'?'בהלוך':'בחזור'} (משקל: ${option.weight.amount} קג מידות: ${option.dimensions.height}x${option.dimensions.width}x${option.dimensions.length}) מחיר: $${option?.price?.amount}`}
                                                              defaultChecked={option.price.amount==0} 
                                                              disabled={option?.price?.amount==0}
                                                              className="classes.Checkbox cb" 
                                                              onChange={(e) =>
                                                                changeBaggage(
                                                                  e,
                                                                  passenger_group_code,
                                                                  {
                                                                    direction: item.direction,
                                                                    passenger: {
                                                                      index: passenger_index,
                                                                      type: passenger_group_code,
                                                                    },
                                                                    baggage: {
                                                                      index: i,
                                                                      code: option.id,
                                                                      category: {
                                                                        id: option.category.code,
                                                                        name: baggage_category_name
                                                                      },
                                                                      two_way: option.two_way
                                                                    }
                                                                  },
                                                                  passenger,
                                                                  passenger_index,
                                                                  i,
                                                                  item.direction,
                                                                  option.price.amount
                                                                )
                                                              }
                                                              size="large"
                                                            />
                                                          </Grid>
                                                            <Grid item>
                                                              {
                                                                option.price.amount==0?
                                                                'חינם'
                                                                :(option.price?.currency=='USD'?'$'+option?.price?.amount:option.price?.currency+option?.price?.amount)
                                                              }
                                                            </Grid>
                                                        </Grid>
                                                        </label>
                                                      </Grid>
                                                      
                                                    </>
                                                    )
                                                }
                                            )}
                                              </Grid>                                                  
                                          </Grid>
                                        )}
                                  )}
                                </Grid>
                              </div>
                            )}
                          )}
                        </>
                      );
                    }}
                  </Form.List>
                </>
                ))}

{/*
                <div className="form-bg mb-4 pd_16">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-2 portionHeading">כבודה</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="title-bar ">
                      על מנת לחסוך בעלויות הכבודה, מומלץ לרכוש מזוודות יד (טרולי) שתוכלו להעלות איתכם למטוס
                      </div>
                    </div>
                  </div>
                  <Grid style={{ margin: "0px" }} container spacing={1}>
                    <Form.List name="baggage">
                      {(fields, { add, remove }) => {
                        fields = arr;

                        return fields.map(
                          (
                            { key, name, passenger, fieldKey, ...restField },
                            index
                          ) => (
                            <>
                              <Grid item xs={12} sm={12} md={12}>
                                <label className="form-label  bold mt-4">
                                  נוסע {index + 1} ({passenger})
                                </label>
                                {reservationState?.flight?.baggage_options?.hand.map(
                                  (item, i) => {
                                    if (
                                      !item?.priority?.is_priority &&
                                      item?.measurements.length > 0
                                    ) {
                                      return (
                                        <div className="baggage-div mb-4">
                                          <Grid container>
                                            <Grid item xs={1}>
                                              {item?.price?.amount > 0 ? (
                                                <Checkbox
                                                  className={
                                                    classes.Checkbox + " check"
                                                  }
                                                  onChange={(e) =>
                                                    changeBaggage(
                                                      e,
                                                      item,
                                                      passenger,
                                                      index,
                                                      i
                                                    )
                                                  }
                                                  color="primary"
                                                  inputProps={{
                                                    "aria-label":
                                                      "secondary checkbox",
                                                  }}
                                                />
                                              ) : (
                                                <LocalMallIcon fontSize="small" />
                                              )}
                                            </Grid>
                                            <Grid item xs={3}>
                                              {item?.category_name}
                                            </Grid>
                                            <Grid item xs={6}>
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.length
                                              }
                                              x
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.width
                                              }
                                              x
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.height
                                              }{" "}
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.unit
                                              }
                                              ,
                                              {
                                                item?.measurements[0]?.weight
                                                  ?.amount
                                              }{" "}
                                              {
                                                item?.measurements[0]?.weight
                                                  ?.unit
                                              }
                                            </Grid>
                                            <Grid item
                                              xs={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              {item?.price?.amount > 0 ? (
                                                <span>
                                                  {"$" +
                                                    parseFloat(
                                                      item?.price?.amount
                                                    ).toFixed(1)}
                                                </span>
                                              ) : (
                                                <span className="greenText">
                                                  כלול במחיר
                                                </span>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                                {reservationState?.flight?.baggage_options?.hold.map(
                                  (item, i) => {
                                    if (
                                      !item?.priority?.is_priority &&
                                      item?.measurements.length > 0
                                    ) {
                                      return (
                                        <div className="baggage-div mb-4">
                                          <Grid container>
                                            <Grid item xs={1}>
                                              {item?.price?.amount > 0 ? (
                                                <Checkbox
                                                  className={
                                                    classes.Checkbox + " check"
                                                  }
                                                  onChange={(e) =>
                                                    changeBaggage(
                                                      e,
                                                      item,
                                                      passenger,
                                                      index,
                                                      i
                                                    )
                                                  }
                                                  color="primary"
                                                  inputProps={{
                                                    "aria-label":
                                                      "secondary checkbox",
                                                  }}
                                                />
                                              ) : (
                                                <LocalMallIcon fontSize="small" />
                                              )}
                                            </Grid>
                                            <Grid item xs={3}>
                                              {item?.category_name}
                                            </Grid>
                                            <Grid item xs={6}>
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.length
                                              }
                                              x
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.length
                                              }
                                              x
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.length
                                              }{" "}
                                              {
                                                item?.measurements[0]
                                                  ?.dimensions?.unit
                                              }
                                              ,
                                              {
                                                item?.measurements[0]?.weight
                                                  ?.amount
                                              }{" "}
                                              {
                                                item?.measurements[0]?.weight
                                                  ?.unit
                                              }
                                            </Grid>
                                            <Grid item
                                              xs={2}
                                              style={{ textAlign: "center" }}
                                            >
                                              {item?.price?.amount > 0 ? (
                                                <span>
                                                  {"$" +
                                                    parseFloat(
                                                      item?.price?.amount
                                                    ).toFixed(1)}
                                                </span>
                                              ) : (
                                                <span className="greenText">
                                                  כלול במחיר
                                                </span>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </Grid>
                            </>
                          )
                        );
                      }}
                    </Form.List>
                  </Grid>
                </div>

                <div className="form-bg mb-4 pd_16">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-2 portionHeading">תוספות</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="title-bar ">
                        על מנת שתבטל את ההזמנה מכל סיבה שהיא, מומלץ לרכוש מגן
                        ביטול
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="baggage-div">
                    {reservationState?.package?.ancillaries.map((item) => (
                      <Grid container>
                        <Grid item xs={1}>
                          <Checkbox
                            className={classes.Checkbox + " check"}
                            color="primary"
                            value
                            onChange={(e) =>
                              handleAncillariesChange(e, item.cost)
                            }
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <div className="d-flex">
                            <VerifiedUserIcon
                              style={{ fontSize: "32px", marginLeft: "10px" }}
                            />
                            <span>
                              <h4>{item?.title} </h4>
                              <p>{item?.description}</p>
                            </span>
                          </div>

                        
                        </Grid>
                        <Grid item style={{ lineHeight: "5px" }} xs={2}>
                          <h4>
                            <b>${item?.cost}</b>
                          </h4>
                          <p>לנוסע</p>
                        </Grid>
                        <Grid item xs={1}> </Grid>
                        <Grid item xs={11} className=" left_green">
                          <span
                            onClick={(e) => openAncillariesModal(item)}
                            className="greenText"
                          >
                            פרטים נוספים
                          </span>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </div>
*/}
                <div className="form-bg mb-4 " tabIndex={0}>
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="mb-2 portionHeading">
                        <strong>סיכום הזמנה</strong>
                      </h3>
                    </div>
                  </div>
                  <div style={{ width: "100%", fontSize: '1.2em' }} className="summary-div">
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="d-flex">
                          <LocalAirportIcon
                            style={{
                              transform: "rotate(270deg)",
                              marginLeft: "2%",
                              fontSize: "33px",
                            }}
                          />
                          <div className="summaryDiv">
                            <h5>
                              <b>
                                {
                                  packageDetails?.data?.flight?.outbound
                                    ?.departure_iata_name
                                }{" "}
                                -{" "}
                                {
                                  packageDetails?.data?.flight?.outbound
                                    ?.arrival_iata_name
                                }{" "}
                                -{" "}
                                {
                                  packageDetails?.data?.flight?.inbound
                                    ?.arrival_iata_name
                                }
                              </b>
                            </h5>
                            <p>
                              {moment(
                                packageDetails?.data?.departure?.date
                              ).format("D/MMM/YYYY")}{" "}
                              - 
                              {moment(
                                packageDetails?.data?.return?.date
                              ).format("D/MMM/YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <HotelIcon
                            style={{ marginLeft: "2%", fontSize: "33px" }}
                          />
                          <div
                            className="summaryDiv"
                            style={{ lineHeight: "0px" }}
                          >
                            <h5>
                              <b>
                                <div>{packageDetails?.data?.property?.name}</div>
                                {/* <Rating
                                  disabled
                                  name="size-small"
                                  defaultValue={
                                    packageDetails?.data?.property?.rating
                                  }
                                  size="small"
                                /> */}
                                <div title={`${packageDetails?.data?.property?.stars} כוכבים`}>
                                {ratingArr?.map((item, index) => {
                                  if (
                                    index <
                                    packageDetails?.data?.property?.stars
                                  ) {
                                    return (
                                    
                                      <StarIcon 
                                        style={{
                                          color: "#ffc772",
                                          height: "31px"
                                        }}
                                      />
                                    );
                                  }
                                })}
                                </div>
                              </b>
                            </h5>

                            <div style={{display:'inline-block', fontWeight:700, marginBottom:'1em'}} className={'pinkText ' + (GetMealPlanName(packageDetails?.data?.property?.meal_code)!=''?'':'hide')}> {GetMealPlanName(packageDetails?.data?.property?.meal_code)} </div>
                          </div>
                        </div>
                        {quoteDetails?.is_flex && (
                          <div className="d-flex mt-3">
                            <VerifiedUserIcon
                              style={{ marginLeft: "2%", fontSize: "33px" }}
                            />
                            <div className="summaryDiv">
                              <h5 className={quoteDetails?.flex.refund_amount>0?'':'hidden'}><b>הזמנה גמישה</b></h5>
                              <h5 className={quoteDetails?.flex.refund_amount>0?'hidden':''}><b>הזמנה עם אפשרות ביטול עד 6 שעות לפני ההמראה</b></h5>
                                <p>הזמנה זו כוללת אפשרות ביטול מכל סיבה, בהודעה מראש עד {quoteDetails?.flex.expiration_hours < 24?quoteDetails?.flex.expiration_hours+' שעות':quoteDetails?.flex.expiration_hours/24+' ימים'} לפני מועד היציאה וקבלת החזר כספי בסך {quoteDetails?.flex.refund_amount>0?quoteDetails?.flex.refund_amount+(quoteDetails?.flex.currency=='USD'?'$':quoteDetails?.flex.currency):'80% מערך ההזמנה כולל הכבודה'}</p>
                            </div>
                          </div>
                        )}
                      </Grid>
                      {/* 
                      <Grid item xs={4}>
                        <div className="finalPriceDiv">
                          <h6>
                            <b>מחיר אחרון</b>
                          </h6>
                          <b>
                            $
                            {parseFloat(
                              reservationState?.package?.price
                            ).toFixed(0)}
                          </b>
                        </div>
                      </Grid>
                      */}
                    </Grid>
                  </div>
                </div>
                <div className="form-bg mb-4" tabIndex={0}>
                  <div style={{ width: "100%" }} className="policy-div">
                    <div className="d-flex" style={{fontSize:'1.2em'}}>
                        <Form.Item
                          name="check1"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('חובה לאשר את תנאי ההזמנה')),
                            },
                          ]}
                        >
                        <label aria-label="title2" tabIndex={0}>
                          <CB
                            className={classes.Checkbox}
                            aria-label="אני מאשר את תנאי ההזמנה ואת תנאי האירוח"
                            color="primary"
                            // required
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          ></CB>
                          &nbsp; אני מאשר את &nbsp;
                          <a href={packageDetails?.data?.policies.booking.link} style={{color: '#555'}} onClick={(e) =>
                            onOpenLink(e, packageDetails?.data?.policies.booking.link)
                          }>תנאי ההזמנה</a>
                          {" "}ואת{" "}
                          <a href={packageDetails?.data?.policies?.property?.link} style={{color: '#555'}} onClick={(e) =>
                            onOpenLink(e, packageDetails?.data?.policies?.property?.link, 
                                       quoteDetails.properties && 
                                       quoteDetails.properties.length>0 && 
                                       quoteDetails.properties[0].inventory && 
                                       quoteDetails?.properties[0].inventory.length>0 && 
                                       quoteDetails?.properties[0].inventory[0]?.remarks &&
                                       quoteDetails?.properties[0].inventory[0].remarks!=''?
                                        ('<p>כמו כן, אני מסכים לתנאי המלון כפי שפורסמו על ידי הספק -</p><p style="direction:ltr;text-align:left">'+quoteDetails?.properties[0]?.inventory[0]?.remarks+'</p>'):
                                        ''
                            )
                          }>תנאי האירוח</a>
                          </label>
                        </Form.Item>
                        
                    </div>            
                    <br />
                    <div className="d-flex" style={{fontSize:'1.2em'}}>
                        <Form.Item
                          name="check2"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('חובה לאשר את מדיניות הביטול')),
                            },
                          ]}
                        >
                        <label aria-label="אני מאשר את מדיניות הביטול" title="אני מאשר את מדיניות הביטול" tabIndex={0}>
                          <CB
                            aria-label="אני מאשר את מדיניות הביטול"
                            title="אני מאשר את מדיניות הביטול"
                            className={classes.Checkbox}
                            color="primary"
                            // required
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                          &nbsp; אני מאשר את  &nbsp;
                          <a href={packageDetails?.data?.policies.cancellation.link} style={{color: '#555'}} onClick={(e) =>
                            onOpenLink(e, packageDetails?.data?.policies.cancellation.link)
                          }>מדיניות הביטול</a>
                        </label>
                        </Form.Item>
                    </div>
                    <br />
                    <div className="d-flex" style={{fontSize:'1.2em'}}>
                        {" "}
                        <Form.Item
                          name="check3"
                          valuePropName="checked"
                        >
                        <label tabIndex={0} aria-label="אני מאשר לשלוח לי הודעות על הטבות ומבצעים">
                          <CB
                            className={classes.Checkbox}
                            color="primary"
                            // required
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            aria-label="אני מאשר לשלוח לי הודעות על הטבות ומבצעים"
                          />
                          {" "}
                          &nbsp; אני מאשר לשלוח לי הודעות על הטבות ומבצעים
                          </label>  
                        </Form.Item>                      
                    </div>
                  </div>
                </div>
                <div className="form-bg mb-4" tabIndex={0}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} md={8} alignContent="center"  >
                          <h4><span className="amount" style={{fontSize:'25px', fontWeight:'bold'}}>${Math.ceil(parseFloat(reservationState?.package?.price+totalBaggagePrice).toFixed(2))} </span>
                          <strong>עלות החופשה</strong></h4>
                    </Grid>
                    <Grid item xs={6} md={4} alignContent="center" style={{textAlign:'center'}}>
                      <button type="submit" id="finishBtn"
                        style={{ margin: "5px 0", height: "55px" }}
                        className={reservationState?.reservation?.loading||finishProgress===true?'hidden':'reserveButton'}>
                          המשך לתשלום
                      </button>
                      <CircularProgress id="finishProgress" 
                        className={finishProgress!=true?'hidden':''}></CircularProgress>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}><span style={{fontSize:'0.92em'}}>
                          יתכנו מיסים מקומיים בהגעה למלון<br />
                          התשלום בשקלים לפי שער אחיד העברות והמחאות מכירה שפורסם בבנק מזרחי</span>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
          </div>
          <div id="ifrm_wrapper" style={{position:'fixed', top:0, zIndex:1, left:0, margin:0, padding:0, backgroundColor:'#fff', border:'none', width:'100%', height:'100%', display:'none'}}>
            <iframe id="ifrm_payment"  key={reservationState?.reservation?.payment_key} 
                    src={reservationState?.reservation?.payment_link} 
                    style={{ border:'none', minWidth: '350px', minHeight: '600px',  justifyContent: 'center', alignItems: 'center', backgroundColor:'#fff'}}
                      >Your browser doesn't support iFrames.</iframe>
          </div>
          <button 
            style={{zIndex:'100',position:'fixed', display:'none', padding: '0 20x', borderRadius: '500px', backgroundColor: '#25d466', bottom:'10px', color:'#fff', fontWeight:'800', border:'none', minWidth:'150px', justifyContent:'center'}}  
            id="btn_whatsapp_help" 
            className="wa-cta-button wa-button-size-with-words wa-button-size-large"
            onClick={(e) =>
              gotoURL('https://wa.me/972507801801')}>
              <div className="wa-cta-button-inner" style={{display:'flex',alignItem:'center',minWidth:'130px', alignItems:'center'}}>
                <svg style={{margin: '10px 5px'}} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22">
                  <path d="m.76 21.24 1.412-5.12A10.324 10.324 0 0 1 .76 10.93C.76 5.35 5.35.76 10.964.76 16.58.76 21.24 5.35 21.24 10.93c0 5.578-4.661 10.31-10.276 10.31-1.765 0-3.46-.565-4.978-1.413L.76 21.24Z" fill="#EDEDED"></path>
                  <path d="m6.268 17.991.318.177c1.307.812 2.825 1.306 4.414 1.306 4.626 0 8.474-3.848 8.474-8.545 0-4.696-3.848-8.404-8.51-8.404-4.66 0-8.439 3.743-8.439 8.404 0 1.624.46 3.213 1.307 4.555l.212.318-.812 2.966 3.036-.777Z" fill="#25D366"></path>
                  <path d="m8.245 6.198-.671-.036a.802.802 0 0 0-.565.212c-.318.283-.848.812-.989 1.519-.247 1.059.141 2.33 1.06 3.601.918 1.271 2.683 3.32 5.79 4.202.989.283 1.766.106 2.402-.282.494-.318.812-.812.918-1.342l.105-.494a.355.355 0 0 0-.176-.389l-2.225-1.024a.337.337 0 0 0-.423.106l-.883 1.13a.275.275 0 0 1-.283.07c-.6-.211-2.613-1.059-3.707-3.177-.036-.106-.036-.212.035-.283l.848-.953c.07-.106.105-.247.07-.353L8.527 6.41a.308.308 0 0 0-.282-.212Z" fill="#FEFEFE"></path>
                </svg>
                <span className="wa-cta-button-label font-header wa-cta-button-label-margin" style={{direction:'rtl', fontWeight:'800', fontSize: '1.3em'}}>צריכים עזרה?</span>
              </div>
            </button>
        </div>
      ) : (
        <div className="text-center mt-5    ">
          <CircularProgress />
        </div>
      )}
    </>
  );
};


export default Reservation;
