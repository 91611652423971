import React from "react";
import configData from '../../config/config.json';

// gui elements
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

// snackbar + alert
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// close button
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from '@material-ui/core/CircularProgress';

// Import calendar Hebrew
import {registerLocale} from "react-datepicker";

// NUMBER OF MONTHS TO SHOW AHEAD
const startMonth = configData.CALENDAR?.start_month;
const endMonth   = configData.CALENDAR?.end_month;
const skipMonthsArray = configData?.CALENDAR?.skip_months; // date in N months from today (last day of that month)      

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// Calculate the start date either by using the provided start date or by subtracting the months count from the endDate
let dt = new Date();
let minDate = new Date(dt.getTime() + 2 * 24 * 60 * 60 * 1000); // date in 2 days time

if (startMonth && dt.getTime() <=new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1).getTime()) {
    minDate = new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1);
}

// Calculate the end date either by using the provided end date or by adding the months count to the startDate
const endDate = new Date(endMonth.split('-')[0],endMonth.split('-')[1],1); // :new Date(dt.setMonth(currentMonth+nMonths));

let maxDate = new Date(endDate.getFullYear(), endDate.getMonth() , 0); // 0=set date to the last day of month in nMonths months (7)

// Calculate the number of months we'll show
const nMonths = Math.round((endDate.getTime() - minDate.getTime())  / (1000 * 60 * 60 * 24 * 30));

class Destinations extends React.Component {
        
    constructor (props) {
        super(props)

        //if (!this.props.location || !this.props.location.search || this.props?.location?.search==="") {
            //console.log('default')
            this.state = {
                range:              this.props?.range,
                open:               this.props?.open?this.props?.open:false,
                window: {
                    dates_not_allowed:      false,
                    dates_max_nights_error: false,
                    dates_min_nights_error: false
                },
                filters: {
                    ...this.props.filters,
                    destinations: []
                }
            }
        //}       

        // binding
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this); 
        this.toggleDestinationSelection = this.toggleDestinationSelection.bind(this); 

        console.log(this.state)
    }
    
    componentDidMount() {
        // Monitor scrolling to show filters after 3rd result
        document.addEventListener('scroll', this.handleScroll);
    }

    confirmSelection = () => {
        this.props.onConfirmDestinationsSelection(this.state?.filters?.destinations)
        this.props.onCloseDestinations()
    }

    closeWindow = (event) => {
        event.stopPropagation();
        this.props.onCloseDestinations()
    }


    

    toggleDestinationSelection = (event, value) => {
        
        console.log('toggleDestinationSelection')
        //console.log(value)
        //console.log(this.state.filters.destinations)
        // Fixes issue that causes the function to be executed twice if the checkbox's label is being clicked
        if (event.target.checked==undefined)
            return;

        event.stopPropagation();
        
        if (value=='all') {
            this.setState((prevState)=>(
                {
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        destinations: []
                    }
                }),function(){
                    //console.log(this.state.filters.destinations)
                })                    
        
            return;
        }

        // create (non referencial) array clone
        let destinationsArray = this.state.filters?.destinations; 

        if (destinationsArray.includes(value)) {

            // if value already exists remove it from array
            destinationsArray = destinationsArray.filter(item => item !== value);

            this.setState((prevState)=>(
            {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    destinations: destinationsArray
                }
            }),function(){
                //console.log(this.state.filters.destinations)
            })                    
        } else {
            // Add new destination
            console.log(`Add ${value}`)
            destinationsArray.push(value)

            // add it to array
            this.setState((prevState)=>(
            {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    destinations: destinationsArray
                }
            }), function(){
                //console.log(this.state.filters.destinations)
            })
        }
    }

   
    handleSnackbarClose = () => {
        this.setState(prevState=>(
            {
                ...prevState,
                window: {
                    dates_not_allowed: false,
                }
            }
        ))
    }

    render(){

        //console.log('render destinations')

        return (
            <Dialog fullScreen
                open={this.props?.open?true:false}  
                fullScreen 
                onClose={e=>{this.closeWindow(e)}} 
                scroll="paper"
                id="search_wizard" > 
              <AppBar style={{ position: 'relative', textAlign:'center'}}>
                  <Toolbar style={{background:'#124b71'}}>
                      <IconButton edge="start" color="inherit" onClick={e=>{this.closeWindow(e)}} aria-label="close" title='סגור חלון בחירת יעדים'>
                          <CloseIcon />
                      </IconButton>
                      <Typography variant="h5" style={{flex: 1}}>
                          בחירת יעדים
                      </Typography>
                      <Box component="span" m={2}></Box>
                  </Toolbar>

              </AppBar>
              <DialogContent >
              <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>איפה תרצו לנפוש?</h1> 
                                <div className='notification' style={{margin:'1em 0 0'}}>
                                <WbIncandescentIcon 
                                    className='icon'
                                />טיפ: סמנו "כל היעדים" לקבלת התוצאות הזולות ביותר
                            </div>
                                
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <List>
                                
                                <FormControlLabel key={'all'} role={undefined} dense button 
                                    className="list_all_items"
                                    value='all'
                                    control={<Checkbox color="primary" />}
                                    //label={`כל היעדים (מומלץ)`}
                                    label={<><Typography className='label_select_all_cities'>כל היעדים </Typography><Typography className='label_select_all_cities_recommended'>(מומלץ!)</Typography></>}
                                    labelPlacement="start"
                                    onClick={e=>this.toggleDestinationSelection(e, 'all')}
                                    checked={this.state.filters?.destinations.length==0}
                                    />
                                
                                <Divider variant="inset" component="li" />
                                {
                                    (this.props?.destinations && this.props?.destinations?.length>0)?
                                        this.props?.destinations?.map((value,index) => {

                                            const labelId = `checkbox-list-label-${value}`;
                                            return (
                                            <div key={value+index} >
                                                
                                                    <FormControlLabel role={undefined} dense button
                                                        value={value.city_id}
                                                        onClick={e=>this.toggleDestinationSelection(e, value.city_id+'')}
                                                        control={<Checkbox color="primary" />}
                                                        label={<><Typography className='label_city_name'>{value.city_name}</Typography>, {value.country_name}</>}
                                                        labelPlacement="start"
                                                        tabIndex={-1}
                                                        //inputProps={{ 'aria-labelledby': labelId }}
                                                        checked={this.state.filters?.destinations.includes(value.city_id+'')}
                                                        >
                                                        </FormControlLabel>
                                                
                                                <Divider variant="inset" component="li" />
                                            </div>
                                            );
                                        }
                                    ):
                                    (<center><CircularProgress /></center>)
                                }
                            </List>
                            </Grid>
                        </Grid>
              </DialogContent>
              <DialogActions>
                    <Button onClick={e=>this.confirmSelection(e)} className='submit-button'>אישור</Button>
                    
                </DialogActions>
            </Dialog>
        )
    }
}

export default Destinations