/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
//import PropTypes from "prop-types";
//import { makeStyles } from "@material-ui/core/styles";

//import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
//import { blue } from "@material-ui/core/colors";
import axios from "axios";

import { Grid, Button } from "@material-ui/core";

/*const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});*/

export default function SimpleDialog(props) {

  const content = useRef(null)
  const { onClose, open, link, text } = props;
  //const classes = useStyles();
  const [message, setMessage] = useState("");
  useEffect(async () => {

    if (link) {
      let res = await axios.get(`${link}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      let msg = res?.data;
      if (text && text!=='') 
        msg = msg+text;

      setMessage(msg);

      content.current.focus()

    }
  }, [link]);


  const handleClose = () => {
    onClose();
  };

  // onClose={handleClose}
  
  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div class="ugly modalBody p-4">
        <Grid container>
          <Grid ref={content} item dangerouslySetInnerHTML={{__html:message}}  className="mb-4" id='data_place' xs={12} style={{textAlign:'right'}} tabIndex={0}></Grid>
         
          <Grid item className="text-center" xs={12}>
            <Button onClick={handleClose} variant="contained" color="primary" tabIndex={0}>
              סגור
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
