import React from "react"
import 'react-responsive-modal/styles.css';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { textAlign } from "@material-ui/system";

moment.locale('he', {
    months: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_'),
    monthsShort: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_')
  }); 

class CardLineFlights extends React.Component {
    constructor (props) {
        super(props);
        /*this.state = {
            'active' : false,
            'open': false,
			'open_covid19': false,
            'data': this.props.data,
            'property_photo': null
        }*/
        //this.handleClick = this.handleClick.bind(this); 
        //this.handleMoreDetailsBtn = this.handleMoreDetailsBtn.bind(this); 
    }

    componentDidMount() {
        // initGA();
     }

    /*
    formatDate(date) {
        const d = new Date(date);
    
        return ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" +
        d.getFullYear();
    }
    */
    
    
    getFlightTime(ftime) {
        return moment.parseZone(ftime).format("HH:mm")
        // {moment.parseZone(detailState?.flight?.outbound?.departure_date).format("HH:mm")}
        const d = new Date(ftime);
        return ("0" + d.getHours()).slice(-2) + ":" +  ("0"+d.getMinutes()).slice(-2)
    }


    getTextualDuration(duration) {
        return (duration/60/60+"" ).split('.')[0]+ 'שעות ו-' + Math.round((duration/60/60)%1*60) + " דקות";
    }

    getDays(day1, day2) {
    
        let days = "";
        switch(day1) {
            case 0: days="ראשון"; break;
            case 1: days="שני"; break;
            case 2: days="שלישי"; break;
            case 3: days="רביעי";break;
            case 4: days="חמישי"; break;
            case 5: days="שישי"; break;
            case 6: days="שבת"; break;
            default: break;
        }
    
        days += " - ";
    
        switch(day2) {
            case 0: days+="ראשון"; break;
            case 1: days+="שני"; break;
            case 2: days+="שלישי"; break;
            case 3: days+="רביעי";break;
            case 4: days+="חמישי"; break;
            case 5: days+="שישי"; break;
            case 6: days+="שבת"; break;
            default: break;
        }
    
        return days;
    }

    getStarsImage(num) {
        switch(num*1) {
            case 1: return '&#xf006;';
            case 2: return '&#xf006;&#xf006;';
            case 3: return '&#xf006;&#xf006;&#xf006;';
            case 4: return '&#xf006;&#xf006;&#xf006;&#xf006;';
            case 5: return '&#xf006;&#xf006;&#xf006;&#xf006;&#xf006;';
            default: return '';
        }
    }

    getCurrencySymbol(cur) {
        let currency_symbol;

        switch( cur ) {
            case 'USD': currency_symbol = '$';break;
            case 'EUR': currency_symbol = 'EURO';break;
            case 'ILS': currency_symbol = 'NIS';break;
            default: currency_symbol = '$';
        }

        return currency_symbol;
    }

    render() {
        
        const deal_uid = this.props.data.uid; // 

        let outbound_departure_airport_name = this.props.data.flight.outbound.departure_iata_name //'בן גוריון (תא)'
        if (outbound_departure_airport_name == null)
            outbound_departure_airport_name = this.props.data.flight.outbound.departure_iata // moment.parseZone(this.props.data.flight.outbound.departure_date).format("DD/MM/YYYY")

        const outbound_departure_time = this.getFlightTime(this.props.data.flight.outbound.departure_date) // '21:00'
        const outbound_departure_date = moment.parseZone(this.props.data.flight.outbound.departure_date).format("DD/MM")

        let outbound_arrival_airport_name = this.props.data.flight.outbound.arrival_iata_name //'בן גוריון (תא)' // this.state.data.flight.outbound.arrival_iata_name
        if (outbound_arrival_airport_name == null)
            outbound_arrival_airport_name = this.props.data.flight.outbound.arrival_iata // moment.parseZone(this.props.data.flight.outbound.arrival_iata_name).format("DD/MM/YYYY")

        const outbound_arrival_time = this.getFlightTime(this.props.data.flight.outbound.arrival_date)
        const outbound_arrival_date = moment.parseZone(this.props.data.flight.outbound.arrival_date).format("DD/MM")
//        const outbound_arrival_date = this.formatDate(this.props.data.flight.outbound.arrival_date)

        const outbound_duration = this.getTextualDuration(this.props.data.flight.outbound.duration) //'1 שעות ו-41 דקות'
//        const outbound_departure_airport_iata = this.props.data.flight.outbound.departure_iata; //'TLV' // this.state.data.flight.outbound.departure_iata_name
//        const to_airline = '' //this.props.data.flight.airline.code;

        let inbound_departure_airport_name = this.props.data.flight.inbound.departure_iata_name
        if (inbound_departure_airport_name == null)
            inbound_departure_airport_name = this.props.data.flight.inbound.departure_iata // moment.parseZone(this.props.data.flight.outbound.arrival_iata_name).format("DD/MM/YYYY")

        const inbound_departure_time = this.getFlightTime(this.props.data.flight.inbound.departure_date) // '21:00'
        const inbound_departure_date = moment.parseZone(this.props.data.flight.inbound.departure_date).format("DD/MM")
//        const inbound_departure_date = this.formatDate(this.props.data.flight.inbound.departure_date)

        let inbound_arrival_airport_name = this.props.data.flight.inbound.arrival_iata_name //'בן גוריון (תא)' // this.state.data.flight.outbound.arrival_iata_name
        if (inbound_arrival_airport_name == null)
        inbound_arrival_airport_name = this.props.data.flight.inbound.arrival_iata // moment.parseZone(this.props.data.flight.outbound.arrival_iata_name).format("DD/MM/YYYY")


        const inbound_arrival_time = this.getFlightTime(this.props.data.flight.inbound.arrival_date)
        const inbound_arrival_date = moment.parseZone(this.props.data.flight.inbound.arrival_date).format("DD/MM")
//        const inbound_arrival_date = this.formatDate(this.props.data.flight.inbound.arrival_date)
//        const return_airline = 'LY';

        const inbound_duration = this.getTextualDuration(this.props.data.flight.inbound.duration) //'1 שעות ו-41 דקות'
//        const inbound_departure_airport_iata = 'PFO'

        return ( 
            <div style={{textAlign:'right', color:'#333', direction:'rtl', padding: '0.8em', width:'100%', backgroundColor:'#eee'}} title="טיסות ישירות">
                {/*<h3 style={{fontSize:'1.4em', fontWeight:800, margin:'0 0 0.3em'}}>טיסות  
                    <span style={{margin:'0 0.25em',fontSize:'0.6em', color:'#777'}}>(ישירות)</span>
                </h3>*/}

                <div className="route"  style={{fontSize:'0.9em', alignItems:'center',textAlign:'center',padding:'0 0 1.2em'}}  title={`טיסה ישירה בהלוך מ-${outbound_departure_airport_name} ${outbound_departure_date} ${outbound_departure_time} (${outbound_duration}) אל ${outbound_arrival_airport_name} ${outbound_arrival_date} ${outbound_arrival_time}`}> 
                    {/*<div>
                        <div className="airline"><img src={to_airline!=null?'/images/airlines/32/'+to_airline+'.png':''} alt={to_airline} className={"airline "+(to_airline==null?'hide':'')} /></div>
                        <p style={{fontWeight:'800'}}>הלוך</p>
                    </div>*/}
                    <div className="from" style={{width:'25%'}}>
                        <div>
                            <p style={{fontSize:'0.8em', color:'#666'}}>{outbound_departure_airport_name} </p>
                            <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{outbound_departure_time}</div>
                            <p style={{fontSize:'0.8em', color:'#999'}}>{outbound_departure_date}</p>
                        </div>
                        {/*<p>יום {outbound_departure_dow}` {outbound_departure_time}</p>*/}
                    </div>

                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', minWidth: '40%'}}>
                        <div style={{width:'100%'}}>
                            <div className="duration" style={{width: 'inherit'}}><p className="duration_caption">{outbound_duration}</p>
                            <p className="duration_comment" style={{fontSize:'0.9em'}}>טיסה ישירה</p></div>
                        </div>
                        <div style={{fontSize: '0.75em', marginRight: '2px', color: '#666'}}><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(180deg)'}} /></div>
                    </div>
                    
                    
                    <div className="to" style={{ minWidth: '25%'}}>
                        <p style={{fontSize:'0.8em', color:'#666'}}>{outbound_arrival_airport_name} </p>
                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{outbound_arrival_time}</div>
                        <p style={{fontSize:'0.8em', color:'#999'}}>{outbound_arrival_date}</p>
                    </div>
                </div>

                <div className="route"  style={{padding:0,fontSize:'0.9em', alignItems:'center',textAlign:'center'}}  title={`טיסה ישירה בחזור מ-${inbound_departure_airport_name} ${inbound_departure_date} ${inbound_departure_time} (${inbound_duration}) אל ${inbound_arrival_airport_name} ${inbound_arrival_date} ${inbound_arrival_time}`}> 
                    {/*<div>
                        <div className="airline"><img src={to_airline!=null?'/images/airlines/32/'+to_airline+'.png':''} alt={to_airline} className={"airline "+(to_airline==null?'hide':'')} /></div>
                        <p style={{fontWeight:'800'}}>הלוך</p>
                    </div>*/}
                    <div className="from" style={{width:'25%'}}>
                        <p style={{fontSize:'0.8em', color:'#666'}}>{inbound_departure_airport_name} </p>
                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{inbound_departure_time}</div>
                        <p style={{fontSize:'0.8em', color:'#999'}}>{inbound_departure_date}</p>
                        {/*<p>יום {inbound_departure_dow}` {inbound_departure_time}</p>*/}
                    </div>
                    
                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', minWidth: '40%'}}>
                        <div style={{width:'100%'}}>
                            <div className="duration" style={{width: 'inherit'}}><p className="duration_caption">{inbound_duration}</p>
                            <p className="duration_comment" style={{fontSize:'0.9em'}}>טיסה ישירה</p></div>
                        </div>
                        <div style={{fontSize: '0.75em', marginRight: '2px', color: '#666'}}><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(180deg)'}} /></div>
                    </div>

                    <div className="to" style={{ minWidth: '25%'}}>
                        <p style={{fontSize:'0.8em', color:'#666'}}>{inbound_arrival_airport_name} </p>
                        <div className="time" style={{fontSize:'1.2em',fontWeight:'700',lineHeight:1}}>{inbound_arrival_time}</div>
                        <p style={{fontSize:'0.8em', color:'#999'}}>{inbound_arrival_date}</p>
                    </div>
                </div>

            </div> 
        )
     }

}

export default CardLineFlights