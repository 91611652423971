import React from "react";
import configData from '../../config/config.json';
import './index.css';
import Modal from 'react-responsive-modal';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const cookies = new Cookies();
class Promotion extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            open_promotion: this.props?.show?true:false
        }
    }

    showPromotionModal = (event, open) => {
        console.log(event)
        console.log(open)
        if (event)
            event.stopPropagation()

        //console.log(open)
        this.setState({ 
            open_promotion: open,
            open: false
         });

         if  (open==false) {
            cookies.set('hide_hp_coupon_popup',true,{ 
                path: '/' ,
                maxAge: 60*60*4 // 4 hour
              })
         }
    };

    render() {
        let content = (<></>);

        if (this.props?.campaign==='coupon-50') {
            content = (
                
                <Modal 
                    open={this.state.open_promotion && !cookies.get('hide_hp_coupon_popup')} onClose={(e) => this.showPromotionModal(e,false)}
                    center 
                    blockScroll={true}
                    closeIcon={<><FontAwesomeIcon icon={faTimes} size="1x" style={{color:'#000', cursor:'pointer'}}/></>}
                    animationDuration={1000}
                    style={{maxWidth:'300px', padding:0, flexDirection:'reverse'}} 
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    >
                    <a href="https://wa.link/vzatw1">
                        <img src="/images/promotions/coupon-50/image.jpg" alt='מבצע סופש מטורף' style={{width:'100%',border:'none', marginTop:'1.5em'}} border="0"></img>
                    </a>
                </Modal>
                            
            )
        }

        if (this.props?.campaign==='crazyweekend') {
            content = (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/crazyweekend/card.png" style={{width:'85px'}} alt='מבצע סופש מטורף'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                                    חם חם חם לנו! אז זה בדיוק הרגע לעוף לנופש בחו"ל ולקפוץ הישר לבריכה!
                                    </p>

                                    <p>מזמינים חופשה לקיץ במהלך סוף השבוע, מזינים את הקופון CRAZY40 ונהנים ממתנה מיידית של $40 הנחה בחיוב.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $350 לנוסע, שיבוצעו במהלך סוף השבוע בלבד</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        
        if (this.props?.campaign==='weekend30') {
            content= (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/weekend30/card.png" style={{width:'85px'}} alt='מבצע סופש מטורף'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                                    חם חם חם לנו! אז זה בדיוק הרגע לעוף ולהזמין חופשה לחול במחיר זול עוד יותר !
                                    </p>

                                    <p>מזמינים חופשה במלונות הכל-כלול במהלך סוף השבוע, מזינים את הקופון CRAZY30 ונהנים ממתנה מיידית של $30 הנחה בחיוב.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $250 לנוסע, שיבוצעו במהלך סוף השבוע בלבד. ללא כפל מבצעים</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        } 
        
        if (this.props?.campaign==='rosh25') {
            content= (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/rosh25/card.png" style={{width:'85px'}} alt='מבצע ראש השנה'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>ראש השנה מטורף בעפנו!</strong><br />
                                    </p>

                                    <p>מזמינים חופשה במהלך החג, מזינים את הקופון ROSH25 ונהנים ממתנה מיידית של $25.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות בערך של לפחות $200 לנוסע, שיבוצעו במהלך החג בלבד. ללא כפל מבצעים</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        return content;
        
        
    }

}
export default Promotion