import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";


class Terms extends React.Component {
    componentDidMount() {
        if (window.location.pathname.indexOf('romotion/2929')>0) {
            setTimeout(function(){window.location.href="/deal/6735d5e391444a1ea980e89781392d97"} , 100);
            document.getElementById("h3").innerHTML = ("טיסה + מלון לבוקרשט ב388 ש\"ח לאדם");
        }

    }

    render() {

        return(
            
            <div className="App Contact">
                <Header hide="true" hideSearch={true} />
                <main>
                   <h1>דיל AFNU{this.props.match.params.id}</h1>
                   <p>מדי שבוע יפורסמו דילים יחודיים של טיסה+מלון, עיקבו אחרינו</p>
                   <h3 id="h3">הדיל יפורסם בקרוב</h3>
                   <p>*בשל אופי שוק התיירות, הזמינות והמחירים עשויים להשתנות בכל עת. כל הקודם זוכה. עד גמר המלאי.</p>

                   <img src="/images/secret-2681508_640.jpg" alt="דיל סודי"></img>
                   
                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;