import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faBed, faPlane  } from "@fortawesome/free-solid-svg-icons"; 


class CardHeader extends React.Component {
    constructor (props) {
        super(props)    

        // Binding
        this.handleClick = this.handleClick.bind(this); 
        this.handleClickGoToDestination = this.handleClickGoToDestination.bind(this);
        this.ToggleDealDetails = this.ToggleDealDetails.bind(this);
    }

    handleClick = (e) => {
        e.preventDefault();
        
        this.props.ShowCardLine(true)
    }


    handleClickGoToDestination = (event,click_location) => {
        this.props.GoToDestination(event, click_location);
    }

    ToggleDealDetails = (event) => {
        this.props.ToggleDealDetails(event);
    }

    render() {
        const item = this.props?.data;
        
        if (this.props?.type=='days')
            return (    
                <div className={"photo hotel"} style={{backgroundImage:`url(https://cdn.cookiecrumbz.com/images/properties/400/${item?.property?.photos?.slice(0,1)})`}} onClick={e=>this.ToggleDealDetails(e)}>    
                    
                    <div className="topbar">
                        <div className="title">
                                <div className='country'>{this.props?.country}</div>
                                <div className='city'>{this.props?.city}</div>
                        </div>
                        <div className="features">
                            <span className={"weekend " + ((item?.weekend_il)?'show':'hide')}>סופ"ש</span>
                            {
                                // Show holidays
                                item?.holidays?.map((item, index) => {
                                        return (<span className="holiday" key={item+index}>{item}</span>)
                                })
                            }
                            <span className="special-icon black-friday"><img src="/images/icons/black-friday.png" alt='בלאק פריידי'></img></span>
                        </div>

                    </div>

                    
                    <div className="titleBottom">
                        <h3 title={`מלון ${item?.property?.name} - ${item?.property?.stars} כוכבים`}>
                            <span className="stars">{this.getStarsImage(item?.property?.stars)} </span> {item?.property?.name}</h3>
                    </div>
                </div> 
            )
        else 
            return (
                <div className={"photo destination"} style={{backgroundImage:`url(https://cdn.cookiecrumbz.com/images/destinations/${this.props.city_uid}.jpg)`}} alt={`תמונות אווירה של ${this.props?.city} (${this.props?.country})`}>    
                    <div className="topbar">
                        <div className="title">
                                <div className="country">{this.props?.country}</div>
                                <div className="city">{this.props?.city}</div>
                        </div>
                        <div className="features">
                            <span className={"weekend " + ((item?.weekend_il)?'show':'hide')}>סופ"ש</span>
                            {
                                // Show holidays
                                item?.holidays?.map((item, index) => {
                                        return (<span className="holiday" key={item+index}>{item}</span>)
                                })
                            }
                            <span className="special-icon black-friday"><img src="/images/icons/black-friday.png" alt='בלאק פריידי'></img></span>
                        </div>
                    </div>
                    
                    <div className="titleBottom">
                        <div title="טיסה+מלון">
                            <div className="flightIcon">
                                <div style={{inline:`block`}} className="icon"><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(-45deg)'}} /></div>
                                <p style={{fontSize:'.9em'}}>טיסה</p>
                            </div>

                            <div className="plusIcon" style={{fontSize:'1.5em', margin:'0 .1em 0 .3em'}}>+</div>

                            <div className="hotelIcon">
                                <div style={{inline:`block`}} className="icon"><FontAwesomeIcon icon={faBed} /></div>
                                <p style={{fontSize:'.9em'}}>מלון</p>
                            </div>
                        </div>
                    </div>

                </div>
        )
    }

    getStarsImage(num) {
        let output = "";

        for (let i=0;i<num;i++) {
            output+="★";
        }

        return output;
    }
} export default CardHeader