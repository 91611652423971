import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
//import { makeStyles } from "@material-ui/core/styles";

//import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
//import { blue } from "@material-ui/core/colors";

import { Grid, Button} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Room from './QuantityModalRooms';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';


//const emails = ["username@gmail.com", "user02@gmail.com"];
/*const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});
*/


export default function SimpleDialog(props) {
  const { onClose, open, handleQuantityChange, pax, rooms } = props;

  console.log(rooms)
  console.log(pax)
  console.log(props)
  const max_guests_per_room = 5;
  const max_adults_per_room = 3;
  const max_children_per_room = 4;
  const max_guests_per_booking = 9;

  // Modify rooms structure
  const formattedRoomsObject = [];
  if (rooms?.room1_adults_count > 0) {
    formattedRoomsObject.push({
        adults: Array.from({length: rooms.room1_adults_count}, ()=>{return 30}), // {adults:[30,30],children:[]}
        children: rooms.room1_children_ages
    });
  }
  
  if (rooms?.room2_adults_count > 0) {
    formattedRoomsObject.push({
        adults: Array.from({length: rooms.room2_adults_count}, ()=>{return 30}), // {adults:[30,30],children:[]}
        children: rooms.room2_children_ages
    });
  }
  
  if (rooms?.room3_adults_count > 0) {
    formattedRoomsObject.push({
        adults: Array.from({length: rooms.room3_adults_count}, ()=>{return 30}), // {adults:[30,30],children:[]}
        children: rooms.room3_children_ages
    });
  }
  
  if (rooms?.room4_adults_count > 0) {
    formattedRoomsObject.push({
        adults: Array.from({length: rooms.room4_adults_count}, ()=>{return 30}), // {adults:[30,30],children:[]}
        children: rooms.room4_children_ages
    });
  }
  
  console.log(formattedRoomsObject)
  //const classes = useStyles();
  const [state, setState] = useState({
    /*adults: pax?.adults,
    children: pax?.children*1>0?pax?.children:0,
    infants: pax?.infants*1>0?pax?.infants:0,
    seniors: pax?.seniors*1>0?pax?.seniors:0,*/
    rooms: formattedRoomsObject,/*[
      {adults:[30,30],children:[]}
    ],*/
    err: '',
    warn: ''
  });
  /*const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };*/

  useEffect(async () => {
    console.log(state)
  });

  const handleClose = () => {
    onClose();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleQuantityChange(state);
    handleClose();
    // console.log(state)
  };

  
  const AddRoom = (e) => {
      //console.log('Adding room')
      const rooms = [...state.rooms]; // clone array (lose the rerence to the original state array)
      //const rooms = state.rooms;

      if (CountGuests(rooms) >= max_guests_per_booking ) {
        setState({ 
          ...state, 
          err: 'לא ניתן להוסיף יותר מ-9 נוסעים בהזמנה'
        })

        return;
      }

      if (rooms.length < 4) {
        if (CountGuests(rooms) >= max_guests_per_booking-1)
          rooms.push({adults:[30],children:[]});
        else 
          rooms.push({adults:[30,30],children:[]});
      }

      setState({
        ...state,
        rooms: rooms,
        err: ""
      }, (res)=>{console.log(res)});
  }

  const RemoveRoom = (index) => {
    const roomsCopy = [...state.rooms] // Create a shadow copy of the array
    //const roomsCopy = state.rooms.slice(); // Create a shadow copy of the array
    roomsCopy.splice(index, 1); // remove 1 element starting from the index position
    
    setState({ 
      //...state, 
      rooms: roomsCopy,
      err: ""
    })
    
  }

  const ModifyAdults = (index, change) => {
    //console.log('Adults: ' + change + ' (' + index + ')')
    const roomsCopy = [...state.rooms] // Create a shadow copy of the array

    // Addition
    if (change>0) {
      
      if (roomsCopy[index].adults.length>=max_adults_per_room) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_adults_per_room} מבוגרים בחדר`
        })
        return;
      }

      if (roomsCopy[index].adults.length+roomsCopy[index].children.length>=max_guests_per_room) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_guests_per_room} אורחים בחדר`
        })
        return;
      }

      if (CountGuests(roomsCopy) >= max_guests_per_booking ) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_guests_per_booking} נוסעים בהזמנה`
        })
        return;
      }

      // add guests
      roomsCopy[index].adults.push(30); //+=change; // remove 1 element starting from the index position
    }
    
    // Substraction
    if (change<0) {
      if(roomsCopy[index].adults.length>1) {
        roomsCopy[index].adults.splice(0,1) // +=change; // remove 1 element starting from the index position
      }
    }  

    // Update state with new pax
    setState({ 
      rooms: roomsCopy,
      err: ""
    })
    
  }


  const UpdateChildAge = (room_index, child_index, age) => {
    //console.log('Child idx '+ child_index + ' age: ' + age)

    const roomsCopy = [...state.rooms] // Create a shadow copy of the array
    roomsCopy[room_index].children[child_index]=age*1 // +=change; // remove 1 element starting from the index position
   
    setState({ 
      //...state, 
      rooms: roomsCopy,
      err: ""
    })
  }

  const ModifyChildren = (index, change) => {
    //console.log('Children: ' + change + ' (' + index + ')')
    const roomsCopy = [...state.rooms] // Create a shadow copy of the array
    let warning = '';
    // Addition
    if (change > 0) {

      if (CountGuests(roomsCopy) >= max_guests_per_booking ) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_guests_per_booking} נוסעים בהזמנה`
        })

        return;
      }
      
      if (roomsCopy[index].children.length>=max_children_per_room) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_children_per_room} ילדים בחדר`
        })
        return;
      }

      if (roomsCopy[index].adults.length+roomsCopy[index].children.length>=max_guests_per_room) {
        setState({ 
          ...state, 
          err: `לא ניתן להוסיף יותר מ-${max_guests_per_room} אורחים בחדר`
        })
        return;
      }

      if (roomsCopy[index].children.length>=2) {
        warning = `מלונות רבים מגבילים את מספר הילדים בחדר ל-2 בלבד. על כן, במידה ולא תתקבל תוצאה, מומלץ לפצל את האורחים בין מספר חדרים`;
      }

      // Add child
      roomsCopy[index].children.push(5) // +=change; // remove 1 element starting from the index position
    }

    // Substraction
    if (change<0 && roomsCopy[index].children.length>0) {
      //console.log('substraction')
      roomsCopy[index].children.splice((roomsCopy[index].children.length-1),1); //+=change; // remove 1 element starting from the index position
    }
    
    setState({ 
      //...state, 
      rooms: roomsCopy, 
      err: "",
      warn: warning
    })

  }

  const CountGuests = (rooms) => {
    return rooms.reduce((partialSum, room) => partialSum + room.adults.length + room.children.length, 0);
  }

  let Rooms = state.rooms.map((room, idx) => {
    if (room.adults.length + room.children.length > 0)
        return <Room data={room} room_index={idx} key={idx} max_guests_per_room={max_guests_per_room} removeRoom={RemoveRoom} modifyAdults={ModifyAdults} modifyChildren={ModifyChildren} updateChildAge={UpdateChildAge} />
    }
  );


  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="ugly modalBody p-4 modalPassengersUpdate">
        <Snackbar
            tabIndex={0}
            open={state?.err!=''}
            style={{textAlign:'right', height:'40%'}}
            autoHideDuration={4000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            key={'middlecenter'}
            onClose={()=>{
              setState({ 
                  ...state, 
                  err: ''
                }
              )}}>
            <Alert 
              tabIndex={0}
              elevation={6} 
              variant="filled" 
              severity="error"
              /*action={
                <Button color="inherit" size="small" onClick={()=>{
                  setState(
                    { 
                      ...state, 
                      err: ''
                    }
                  )}}>
                  x
                </Button>
              }*/
              onClose={()=>{
                setState({ 
                    ...state, 
                    err: ''
                  }
                )}}>
                 <AlertTitle style={{fontWeight:600}}>שגיאה</AlertTitle>
                {state.err}</Alert>
        </Snackbar>

        <Snackbar
            tabIndex={0}
            open={state?.warn  && state?.warn!=''}
            autoHideDuration={10000}
            style={{ textAlign:'right', height:'40%' }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            key={'topcenter'}
            onClose={()=>{
              setState({ 
                  ...state, 
                  warn: ''
                }
              )}}>
            <Alert 
              tabIndex={0}
              elevation={6} 
              variant="filled" 
              severity="warning"
              onClose={()=>{
                setState({ 
                    ...state, 
                    warn: ''
                  }
                )}}>
                 <AlertTitle style={{fontWeight:600}}>שימו לב</AlertTitle>
                {state.warn}</Alert>
        </Snackbar>
        {/*<div id="err" style={state.err!=''?{color:'#D8000C', backgroundColor:'#FFBABA', border: '1px solid', position:'fixed', fontWeight:600, padding:'1em', direction:'ltr', zIndex:10000}:{}}>{state.err}</div>*/}
        <Grid container>
          <Grid item className="mb-2" xs={12}>
            <h1>
              <strong>הרכב הנוסעים</strong>
            </h1>
            <h5>
              ניתן להוסיף עד {max_guests_per_room} אורחים בכל חדר<br />
              תוכלו להוסיף חדרים נוספים לפי הצורך
            </h5>
          </Grid>
          <form onSubmit={(e) => handleSubmit(e)} style={{textAlign:'right', margin:'0 auto', width:'90%'}}>

            <Grid container>

              {Rooms}
              
              <Grid item className="mt-3">
                <span style={{color:'#cf5770', display:'flex', alignItems:'center', fontWeight:700, cursor:'pointer'}} className={state.rooms.length >= 4?' hidden ':''} onClick={(e) => AddRoom(e)}>
                <FontAwesomeIcon icon={faPlusCircle} style={{marginLeft:'.5em'}} /> הוספת חדר
                </span>
              </Grid>

              <Grid item className="text-center mt-4 mb-3" xs={12}>
                <Button
                  style={{ backgroundColor: "#cf5770", color: "white", padding:".5em 3em" }}
                  type="submit"
                >
                  אישור
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </div>
    </Dialog>
  );
}