import React from "react";
import configData from '../../config/config.json';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import queryString from 'query-string';
import './index.css';

const API_ENDPOINT = configData.API_ENDPOINT;

class Heading extends React.Component {

    constructor (props) {
        super(props)
        //console.log(props)

        this.state = {
            'popup' : this.props?.show_popup,
        }
       
        const QS = queryString.parse(window.location.search);
        //console.log(QS)

        this.city_id = QS?.destination;
        if (this.city_id>0) {
            try {
                const base = window.location.pathname.split('/');
                this.city_name = decodeURIComponent(base[1]);
            } catch (e) {
                this.city_name = '';
            }
            
        } else
            this.city_id = null;

        /*this.headingText = 'הדילים הכי זולים';
        this.headingSuffix = '';

        if (this.props?.active==='5stars' || (QS?.min_stars==='5' && !QS?.max_stars) || window.location.pathname==='/5stars/')
                this.headingText = 'דילים במלונות יוקרה'

        if (this.props?.active==='last-minute' || this.props?.filters?.when==='last-minute' || window.location.pathname==='/last-minute/')
                this.headingText = 'דילים ברגע האחרון'

        if (this.props?.active==='one-week' || this.props?.filters?.when==='one-week' || window.location.pathname==='/one-week/')
            this.headingText = 'דילים עד 7 לילות'
                
        if (this.props?.active==='weekends' || this.props?.filters?.when==='weekends' || window.location.pathname==='/weekends/')
                this.headingText = 'דילים בסופי שבוע'

        if (this.props?.active==='waterpark' || this.props?.filters?.when==='waterpark' || window.location.pathname==='/waterparks/')
            this.headingText = 'דילים במלונות עם פארק מים'

        if (this.props?.active==='luxury' || this.props?.filters?.when==='luxury' || window.location.pathname==='/luxury/')
            this.headingText = 'דילים במלונות יוקרה'

        if (this.props?.active==='casino' || this.props?.filters?.when==='casino' || window.location.pathname==='/casino/')
            this.headingText = 'דילים במלונות עם קזינו'

        if (this.props?.active==='kosher' || this.props?.filters?.when==='kosher' || window.location.pathname==='/kosher/')
            this.headingText = 'דילים במלונות כשרים'

        if (this.props?.active==='summer' || this.props?.filters?.when==='summer' || window.location.pathname==='/summer/')
                this.headingText = 'דילים בקיץ'
        
        if (this.props?.active==='summer-vacation' || window.location.pathname==='/summer-vacation/')
                this.headingText =  'דילים בחופש הגדול'                                
                
        if (this.props?.active==='holidays'  || this.props?.filters?.when==='holidays' || window.location.pathname==='/holidays/')
            this.headingText = 'דילים בחגים'

        if (this.props?.active==='passover-vacation' || this.props?.filters?.when==='passover-vacation' || window.location.pathname==='/passover-vacation-deals/')
                this.headingText =  'דילים בחופש פסח'

        if (this.props?.active==='sukkot-vacation' || window.location.pathname==='/sukkot-vacation-deals/')
                this.headingText =  'דילים בחופש סוכות'        

        if (this.props?.active==='families' || window.location.pathname==='families/')
                this.headingText = 'דילים למשפחות'

        if (this.props?.active==='summer-family' || window.location.pathname==='summer-family/')
            this.headingText = 'דילים למשפחות'


        if (this.props?.destination_id > 0) {
            this.headingText = 'דילים'
        }
 
        if (this.props?.filters?.month) {
            const base = this.props?.filters?.month.split('-');
            const month_num = base[1];

            const month_names_he = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
            'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

            this.headingSuffix = month_names_he[month_num-1]
        }

        this.headingText = this.props.text;
        */
       

        // binding
        this.handleToggleDestiantionTab = this.handleToggleDestiantionTab.bind(this)
        this.handleSelectDestination = this.handleSelectDestination.bind(this); 

    }

    componentDidMount() {
        if (this.props.destination_id)
            this.fetchDestination(this.props.destination_id)
    }
    

    

    async fetchDestination(city_id) {

        const url =  API_ENDPOINT + '/search/destinations/'+city_id+'/?lang=he';
        

        return (
          await fetch(url)
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
            }
          })
          .then(response => {
            // Find city in cities array
            const currentCity = response.find(item=>item.city_id==city_id)
            

            // send destination details to caller
            if (this.props?.sendDataToCaller) {
                // if we're in a page of specific city, send only city details
                // otherwise, send all cities
                if  (city_id) {
                    this.props.sendDataToCaller(currentCity);
                } else {
                    this.props.sendDataToCaller(response);
                }
            }
                
        
            this.setState((prevState)=>({
                ...prevState,
                'destination': currentCity,
                'destinations': response
            }))
          })
          .catch(error=>this.setState(
              {
                error, 
                  'isLoading': false,
                  filters: {...this.state.filters }
              }
          ))
        )
    }

    async fetchDestinations(city_id) {

        const url =  API_ENDPOINT + '/search/destinations/?lang=he';
        

        return (
          await fetch(url)
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
            }
          })
          .then(response => {
            // Find city in cities array
            const currentCity = response.find(item=>item.city_id==city_id)
            

            // send destination details to caller
            if (this.props?.sendDataToCaller) {
                // if we're in a page of specific city, send only city details
                // otherwise, send all cities
                if  (city_id) {
                    this.props.sendDataToCaller(currentCity);
                } else {
                    this.props.sendDataToCaller(response);
                }
            }
                
        
            this.setState((prevState)=>({
                ...prevState,
                'destination': currentCity,
                'destinations': response
            }))
          })
          .catch(error=>this.setState(
              {
                error, 
                  'isLoading': false,
                  filters: {...this.state.filters }
              }
          ))
        )
    }

  
      
    handleToggleDestiantionTab = (event) => {
        if (!this.state?.destinations || this.state?.destinations?.length<=1) 
            this.fetchDestinations(this.props.destination_id);

        const currentState = this.state;
        currentState.popup = true;
        this.setState(currentState);

        window.dataLayer = window.dataLayer || [];
        const event_name = 'DestinationChangeClicked';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            event: event_name,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });
        // refresh data
        //this.getFiltersUpdate(this.state.filters)
    }

    
    /** 
     * Update destination state based on selection
     */
    handleSelectDestination = (event, cityId, cityName) => {

        event.stopPropagation()
        
        /*if (cityId!='all')
            window.location = `/${cityName}/?destination=${cityId}`
        else {
            this.handleClose(event)
            window.location = `#deals`
        }*/

        //remove QS property from parent filters
        this.props.filters.QS = undefined;

        // convert filters of parent page to querystring
        const filtersQuerystring = this.objectToQueryString(this.props.filters)

        // if user selected a specific city
        if (cityId!=='all') {
            // This will redirect user to destination page and will keep all existing filters => we removed this because if we were is last minute filter it would user to the destination and show only last minute deals! we prefer to show all months in the destination instead
            //window.location = `/${cityName}/?destination=${cityId}&`  + filtersQuerystring
            window.location = `/${cityName}/?destinations=${cityId}`
        }
        else {
            // if user wants to see results for all cities
            window.location = '/best-deals/?' + filtersQuerystring
        }
        
    }

    /**
     * Convert filters object to querysting
     * 
     * @param {} obj 
     * @returns 
     */
    objectToQueryString(obj) {
        console.log(obj)
        if (!obj)   
            return 
        var str = [];
        for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            if (obj[p])
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }

    /**
     * Close window
     * 
     * @param {*} event 
     */
    handleClose = (event) => {
        this.setState({
            popup: false
        });
//        this.state.popup = false;
    }


    render() {
        return (
            <>
                <Dialog open={this.state?.popup===true} onClose={e=>{this.handleClose(e)}}>
                    <DialogTitle id="alert-dialog-title">איפה תרצו לנפוש?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div className="destinations_popup noselect">
                            
                            <div className='window'>
                                <div className='content'>
                                    <div className='list'>
                                        <div onClick={e=>this.handleSelectDestination(e, 'all', 'כל היעדים')} className={!this.state?.destination || this.state?.destination===''|| this.state?.destination==='all'?' item selected':'item'}>
                                            <div className="icon" style={{marginLeft:'1em'}}><FontAwesomeIcon icon={faPlaneDeparture}   style={{transform:'scaleX(-1)'}} /></div>
                                            <div className="text">אין לי העדפה</div>
                                        </div>
                                        {
                                            
                                            this.state?.destinations?.sort().map(dest=> 
                                                // Here goes your models option
                                                (<div key={dest?.city_id} onClick={e=>this.handleSelectDestination(e, dest.city_id, dest.city_name)} className={this.props.destination*1===dest?.city_id*1?'item selected':'item'}>
                                                    <div className="icon" style={{marginLeft:'1em'}}><FontAwesomeIcon icon={faPlaneDeparture}   style={{transform:'scaleX(-1)'}} /></div>
                                                    <div className="text">{dest.city_name}</div>
                                                </div>)
                                            )
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        </DialogContentText>
                    </DialogContent>
                    
                </Dialog>


                <div className="change_destination_heading_wrapper">
                    <div className="change_destination_heading">
                        {/*
                        
                        <h2>{this.headingText} <span onClick={e=>{this.handleToggleDestiantionTab(e)}} className='city_name_wrapper'><span className='city_name'>{this.city_id&&!this.state.destination?this.city_name:(this.state?.destination?('ל'+this.state?.destination?.city_name):'בכל היעדים')}</span><FontAwesomeIcon className='icon' icon={faAngleDown} style={{fontSize:'0.8em',margin:'0 .2em 0 0', display:'inline-block'}} onClick={e=>{this.handleToggleDestiantionTab(e)}} /></span>{this.headingSuffix?' ב'+this.headingSuffix:''}</h2>                        
                        */}
                        <h2>{this.props?.text} {this.city_id&&!this.state.destination?this.city_name:(this.state?.destination?('ל'+this.state?.destination?.city_name):'')}</h2>
                    </div>
                </div>
            </>
        )    
    }

}
export default Heading