import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
//import { makeStyles } from "@material-ui/core/styles";

//import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
//import { blue } from "@material-ui/core/colors";
import axios from "axios";

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});
*/

export default function SimpleDialog(props) {
  const { onClose, open, data } = props;
  //const classes = useStyles();
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    async function go() {
      if (data) {
        let res = await axios.get(`${data?.link}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        setMessage(res?.data);
      }
    }
    go()
  },[data]);
  
  console.log(data);

  const handleClose = () => {
    onClose();
  };
 

  // onClose={handleClose}
  
  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="modalBody" className="p-4">
        <Grid container>
          <Grid item className="text-center" xs={12}>
            <h3>
              <>פרטים נוספים</>
            </h3>
          </Grid>
          <Grid item dangerouslySetInnerHTML={{__html:message}} className="text-center" id='data_place2' xs={12}>
           
          </Grid>
         
          <Grid item className="text-center" xs={12}>
            <Button onClick={handleClose} variant="contained" color="primary">
              תחזור
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
