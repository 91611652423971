/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PRESERVE_DATA":
      return {
        ...state,
        packageDetails: payload,
      };
    case "CHANGE_PASSENGERS":
      return {
        ...state,
        passengersQuantity: payload,
      };
    case "CHANGE_ROOM":
      return {
        ...state,
        selectedRoomContext: payload,
      };
    default:
      return state;
  }
};
