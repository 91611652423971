import React from "react";
import Card from "./Card";
import './index.css';

// eslint-disable-next-line
import { animateScroll as scroll, scroller } from 'react-scroll' 

class DealCard extends React.Component {
    
    constructor (props) {
        super(props)
        
       
        // skip this card if no flights found in this destination
        if (this.props.data === null || (this.props.data.deals===null))
            return;

        // never show 2 deals with the same checkin date & same flight (meaning we can show the same date if the flight is in different hours)
        const uniqueDealsList = new Map();
        for (const deal of this.props?.data?.deals) {
            if (!deal) return 
            
            const id = deal?.property?.uid + deal?.price;
            
            if (!uniqueDealsList.has(id)) {
                uniqueDealsList.set(id, deal); 
            }
            
        }
        this.data_source = Array.from(uniqueDealsList.values());

        ///////////
        
        ////////////
      
        this.state = {
            'open': false
        }
        this.cards_counter = 0;
        this.total = this.data_source.length;
        this.GetDestinationURL = this.GetDestinationURL.bind(this)

    }
   
    
    GetDestinationURL = () => {
        
       
        var city_id=this.this.props?.destination?.city_id;
        var city_name=this.props?.destination?.city_name;

        let prefix = `/${city_name}/?`;
            let destination_param = `destinations=${city_id}`;
            //let url = `/${city_name}/?destinations=${city_id}`;

            
            // &min_stars=4&max_stars=5&min_nights=5&max_nights=7&when=all&when_caption=%D7%91%D7%AA%D7%90%D7%A8%D7%99%D7%9B%D7%99%D7%9D%20%D7%94%D7%9B%D7%99%20%D7%96%D7%95%D7%9C%D7%99%D7%9D&from=&to=&limit=10&destination=59&destination_name=%D7%90%D7%A0%D7%98%D7%90%D7%9C%D7%99%D7%94&sortby=price-asc&months=&dates=&QS=null
            let params = ``;
            
            if (this.props?.stars && this.props?.stars!='') {
                params += `&stars=${this.props?.stars}`;
            }

            if (this.props?.nights && this.props?.nights!='') {
                params += `&nights=${this.props?.nights}`;
            }

            if (this.props?.meals && this.props?.meals!='') {
                params += `&meals=${this.props?.meals}`;
            }

            if (this.props?.airlines && this.props?.airlines!='') {
                params += `&airlines=${this.props?.airlines}`;
            }


            /*if (this.props?.months && this.props?.months!='') {
                params += `&months=${this.props?.months}`;
            }*/

            if (this.props?.adults && this.props?.adults!='') {
                params += `&adults=${this.props?.adults}`;
            }

            if (this.props?.teens && this.props?.teens!='') {
                params += `&teens=${this.props?.teens}`;
            }


            if (this.props?.children && this.props?.children!='') {
                params += `&children=${this.props?.children}`;
            }

            if (this.props?.infants && this.props?.infants!='') {
                params += `&infants=${this.props?.infants}`;
            }

            if (this.props?.dow && this.props?.dow!='') {
                params += `&dow=${this.props?.dow}`;
            }

            if (this.props?.waterpark ) {
                params += `&waterpark=1`;
            }

            if (this.props?.luxury ) {
                params += `&luxury=1`;
            }

            if (this.props?.casino ) {
                params += `&casino=1`;
            }

            if (this.props?.near_chabad ) {
                params += `&chabad=1`;
            }

            if (this.props?.spa ) {
                params += `&spa=1`;
            }

            if ((this.props?.from_date && this.props?.from_date!='' && this.props?.to_date && this.props?.to_date!='')) {
                const date_caption = this.props.from_date.split('-')[2]+'.'+this.props.from_date.split('-')[1]+'.'+this.props.from_date.split('-')[0] + ' עד ' + this.props.to_date.split('-')[2]+'.'+this.props.to_date.split('-')[1]+'.'+this.props.to_date.split('-')[0]
                prefix = `/${city_name}/${date_caption}/?`;
                params += `&from_date=${this.props.from_date}&to_date=${this.props.to_date}&dates_type=${this.props.dates_type}&month=`
            }

            if (this.props.months && this.props.months.length==1) {
                prefix = `/${city_name}/${this.props.months[0]}/?`;
                params += `&month=${this.props.months.toString()}`
            }

            var url=prefix + destination_param + params;
            console.log(url)
            return url;
    }

    GoToDestination = (event, city_id, city_uid, city_name, click_location) => {
        
//        event.stopPropagation()
        let url = this.GetDestinationURL();
        
        // add GTM custom event
        if (window['google_tag_manager']) {
            const event_name = 'GoToDestinationPage';
            console.log('GTM event: ' + event_name)

            event.preventDefault();

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event_name,
                eventTimeout: 2000,
                city_name: city_name,
                city_id: city_id,
                target_url: url,
                pageUrl: window.location,
                pageTitle : window.title,
                source: click_location,
                eventCallback: function() {
                    window.location = url;
                }
            });
        } else {
            window.location.href=url;
        }
    
    }

  
    render() {

        // skip this card if no flights found in this destination
        if (this.props.data===null || this.data_source===null) 
            return (<></>);
          
        // Fetch deals and make sure they are unique
        const uniqueDealsMap = new Map();
        for (const deal of this.props?.data?.deals) {
            if (!deal) return 
            
            const id = deal?.property?.uid + deal?.price;
            
            if (!uniqueDealsMap.has(id)) {
                uniqueDealsMap.set(id, deal); 
            }
            
        }
        this.data_source = Array.from(uniqueDealsMap.values());
        
        let cardData = this.data_source.map((item,index) => {
            
            const dominantColor = item?.property?.dominant_color?item?.property?.dominant_color:'#777';

            return (
                <Card
                    key={'card' + index}
                    index={index} 
                    data={item} 
                    active={this.props?.open} 
                    slug={item.code}  
                    type={this.props.type} 
                    stars={this.props.stars} 
                    show={this.props.show}
                    month={this.props.month} 
                    adults={this.props?.adults} 
                    teens={this.props?.teens} 
                    children={this.props?.children}
                    infants={this.props?.infants} 
                    dow={this.props?.dow} 
                    city_uid={this.props?.destination?.city_uid} 
                    city_name={this.props?.destination?.city_name} 
                    city_id={this.props?.destination?.city_id}
                    country_name={this.props?.destination?.country_name}
                    background={dominantColor}
                    GoToDestination={this.GoToDestination}
                    DestinationURI={this.state.destination_url}
                    destination={this.props?.destination}
                />
            )
        }
        );

        this.cards_counter++;
        
        return cardData;
    
    }

    


}

//ReactDOM.render(<Card />, document.getElementById('root'));

export default DealCard
