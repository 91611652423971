import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";


class Terms extends React.Component {
    render() {
        return(
            <div className="App Terms">
                
                <main>
                    
                <div class="entry-content clr" itemprop="text">
                    <p><h2>הצהרת נגישות</h2>
                    טריפ גייטור בע"מ, אחראית על הקמת והפעלת אתר : https://www.afnu.co.il. אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור השירות הניתן לאזרחים עם מוגבלות.<br />
                    אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת להפוך את שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.</p>
                    <p>במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות הזקוקים לנגישות דיגיטלית, על מנת לצרוך מידע ושירותים כללים.<br />
                    הנגשת האתר של טריפ גייטור בע"מ, נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות,<br />
                    לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על בני הגיל השלישי.</p>
                    <p>הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים "Vee הנגשת אתרים".</p><br />
                    <p><h3>רמת הנגישות באתר – AA</h3>
                    חברת "Vee", התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש בטלפון הסלולרי ככל הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws ו- NVDA.</p>
                    <p>מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות 5568 התשע"ג 2013 ברמת AA. וכן, מיישמת את המלצות מסמך WCAG2.2 מאת ארגון W3C.</p>
                    <p>בעברית: הנחיות לנגישות תכנים באינטרנט<br />
                    באנגלית:  Web Content Accessibility Guidelines (WCAG) 2.0</p>
                    <p>הנגשת האתר בוצעה בהתאם להנחיות רשות התקשוב להנגשת יישומים בדפדפני אינטרנט.</p>
                    <p> </p>
                    <p><h3>כיצד עוברים למצב נגיש?</h3>
                    באתר מוצב אייקון נגישות (בד"כ בדפנות האתר). לחיצה על האייקון מאפשרת פתיחת של תפריט הנגישות. לאחר בחירת הפונקציה המתאימה בתפריט יש להמתין לטעינת הדף ולשינוי הרצוי בתצוגה (במידת הצורך).</p>
                    <p>במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם שניה. בכל מצב, ניתן לאפס הגדרות נגישות.</p>
                    <p>התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera בכפוף (תנאי יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.</p>
                    <p>האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.</p>
                    <p>לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר.</p>
                    <p> </p>
                    <p><h3>תיקונים והתאמות שבוצעו באתר:</h3><br />
                    התאמה לקורא מסך – התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS<br />
                    אמצעי הניווט באתר פשוטים וברורים.<br />
                    תכני האתר כתובים באופן ברור, מסודר והיררכי.<br />
                    האתר מותאם לצפייה בדפדפנים מודרניים.<br />
                    התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.<br />
                    כל הדפים באתר בעלי מבנה קבוע (1H/2H/3H וכו').<br />
                    לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).</p>
                    <p>פונקציונליות תוכנת נגישות:<br />
                    התאמה לקורא מסך – התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS<br />
                    עצירת הבהובים – עצירת אלמנטים נעים וחסימת אנימציות<br />
                    דילוג ישיר לתוכן – דילוג על התפריט הראשי ישירות אל התוכן.<br />
                    התאמה לניווט מקלדת.<br />
                    הגדלה / הקטנה של טקסט.<br />
                    ריווח בין אותיות / מילים / שורות.<br />
                    ניגודיות וצבע – גבוהה, הפוכה, שחור לבן.<br />
                    גופן קריא.<br />
                    הדגשת קישורים.<br />
                    מדריך קריאה.<br />
                    שינוי אייקון סמן עכבר.<br />
                    תיאור לתמונות.</p>
                    <p><h3>החרגות</h3><br />
                    חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים והאלמנטים באתר, ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.</p>
                    <p>אנו פועלים לשפר את נגישות האתר שלנו כל העת, כחלק ממחויבותנו לאפשר לכלל האוכלוסייה להשתמש בו, כולל אנשים עם מוגבלות.</p>
                    <p> </p>
                    <p><h3>יצירת קשר בנושא נגישות</h3><br />
                    במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות באמצעות פנייה לרכז הנגישות שלנו:<br />
                    על מנת שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:</p>
                    <p>•        תיאור הבעיה.</p>
                    <p>•        מהי הפעולה שניסיתם לבצע.</p>
                    <p>•        קישור לדף שבו גלשתם.</p>
                    <p>•        סוג הדפדפן וגרסתו.</p>
                    <p>•        מערכת הפעלה.</p>
                    <p>•        סוג הטכנולוגיה המסייעת (במידה והשתמשתם).</p>
                    <p>טריפ גייטור בע"מ תעשה ככל יכולה על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות בצורה המקצועית והמהירה ביותר.</p>
                    <p>רכז נגישות:</p>
                    <p>אביב כהן</p>
                    <p>050-7801801 (מספר טלפון להודעות וואטסאפ)</p> 
                    <p>aviv@afnu.co.il</p>
                    <p>תאריך עדכון הצהרת נגישות 28-07-2024</p>
                    <p> </p>
                    <p> </p>
                    </div>


                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;