import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onClose, openError ,errorMessage} = props;
    



    const handleClose = () => {
        onClose();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openError}>
            <div className='modalBody p-4'>

                <Grid container>
                    <Grid item className='mb-4 text-center' xs={12}><h5>{errorMessage}</h5></Grid>
                    <Grid item className='text-center' xs={12}><Button onClick={handleClose} variant='contained' color='secondary'>סגור</Button></Grid>


                </Grid>
            </div>

        </Dialog>
    );
}
