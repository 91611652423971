import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faSmileWink } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import {PageView, initGA} from '../../features/Tracking';

class Members extends React.Component {

    componentDidMount() {
        initGA();
        PageView();
        document.title = 'מועדון חברים - עפנו';

  
    }

    render() {
        window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            event: 'Pageview',
            'pageUrl': window.location,
            'pageTitle' : window.title
            });
            
        return(
            <div className="App Members">
                <Header hide="true" hideSearch={true} />
                <main>
                   <h1>VIP</h1>
                   <h2>רק החברים שלנו יכולים לראות את כל הדילים ולהנות ממגוון הטבות נוספות*</h2>
                   <h2 className="gold">ההרשמה והחברות חינם</h2>
                   <div className="cards">
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faClock} size="4x" />
                        </div>
                        <div className="content">
                            <h3>חשיפה לדילים הכי שווים לפני כולם</h3>
                            על מנת להגדיל את סיכוייך לשלם את המחיר הזול ביותר
                        </div>
                    </div>
                   
                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faGift} size="4x" />
                        </div>
                        <div className="content">
                            <h3>הפתעות בשדה התעופה</h3>
                            בטיסות נבחרות
                        </div>
                    </div>

                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faBirthdayCake} size="4x" />
                        </div>
                        <div className="content">
                            <h3>דיל מיוחד בחודש יום ההולדת</h3>
                            בכפוף לזמינות ולמלאי
                            
                        </div>
                    </div>

                    <div className="card">
                        <div className="icon">
                            <FontAwesomeIcon icon={faSmileWink} size="4x" />
                        </div>
                        <div className="content">
                            <h3>הטבות נוספות</h3>
                            הפתעות, מתנות, הגרלות והזמנות למסיבות מיוחדות לחברי המועדון לאורך השנה
                        </div>
                    </div>  
                   </div>
                   <div className="btns">
                       <a href="/members/signup/"><button className="signup">הצטרפו חינם</button></a>
                       <a href="/members/signup/"><button className="signin">כניסת חברים</button></a>
                   </div>
                   <p>* בכפוף <a href="/members/terms.html" target="_blank">לתנאי המועדון</a></p>                                                      
                </main>
                <Footer />
            </div>
        );
    }
}

export default Members;