import React, {useState, useEffect, Exception} from 'react'
import { useHistory } from "react-router-dom";
import configData from '../../config/config.json';
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorModal from "./ErrorModal";

const API_ENDPOINT = configData.API_ENDPOINT;

const PaymentConfirmation = (props) => {
    const history = useHistory();
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [confirmationId, setConfimrationId] = React.useState("")
    const [checkStatus, setCheckStatus] =  React.useState('wait');
    const [openError, setOpenError] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const paymentUid = props.match.params.uid;
    
    let checksCount = 0;
    let errorsCount = 0;

    
    useEffect(async () => {
        checkPaymentHandler()
    },[]);

    const handleOk = () => {
        setOpenConfirmation(false);
        history.push('/')
    }

    const GetMealPlanCode = (code) => {
        let local_meal_plan_code='RO';
        
        switch(code) {
            case 'SA'||'SP'||'SC': local_meal_plan_code='RO';break; // Accommodation only
            case 'HD': local_meal_plan_code='BB';break;             // B&B 
            case 'HM'||'MB'||'MP': local_meal_plan_code='HB';break; // HAlf Board
            case 'PC'||'PV': local_meal_plan_code='FB';break;       // Full board
            case 'TI'||'TP': local_meal_plan_code='AI';break;       // All Inclusive
            default: local_meal_plan_code='RO'
        }

        return local_meal_plan_code;
    }

    
    /**
     * Calculate the number of days / weeks / months between the departure date & today
     * @param {*} departure_date 
     */
    const GetTimeToDeparture = (departure_date) => {
        try {
            const today = new Date();
            const [datestr, timestr] = (departure_date+'').split("T");
            const [year, month, day] = datestr.split("-");
            const departureDate = new Date(+year, +month - 1, +day);

            const difference = departureDate.getTime() - today.getTime();

            const daysToDeparture = Math.ceil(difference / (1000 * 60 * 60 * 24));
            
            switch (true) {
                case daysToDeparture <= 2: return '1-2 days';break;
                case daysToDeparture <= 4: return '3-4 days';break;
                case daysToDeparture <= 7: return '5-7 days';break;
                case daysToDeparture <= 10: return '8-10 days';break;
                case daysToDeparture <= 13: return '11-13 days';break;
                case daysToDeparture <= 21: return '2 weeks';break;
                case daysToDeparture <= 28: return '3 weeks';break;
                case daysToDeparture <= 35: return '4 weeks';break;
                case daysToDeparture <= 42: return '5 weeks';break;
                case daysToDeparture <= 49: return '6 weeks';break;
                case daysToDeparture <= 56: return '7 weeks';break;
                case daysToDeparture <= 63: return '8 weeks';break;
                case daysToDeparture <= 70: return '9 weeks';break;
                case daysToDeparture <= 100: return '2 months';break;
                case daysToDeparture <= 130: return '3 months';break;
                case daysToDeparture <= 160: return '4 months';break;
                case daysToDeparture <= 190: return '5 months';break;
                case daysToDeparture <= 220: return '6 months';break;
                case daysToDeparture <= 250: return '7 months';break;
                case daysToDeparture <= 280: return '8 months';break;
                case daysToDeparture <= 310: return '9 months';break;
                case daysToDeparture > 310: return '10+ months';break;
                default: return '';
            }

        } catch(e) {
            return 'ERROR'
        }
        
    }

    /**
     * This function will check if the payment request was already authorized by the supplier 
     * 
     * If not - it will wait 2.5 seconds and check again
     */
    const checkPaymentHandler = async () => {
        try {        
            const res = await checkPayment();
            let timeoutMS = 2500;

            if (res?.status===200) {
                setCheckStatus(res?.data?.status)
                
                // Payment authorized successfully!
                if (res?.data?.status==='authorized') {
                    const bookingId = res?.data?.booking?.id;
                    // Open confirmation window
                    setConfimrationId(bookingId);
                    setOpenConfirmation(true);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ ecommerce: null });  
                    window.dataLayer.push({
                        event: 'ReservationCompleted',
                        'pageUrl': window.location.href.split('?')[0],
                        'pageTitle' : window.title,
                        'bookingID'  : bookingId,
                        'value': res?.data?.booking?.amount,
                        'currency': res?.data?.booking?.currency,
                        ecommerce: {
                            purchase: {
                              actionField: {
                                id: bookingId,
                                revenue: res?.data?.booking?.amount
                              },
                            }
                        },
                    });

                    // Update GTM 
                    window.dataLayer.push({
                        event: "add_payment_info",
                        ecommerce: {
                          currency: res?.data?.booking?.currency,
                          value: res?.data?.booking?.amount,
                          payment_type: "Credit Card",
                          items: [
                          {
                            item_id: res?.data?.booking?.quote
                          }
                          ]
                        }
                      });

                      window.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: bookingId,
                            value: res?.data?.payment?.amount,
                            tax: 0,
                            shipping: 0,
                            currency: res?.data?.payment?.currency,
                            items: [
                            {
                                item_id: res.data?.deal?.uid,
                                item_name: res.data?.deal?.code,
                                index: 0,
                                discount: 0,
                                item_brand: res.data?.deal?.city_name,
                                item_category:  `adults:${res.data?.deal?.adults},children:${res.data?.deal?.children},infants:${res.data?.deal?.infants},rooms:${res.data?.deal?.rooms_count}`,
                                item_category2: "stars: " + res.data?.deal?.stars + " meals: " + GetMealPlanCode(res.data?.deal?.meal_code),
                                item_category3: "nights: " + res.data?.deal?.nights,
                                item_category4: "departure: " + GetTimeToDeparture(res.data?.deal?.departure+''),
                                item_category5: "month: "+ (res.data?.deal?.departure+'').split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
                                item_list_id:   "deals",
                                item_list_name: "Deals",
                                item_variant:   res.data?.payment?.is_flex==1?'flex':'standard',
                                location_id: res.data?.deal?.city_id,
                                price: res?.data?.payment?.amount,
                                quantity: 1
                            }
                          ]
                        }
                      });
                      
                } else {
                    // Wait 2 seconds before checking again (maximum of 150 check attempts)
                    if (checksCount++ <= 150) { 
                        if (checksCount > 30)
                            timeoutMS = 5000;
                        else 
                            if (checksCount > 60)
                            timeoutMS = 10000;
                            else 
                                if (checksCount > 100)
                                    timeoutMS = 20000;

                        // check again in 2 seconds
                        setTimeout(checkPaymentHandler, timeoutMS);
                        
                    } else {
                        console.log(res)
                        setCheckStatus('error');
                        // Show error dialog
                        setErrorMessage('ארעה תקלה בחיבור לשרת תשלומים. אנא נסו שנית או פנו אלינו באמצעות מייל support@afnu.co.il')
                        setErrorMessage(true)
                    }
                }
                
            } else {
                // Server responded with unexpected error 

                // if failed 7 times, show error message
                if (errorsCount++ > 7) {
                    setCheckStatus('error');
                    // Show error dialog
                    setErrorMessage(`שרת התשלומים החזיר שגיאה מספר ${res?.status}. אנא שילחו הודעה למחלקת השירות וציינו את מספר השגיאה: support@afnu.co.il`)
                    setErrorMessage(true)
                }
                else {
                    setCheckStatus('server_not_responing')

                    // try again in 3 seconds
                    setTimeout(checkPaymentHandler, 3000);
                }
            }
        } catch (e) {
            console.log('Exception in checkPaymentHandler')
            console.log(e)
            throw new Exception('checkPaymentHandler failed')
        }
    }

    /**
     * Makes the actual call to remote API to verify the payment
     */
    const checkPayment = async () => {
        console.log(`${API_ENDPOINT}/payment/${paymentUid}`);
        //const checksCount = 0;

        try {
            if (paymentUid!=null) {
             
                const res = await axios.get(
                  `${API_ENDPOINT}/payment/${paymentUid}?r=` + Math.random(),
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    withCredentials: true  
                  }
                );
    
                return res;
            }
        } catch (e) {
            console.log('Exception in checkPayment')
            console.log(e)
            throw new Exception('checkPayment failed')
        }
    }
    
    const handleClose = () => {
        setOpenError(false);
    };    

    return (
        <>
        <ErrorModal
            errorMessage={errorMessage}
            openError={openError}
            onClose={handleClose}
        />
        <ConfirmationModal
            confirmationId={confirmationId}
            openConfirmation={openConfirmation}
            onOk={handleOk}
        />
        {checkStatus && (checkStatus==='authorized' || checkStatus==='error') ? (
            <></>
        ) : (
            <div className="text-center mt-5">
                <CircularProgress />
            </div>
            )}
        
        </>
    )
}

export default PaymentConfirmation
