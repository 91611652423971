import React from "react";
import { Grid, Select,FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons"

//class Room extends React.Component {

class Room extends React.Component {

    constructor (props) {
        super(props)
    }

    render() {
        return (
            <div className="pax_box" role="group">
                <label tabIndex={0} style={{width:'100%',display:'flex'}}>
                <Grid item xs={6} className="title mb-3 mt-3">
                    <h4>מבוגרים</h4>
                    <small>גיל 16 ומעלה</small>
                </Grid>
                <Grid item xs={6} className="mb-3 mt-3" align="center"  role="group" aria-label="בחירת הרכב נוסעים (לחצו על + ו-- להוספה או הסרה של מבוגרים בגילאי 16 ומעלה">
                    <Grid container  style={{alignItems:'center'}}>
                        <Grid item xs={5}>
                            <button type="button" className="minus" name="adults" role="button" aria-label="הסר מבוגר" onClick={(e) => this.props.ModifyAdults(-1)} style={{border:'none'}} title="הסר מבוגר">
                                <FontAwesomeIcon icon={faMinusSquare} /></button>
                        </Grid>
                        <Grid item xs={2} className="caption">
                            <div title={this.props?.filters.rooms[0]?.adults.length + ' מבוגרים נבחרו'} role="spinbutton" aria-live="polite" aria-label={this.props?.filters.rooms[0]?.adults.length + ' מבוגרים נבחרו'}><strong>{this.props?.filters.rooms[0]?.adults.length}</strong></div>
                        </Grid>
                        <Grid item xs>
                            <button type="button" className="plus" onClick={(e) => this.props.ModifyAdults(1)} role="button" aria-label="הוסף מבוגר" title="הוסף מבוגר" style={{border:'none'}}>
                                <FontAwesomeIcon icon={faPlusSquare} size="3x" /></button>
                        </Grid>
                    </Grid>
                </Grid>
                </label>

                <label tabIndex={0} style={{width:'100%',display:'flex'}}>
                <Grid item xs={6} className="title mb-3 mt-3" tabIndex={0}>
                    <h4>נוער</h4>
                    <small>גיל 12 עד 15</small>
                </Grid>
                <Grid item xs={6} className="mb-3 mt-3" align="center"  role="group" aria-label="בחירת הרכב נוסעים (לחצו על + ו-- להוספה או הסרה של נוער בגילאי 12 עד 15">
                    <Grid container style={{alignItems:'center'}}>
                        <Grid item xs={5}>
                            <button className="minus" name="teens" title="הסר נער" aria-label="הסר נער" style={{border:'none'}} role="button"
                                onClick={(e) => this.props.ModifyTeens(-1)}>
                                <FontAwesomeIcon icon={faMinusSquare} /></button>
                        </Grid>
                        <Grid item xs={2} className="caption">
                            <div title={this.props?.filters.rooms[0]?.teens.length + ' נערים נבחרו'} role="spinbutton" aria-live="polite"  aria-label={this.props?.filters.rooms[0]?.teens.length + ' נערים נבחרו'}>
                                <strong>{this.props?.filters.rooms[0]?.teens.length}</strong></div></Grid>
                        <Grid item xs={5} >
                            <button className="plus"  aria-label="הוסף נער" title="הוסף נער" style={{border:'none'}} role="button"
                                onClick={(e) => this.props.ModifyTeens(1)}>
                            <FontAwesomeIcon icon={faPlusSquare} /></button>
                        </Grid>
                    </Grid>
                </Grid>
                </label>

                <label tabIndex={0} style={{width:'100%',display:'flex'}}>
                <Grid item xs={6} className="title mb-3 mt-3">
                    <h4>ילדים </h4>
                    <small>מגיל שנתיים עד 11</small>
                </Grid>
                <Grid item xs={6} className="mb-3 mt-3" align="center"  role="group" aria-label="בחירת הרכב נוסעים (לחצו על + ו-- להוספה או הסרה של ילדים בגילאי שנתיים עד 11">
                    <Grid container style={{alignItems:'center'}}>
                        <Grid item xs={5}>
                            <button className="minus" name="children"  title="הסר ילד" aria-label="הסר ילד" style={{border:'none'}}  role="button"
                                onClick={(e) => this.props.ModifyChildren(-1)}>
                                <FontAwesomeIcon icon={faMinusSquare} /></button>
                        </Grid>
                        <Grid item xs={2} className="caption">
                        <div title={this.props?.filters.rooms[0]?.children.length + ' ילדים נבחרו'} role="spinbutton" aria-live="polite"  aria-label={this.props?.filters.rooms[0]?.children.length + ' ילדים נבחרו'}>
                            <strong>{this.props?.filters.rooms[0]?.children.length}</strong></div>
                        </Grid>
                        <Grid item xs={5}>
                            <button className="plus" name="children"   title="הוסף ילד" aria-label="הוסף ילד" style={{border:'none'}} role="button"
                                onClick={(e) => this.props.ModifyChildren(1)}>
                                <FontAwesomeIcon icon={faPlusSquare} /></button>
                        </Grid>
                    </Grid>
                </Grid>
                </label>

                <label tabIndex={0} style={{width:'100%',display:'flex'}}>
                <Grid item xs={6} className="title mb-3 mt-3">
                    <h4>תינוקות</h4>
                    <small>מגיל לידה ועד 23 חודשים</small>
                </Grid>
                <Grid item xs={6} className="mb-3 mt-3" align="center"   role="group" aria-label="בחירת הרכב נוסעים (לחצו על + ו-- להוספה או הסרה של תינוקות מגיל לידה ועד 23 חודשים כולל">
                    <Grid container style={{alignItems:'center'}}>
                        <Grid item xs={5}>
                            <button className="minus" name="infants"  title="הסר תינוק" aria-label="הסר תינוק" style={{border:'none'}} role="button"
                                onClick={(e) => this.props.ModifyInfants(-1)}>
                                <FontAwesomeIcon icon={faMinusSquare} /></button>
                        </Grid>
                        <Grid item xs={2} className="caption">
                        <div title={this.props?.filters.rooms[0]?.infants.length + ' תינוקות נבחרו'} role="spinbutton" aria-live="polite"  aria-label={this.props?.filters.rooms[0]?.infants.length + ' תינוקות נבחרו'}>
                            <strong>{this.props?.filters.rooms[0]?.infants.length}</strong></div>
                        </Grid>
                        <Grid item xs={5}>
                            <button className="plus" name="infants"   title="הוסף תינוק" aria-label="נוסף תינוק" style={{border:'none'}}  role="button"
                                onClick={(e) => this.props.ModifyInfants(1)}>
                                <FontAwesomeIcon icon={faPlusSquare} /></button>
                        </Grid>
                    </Grid>
                </Grid>
                </label>
            </div>
        )   
    } 
}

export default Room