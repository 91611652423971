import React, { createContext, useReducer, useState } from "react";
import AppReducer from "./AppReducer";

//Initial State
let initialState = {
  packageDetails: {},
  reservationDetails: {},
  passengersQuantity: {
    adults: 2,
    children: 0,
    infants: 0,
    seniors: 0,
  },
  selectedRoomContext: null,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [preserve, setPreserve] = useState(0);
  let [state, dispatch] = useReducer(AppReducer, initialState);

  //Actions

  const preserveData = (data, selectedRoom) => {
    dispatch({
      type: "PRESERVE_DATA",
      payload: { data, selectedRoom },
    });
    setPreserve(preserve + 1);
  };

  const preserveReservation = (data) => {
    dispatch({
      type: "CONFIRM_RESERVATION",
      payload: {data}
    });
    setPreserve(preserve + 1)
  };



  const changePassengerQuantity = (data) => {
    dispatch({
      type: "CHANGE_PASSENGERS",
      payload: data,
    });
  };
  const changeSelectedRoom = (data) => {
    dispatch({
      type: "CHANGE_ROOM",
      payload: data,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        packageDetails: state.packageDetails,
        reservationDetails: state.reservationDetails,
        passengersQuantity: state.passengersQuantity,
        selectedRoomContext: state.selectedRoomContext,
        preserveData,
        preserveReservation,
        changePassengerQuantity,
        changeSelectedRoom
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
