import React from "react";
import Room from './Room';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import {Grid} from '@material-ui/core';


class Pax extends React.Component {
        
    constructor (props) {
        super(props)

        //if (!this.props.location || !this.props.location.search || this.props?.location?.search==="") {
            //console.log('default')
            this.state = {

              open:               this.props?.open?this.props?.open:false,
                filters: {
                    ...this.props.filters,
                }
            }
        //}

    }
    
    closeWindow = (event) => {
        event.stopPropagation();
        this.props.onCloseDestinations(this.state?.filters?.destinations)
    }

    
    
    render(){

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} style={{padding:'0 important!'}}>
                    <h1 tabIndex={0}>מי יוצא לחופשה?</h1> 
                </Grid>
                <Grid className="p-3" style={{paddingTop:0}}>
                    <div className="ugly modalBody modalPassengersUpdate">
                    
                    {/*<div id="err" style={state.err!=''?{color:'#D8000C', backgroundColor:'#FFBABA', border: '1px solid', position:'fixed', fontWeight:600, padding:'1em', direction:'ltr', zIndex:10000}:{}}>{state.err}</div>*/}
                    <Grid container>
                        <Grid item className="text-center mb-3" xs={12}>
                            <Room 
                              filters={this.props.filters} 
                              max_guests_per_room={this.max_adults_per_room} 
                              ModifyAdults={this.props.ModifyAdults} 
                              ModifyChildren={this.props.ModifyChildren} 
                              ModifyTeens={this.props.ModifyTeens} 
                              ModifyInfants={this.props.ModifyInfants} />
                        </Grid>
                    </Grid>
                </div>
                </Grid>

                <Grid>
                    <div className='notification'>
                       {/* <WbIncandescentIcon className='icon'/>
                            לא מצאתם את הרכב הנוסעים המבוקש? בחרו בהרכב הכי קרוב וכשתכנסו לדיל ספציפי תוכלו לבחור את כמות הנוסעים המדויקת                                   */} </div>

                </Grid>


                
            </Grid>
        )
    }
}

export default Pax