import React from 'react';
//import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';

//import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onClosePassengersChangeWarning, openPassengersChangeWarning ,passengersChangeMessage} = props;
    //const classes = useStyles();

    const handleClosePassengersChangeWarning = () => {
        onClosePassengersChangeWarning();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openPassengersChangeWarning}>
            <div className='ugly modalBody p-4'>

                <Grid container>
                    <Grid item className='text-center' xs={12}><h3>שימו לב</h3></Grid>
                    <Grid item className='mb-4 text-center' xs={12}><div dangerouslySetInnerHTML={{__html:passengersChangeMessage}} /></Grid>
                    <Grid item className='text-center' xs={12}><Button onClick={handleClosePassengersChangeWarning} variant='contained' color='secondary'>הבנתי</Button></Grid>


                </Grid>
            </div>

        </Dialog>
    );
}
