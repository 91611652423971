import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button } from "@material-ui/core";
import './ConfirmationModal.css';

export default function SimpleDialog(props) {
    const { onOk, openConfirmation} = props;

    const handleOk = () => {
        onOk();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openConfirmation} keepMounted>
            <div className='ugly modalBody p-4'>

                <Grid container id="ConfirmationModal">
                    <Grid item className='text-center' xs={12}>
                        <div className="heading">
                            <img src="/images/modals/reservation-pending-confirmation.png" alt="הזמנתכם נקלטה וממתינה לאישור" />
                            {/*<p className="attention"><span>שימו לב ❤</span><br />*/}
                            <h1>כל הכבוד, עשיתם את זה!</h1>
                            <h2 className='mb-4'>
                                <p>מספר הזמנה: <strong><span className="orderId">{props.confirmationId}</span></strong></p>
                                <p>סטטוס: <span className="attention">ממתינה לאישור</span>*</p></h2>
                            <p>ברגעים אלו אנו פונים לספקים השונים 
                            ומבקשים לאשר את הזמנתכם...</p>
                            <p>התהליך עשוי לקחת מספר שעות
                            ובסופו תקבלו מאיתנו עדכון סופי למייל!</p>
                            <p> </p>
                            <p><strong><font color="red"><u>שימו לב</u></font>: במהלך הדקות הקרובות ישלח אליכם מייל עדכון - במידה ולא קיבלתם אותו, אנא בידקו בתקיית הספאם או צרו איתנו קשר! </strong></p>                            
                        </div>
                    </Grid>
                    
                    <Grid container id="ConfirmationModal">
                        <Grid item className='text-center mt-4' xs={12}>
                            <h4>*החיוב בפועל יבוצע רק
                            לאחר אישור הספקים</h4>
                        </Grid>
                    </Grid>
                    <Grid item  className='text-center mt-2' xs={12}><Button onClick={handleOk} variant='contained' color='secondary'>סגור</Button></Grid>

                </Grid>
            </div>

        </Dialog>
    );
}
