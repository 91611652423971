import React, { useState, useEffect } from "react";
import './spinner.css';

const Spinner = (props) => {

    
    const [loadImage, setLoadImage] = useState(false);
    const [spinning, setSpinning]  = useState(false);

    var index = 0;
    var waitInterval = props.interval>0?props.interval:3000; // if waitInterval not specificed, set default to 3seconds
    
    //props.poipointer==null?index=0:index=props.pointer;
    const imagesArray = props.images;

    index = 0;

    const startSpinner = async function() {
        setSpinning(true);
        spin(true);
    }

    const spin = async function(active) {

        if (!active) return;

        setLoadImage(imagesArray[index])
        index+1 < imagesArray.length ? index++ : index = 0;
        
        if (document.getElementById("SuperSpinner")) {
            //console.log('Spinning: ' + imagesArray[index])
            window.setTimeout(function(){spin()}, waitInterval);
        }
    }

    useEffect( () => {      
        if (!spinning) {
            startSpinner();
        }
        
        // cleanup
        return () => {
            spin(false);
        };
        
    },[])
  
    return (
        <div id="SuperSpinner" className="ugly">
            <div className="spinnerWrapper">
                <div className="spinnerLine"></div>
                <div className="spinnerLoader">
                <div className="image" style={{backgroundImage:`url(${loadImage})`}}></div>
                </div>
            </div>
            <div className="content">{props.children}</div>	
        </div>

    )
}

export default Spinner;