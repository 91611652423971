import React from "react";
//import { render } from 'react-dom';
import Modal from 'react-responsive-modal';
import CardLine from "./CardLine";
//import FlightLines from "./FlightLines";
//import * as Scroll from 'react-scroll';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faBed, faPlane  } from "@fortawesome/free-solid-svg-icons"; 
// eslint-disable-next-line
import { animateScroll as scroll, scroller } from 'react-scroll' 
import Promotion from '../../features/Promotion/'

class Card extends React.PureComponent {
    
    constructor (props) {
        super(props)

        // skip this card if no flights found in this destination
        if (this.props.data == null || (this.props.data.deals==null))
            return;

        // never show 2 deals with the same checkin date & same flight (meaning we can show the same date if the flight is in different hours)
        //let seen = {};
        this.data_source = this.props.data.deals
            /*.filter(deal=>{
                 return seen.hasOwnProperty(deal.departure.date + '|' + deal.return.date + '|' + deal.flight.invetory_uid) ? false : (seen[deal.departure.date + '|' + deal.return.date + '|' + deal.flight.invetory_uid] = true);
        });*/
        
        this.state = {
            'text': 'עוד דילים ב' + this.props.data.city,
            'showMore': 0,
            'fa_arrow': 'fa fa-angle-double-down',
            'city': this.props.data.city,
            'card_deals': this.data_source.slice(0,3),
            'open': false,
            'open_promotion': false,
            'expired': false,
        }
        this.counter = 0;
        this.cards_counter = 0;
        this.total = this.data_source.length;


        this.cardData = this.state.card_deals.map((item, idx) => {
            let baggageArray = [];

            const deals = this.props?.deals;
            let dealExpired = this.props?.expired;

            // check if deal expired
            if (deals && deals.length > 0) {
                const dealsArray = JSON.parse(deals)
                
                //console.log(dealsArray)
                //console.log(item.price)
                // set the default value as the one inherited from the index page
    
                const found = dealsArray.find(deal => {

                    const itemPrice = item.price/2; // realtime price per person

                    // by default, use the real deal price as the maximum
                    let dealMaxPrice = itemPrice;

                    // if deal max price specified in the promotions' settings text files => override the max price
                    if (deal?.max_price>0)
                        dealMaxPrice = deal.max_price*1;
                  
                    const dealOverPriced = (deal.uid==item.uid) && dealMaxPrice < itemPrice;

                    if (dealOverPriced)
                        item.price = dealMaxPrice*2; // overwrite displayed price with the max price

                    return dealOverPriced || ((deal.uid==item.uid) && deal?.disabled===true);
                });

                if (found) {
                    dealExpired = true;
                    this.cardTimerDisabled = true; // hide timer if deal expired
                }

               
            }

            // Get deal's baggage options - >> TODO :  get all options and then filter only the ones related to the given destination airport
            if (this.props?.baggage) {

                // Check If flight's destination airport has special pricing in baggage table =>  Keep only the relevant baggage options
                if (this.props?.baggage.find(bag=>bag.airline_code.split(',').includes(item.flight.outbound.airline.code)  && bag.destination_airport_iata==item?.flight?.outbound?.arrival_iata)) {
                    baggageArray = this.props?.baggage.filter(
                        bag=>{
                            if (bag.airline_code.split(',').includes(item.flight.outbound.airline.code) && bag.destination_airport_iata==item.flight.outbound.arrival_iata)  {
                                return item
                            }
                        }
                    )
                } else {
                    baggageArray = this.props?.baggage.filter(
                        bag=>{
                            if (bag.airline_code.split(',').includes(item.flight.outbound.airline.code) && bag.destination_airport_iata==undefined )  {
                                return item
                            }
                        }
                    )
                }
                 
                
            }

            try {
                return (
                    <div key={'cardlinewrapper'+idx}>
                        <CardLine 
                            data={item} 
                            baggage={baggageArray} 
                            slug={item.code} 
                            key={'cardline'+idx} 
                            counter={(this.counter++)} 
                            total={this.total} 
                            type={this.props.type} 
                            country={this.props?.destination?.country_name} 
                            city={this.props?.destination?.city_name}  
                            expired={dealExpired} />
                    </div>
                )
            } catch(e) {
                console.log(e)
            }
        })         

        // binding
        this.handleClick = this.handleClick.bind(this); 
        this.toggleMore = this.toggleMore.bind(this);
    }

    componentDidMount() {
        //initGA();
    }
    
    onOpenModal = () => {

        //Event("DEALS", "Help Button Clicked", window.location.pathname);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'DealHelpIconClick'
        });
        this.setState({ open: true });
    };
    
    onCloseModal = () => {
        this.setState({ 
            open: false
        });
      };

    showPromotionModal = (event, open) => {
        if (event)
            event.stopPropagation()

        //console.log(open)
        this.setState({ 
            open_promotion: open,
            open: false
         });
    };

    dateInRange(from,to,today=new Date) {
        const startDate = Date.parse(from);
        const endDate =  Date.parse(to);

        let check=Date.parse(today);
        return check>=startDate && check<=endDate;
    }

    /**
     * Function will check if it is weekend in Israel (Friday/Sat)
     * @param {*} date 
     * @returns 
     */
    isWeekend(date = new Date()) {

        //return false; // disabled

        const d = new Date();
        const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        const offset = 3; // Use UTC+3 time = Israel's time
        const nd = new Date(utc + (3600000*offset));
        
        return  nd.getDay() === 5 || nd.getDay() === 6;
    }

    render() {
        //console.log('card rendered')

        // skip this card if no flights found in this destination
        if (this.props.data==null || this.data_source==null) return (<div></div>);
        if (this.counter > 9)
            this.counter = 0;
        this.last_city = this.props.destination.city_name
        const card_items_count = this.data_source.length;


         
        
        // Skip cards without deals
        if (!this.props.data.deals || this.props.data.deals.length==0) return (<></>);

        //const item_order = this.props.data.flights[0].price;
        const best_price =  Math.ceil(this.props.data.deals[0]?.price); // sort by deal price
        this.cards_counter++;

        const city_id = this.props.destination.city_id;
        const city_name = this.props.destination.city_name;

        let destination_url = `/${city_name}/?destinations=${city_id}`
        
        if ((this.props?.stars && this.props?.stars!='') || (this.props?.stars && this.props?.stars!=''))
            destination_url = `/${city_name}/?destinations=${city_id}&stars=${this.props?.stars}&nights=${this.props?.nights}`;
            
        // if we're in a promotion page, don't redirect to the destination page
        if (this.props?.promotion!=null && this.props?.promotion!=='' && this.props?.promotion.length > 0)
            destination_url = '#';

        return (
            <div className={'card ' + (this.props.member==="0" ?'locked':'unlocked')} id={'card-'+this.props.id} style={{maxWidth:'350px', filter:'drop-shadow(2px 2px 8px #aaa)', margin:'1em'}} >
                
                <Promotion campaign='rosh25' hide={!this.dateInRange('15 Sep 2023 00:01:00 GMT+0300','17 Sep 2023 23:59:59 GMT+0300')} />
                <div className={"image " + this.props.destination?.sid}  
                    style={{height:'165px', display:'flex', justifyContent:'space-between'}}
                    onClick={e=> {this.handleClick(e, this.props?.destination?.city_id, this.props?.destination?.city_name, 'image')}}>

                    <div className="title" style={{background:'#01bddddb',padding:'0.5em 0.5em .25em', minWidth:'100px', fontSize:'.7em', height:'max-content'}} title={best_price + ' ' + this.props.destination.city_id} onClick={e=> {this.handleClick(e, this.props.destination.city_id, this.props?.destination?.city_name, 'title')}}>
                    <a style={{textDecoration:'none', color:'#fff'}} href={destination_url}>
                        <p style={{lineHeight:1, fontSize: '1.3em'}}>{this.props.destination?.country_name?`${this.props.destination?.country_name}`:``}</p>
                        <h3 style={{fontWeight:700, fontSize:'2em',margin:0}}>{this.props?.destination?.city_name}&nbsp;</h3>
                        
                    </a>
                    </div>


                    <div  style={{display:'flex', flexDirection:'row', alignSelf:'end'}} >
                        <div className="subtitle_item" style={{fontSize:'.85em'}}>
                            <div className="icons_wrapper" style={{maxHeight:'unset', padding:'.5em 1em .5em 0', background:'#1f5981', fontSize:'1em'}}>
                            <div className="icons_content" style={{}}>
                                <div className="flightIcon">
                                    <div style={{inline:`block`}} className="icon"><FontAwesomeIcon icon={faPlane} style={{transform: 'rotate(-45deg)'}} /></div>
                                    <p style={{fontSize:'.9em'}}>טיסה</p>
                                </div>

                                <div className="plusIcon" style={{fontSize:'1.5em', margin:'0 .1em 0 .3em'}}>+</div>

                                <div className="hotelIcon">
                                    <div style={{inline:`block`}} className="icon"><FontAwesomeIcon icon={faBed} /></div>
                                    <p style={{fontSize:'.9em'}}>מלון</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div> 


                </div>
                

            
                <div className={"lines lines-"+this.props?.destination?.city_name} key={"line"+this.props?.destination?.city_name}>
                    {this.cardData}
                </div>
                <div style={{lineHeight:'2.5rem', display:'flex', justifyContent:'center', alignItems:'center', background:'#1e5881db'}} className={'showMore ' + (card_items_count<3&&1==2?'hide':'')} onClick={e=> {this.handleClick(e, this.props.destination.city_id, this.props?.destination?.city_name,'more')}} items_count={card_items_count}>
                    <div className='caption'>עוד דילים ל{this.props?.destination?.city_name} </div>
                    <div className='icon' style={{margin:'0 .5em 0 0'}}>
                        <FontAwesomeIcon icon={faAngleDoubleDown}  />
                    </div>
                </div>

            </div>
        )
    
    }

    toggleMore = evt => {
        //Event("DEALS", "Show more button clicked", this.state.city);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ShowMoreDealsLink',
            page: 'homepage',
            'pageUrl': 'https://www.afnu.co.il/',
	        //'pageTitle' : title
        });
        //evt.preventDefault();       
        //const city = this.state.city;

        this.setState(prevState => ({
            //'text':     (!prevState.showMore ? "הסתר" : " תאריכים נוספים" ),
            'card_deals': prevState.showMore?this.data_source.slice(0,3):this.data_source,
            'fa_arrow': (!prevState.showMore ? "fa fa-sort-up" : "fa fa-sort-down" ),
            'showMore' : !prevState.showMore,
            'open': false
        }));


        console.log(this.props.data.city);
        // Somewhere else, even another file
        scroller.scrollTo('myScrollToElement', {
            duration: 1500,
            delay: 100,
            smooth: true,
            containerId: this.state.city,
            offset: 50, // Scrolls to element + 50 pixels down the page
        
        })

        //document.getElementById(this.state.city).style.backgroundColor = 'red';//location.hash = "#" + this.props.data.city + "-line-5";
    }

    
    
    handleClick(event, city_id, city_name, click_location) {
        
        event.stopPropagation()

        if (this.props?.promotion=='max199') return
                
        if (event.target.className.indexOf("lock_trigger")>=0) {
            //Event("DEALS", "Member Signup Button", this.props.data.deal_id);    
            window.location = "members/signup";
            return;
        } else {

            let prefix = `/${city_name}/?`;
            let destination_param = `destinations=${city_id}`;
            //let url = `/${city_name}/?destinations=${city_id}`;

            //console.log(this.props)
            //console.log(this.props.months.length)
            //return
            
            // &min_stars=4&max_stars=5&min_nights=5&max_nights=7&when=all&when_caption=%D7%91%D7%AA%D7%90%D7%A8%D7%99%D7%9B%D7%99%D7%9D%20%D7%94%D7%9B%D7%99%20%D7%96%D7%95%D7%9C%D7%99%D7%9D&from=&to=&limit=10&destination=59&destination_name=%D7%90%D7%A0%D7%98%D7%90%D7%9C%D7%99%D7%94&sortby=price-asc&months=&dates=&QS=null
            let params = ``;
            
            if (this.props?.stars && this.props?.stars!='') {
                params += `&stars=${this.props?.stars}`;
            }

            if (this.props?.nights && this.props?.nights!='') {
                params += `&nights=${this.props?.nights}`;
            }

            if (this.props?.meals && this.props?.meals!='') {
                params += `&meals=${this.props?.meals}`;
            }

            /*if (this.props?.months && this.props?.months!='') {
                params += `&months=${this.props?.months}`;
            }*/

            if (this.props?.adults && this.props?.adults!='') {
                params += `&adults=${this.props?.adults}`;
            }

            
            if (this.props?.teens && this.props?.teens!='') {
                params += `&teens=${this.props?.teens}`;
            }

            if (this.props?.children && this.props?.children!='') {
                params += `&children=${this.props?.children}`;
            }

            if (this.props?.infants && this.props?.infants!='') {
                params += `&infants=${this.props?.infants}`;
            }

            if (this.props?.dow && this.props?.dow!='') {
                params += `&dow=${this.props?.dow}`;
            }

            if ((this.props?.from_date!='' && this.props?.to_date!='')) {
                prefix = `/${city_name}/${this.props.from_date}/?`;
                params += `&from_date=${this.props.from_date}&to_date=${this.props.to_date}&dates_type=${this.props.dates_type}&month=none`
            }

            if (this.props.months && this.props.months.length==1) {
                prefix = `/${city_name}/${this.props.months[0]}/?`;
                params += `&month=${this.props.months.toString()}`
            }

            let url = prefix + destination_param + params;
            // add GTM custom event
            if (window['google_tag_manager']) {
                const event_name = 'GoToDestinationPage';
                console.log('GTM event: ' + event_name)

                event.preventDefault();

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: event_name,
                    eventTimeout: 2000,
                    city_name: city_name,
                    city_id: city_id,
                    target_url: url,
                    pageUrl: window.location,
                    pageTitle : window.title,
                    source: click_location,
                    eventCallback: function() {
                        window.location = url;
                    }
                });
            } else {
                window.location.href=url;
            }
        }
    }
    
}

//ReactDOM.render(<Card />, document.getElementById('root'));

export default Card
