import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";
import {PageView, initGA} from '../../features/Tracking';

class Terms extends React.Component {

    componentDidMount() {
        initGA();
        PageView();
        document.title = 'הצטרפות למועדון של עפנו עם אמאלה';  
    }

    render() {
        window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            event: 'Pageview',
            'pageUrl': window.location,
            'pageTitle' : window.title
            });
            
        return(
            <div className="App Contact"> 
                <Header hide="false" hideSearch={true} />
                    <main>
                    <img style={{maxWidth:'300px', display:'block',  margin:'0 auto'}} width='60%' src='/images/partners/imaclub/imaclub-logo.png'></img>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdWh8Rug_An2gkaCU3kdv4-TqN2QPvv8AaReS8Y6FIw_FEOoQ/viewform?embedded=true" width="1024" height="1250" frameborder="0" marginheight="0" marginwidth="0">בטעינה…</iframe>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;