import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

export default function GMap(props) {
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDuXlG_xFtZH5xAhaQ7m7bH_VZ48Z_8NIU",
    language: 'he',

  })

  /*const center = {
    lat: -3.745,
    lng: -38.523
  };*/
  const center = {
    lat: props.lat*1,
    lng: props.lng*1
  };

  const position = {
    lat: props.lat*1,
    lng: props.lng*1
  }

  const [setMap] = React.useState(null)

  const onLoad = marker => {
    console.log('marker: ', marker)
  }

  /*const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])*/

  const onUnmount = React.useCallback(function callback(map) {
    //setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
         <Marker
            onLoad={onLoad}
            position={position}
        />  
      </GoogleMap>
  ) : <></>
}
