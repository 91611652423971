import React from "react"
import configData from '../../../config/config.json';
//import Rating from "react-rating" // https://www.npmjs.com/package/react-rating
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faMoon, faCalendar, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Cookies from 'universal-cookie';
import CardFlights from "./_CardFlights";
import CardHotel from "./_CardHotel";
import BaggageIcons from '../../../features/Baggage';
import moment from "moment";
//import './CardLine.css';
//import Deal from "./Deal"


moment.locale('he', {
    months: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_'),
    monthsShort: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_')
  }); 

//var lastObj = null;

const API_ENDPOINT = configData.API_ENDPOINT;

class CardLine extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            'active' : this.props?.active,
            'open': false,
			'open_covid19': false,
            'data': this.props.data,
            'property_photo': null
        }
        this.handleClick = this.handleClick.bind(this); 
        this.handleMoreDetailsBtn = this.handleMoreDetailsBtn.bind(this); 
        this.counter = 0;

        
    }

    
    componentDidMount() {
       // initGA();
    }

    onOpenModal = () => {
        //this.setState((prevState) => ({ 'open': true, 'active': prevState.active}));
        this.setState({open: true})
    };

    onCloseModal = () => {
        //this.setState((prevState) => ({ 'open': false, 'active': prevState.active}));
        this.setState({open: false, open_covid19: false})
      };

    render() {
      //  if (this.props.data.hotels == null ) return (<div>NO HOTELS {this.state.to_agoda_city_id}</div>)

        console.log('card line rendered: ' + this.state.active )

        const { open, open_covid19 } = this.state;
        //console.log(this.props.data)
       
        //const deal_price = Math.ceil(this.props.data.deal_price);
        //const departure_date = formatDate(this.props.data.flight.outbound.departure_date);
        //const return_date = formatDate(this.props.data.flight.inbound.arrival_date);
        const departure_date = formatDate(this.props.data.flight.outbound.departure_date)//formatDate(this.props.data.departure.date);
        const return_date = formatDate(this.props.data.flight.inbound.departure_date) //formatDate(this.props.data.return.date);
        const timeDiff = Math.abs(new Date(this.props.data.return.date).getTime() - new Date(this.props.data.departure.date).getTime());
        const nights =  Math.ceil(timeDiff / (1000 * 3600 * 24)); 

        const item_uri = "/deal/" + this.props.data.uid;
        const weekendClass = (this.props.data.weekend_il)?'show':'hide';
        //const holidayClass = (this.props.data.holidays.length>0)?'show':'hide';

    
//      console.log(this.props.data.hotels[0].deal_price)
        let duration_departure, duration_return, out_departure_time,out_arrival_time,in_departure_time,in_arrival_time,to_airline, return_airline=null;

        out_arrival_time = this.getFlightTime(this.props.data.flight.outbound.arrival_date);
        out_departure_time = this.getFlightTime(this.props.data.flight.outbound.departure_date);
        duration_departure = this.getTextualDuration(this.props.data.flight.outbound.duration);
        to_airline = this.props.data.flight.outbound.airline.code;
        //let to_airline_icon = this.props.data.flight.outbound.airline.icon;

        in_departure_time = this.getFlightTime(this.props.data.flight.inbound.departure_date);
        in_arrival_time = this.getFlightTime(this.props.data.flight.inbound.arrival_date);
        duration_return = this.getTextualDuration(this.props.data.flight.inbound.duration);
        return_airline = this.props.data.flight.inbound.airline.code;
        //let return_airline_icon = this.props.data.flight.inbound.airline.icon;

        const pax_count = (this.props.adults?this.props.adults*1:0) + (this.props.children?this.props.children*1:0) + (this.props.infants?this.props.infants*1:0);
        const pricePP = Math.ceil(this.props.data.price/pax_count)
        return (           
            <div  className={"lineWrapper " +  (this.props.counter>this.props.show?' hide ':'')  +  (this.state.active?'active':'')} id={ this.props.stars + "-line-" + this.props.counter  } key={'month'+(this.counter++)} title={this.props.data.uid} href={item_uri} style={{direction:'rtl', order: Math.ceil(pricePP/2)}} >
                <div onClick={(e)=>this.handleClick(e)}>

                    <div style={{padding:'.75em .75em .75em 0'}} className={"line line-"+ (this.props.counter+1) + " " + (( (this.props.total >= 3 && this.props.counter<2) || (this.props.total<3 && this.props.counter<this.props.total-1) ) && this.props.member==="0" ?'unlocked':'unlocked')}>
                        <span className="overlay lock_trigger"></span>
                        <div className="dates" style={{fontSize:'1em', margin:`0`, lineHeight:0}}>
                            <h4 style={{fontWeight:800, color:'#333',fontSize:'1.1em'}}>טיסה + מלון</h4>
                            <div className="symbols"  style={{fontSize:'1em', color:'#888'}}>
                                <div style={{fontWeight:'600', color: '#555', fontSize:'.9em'}}>
                                    <FontAwesomeIcon icon={faMoon} style={{display:'inline-block', margin:'0 0 0 0.3em', fontSize:'0.8em'}} /> 
                                    <span style={{ fontWeight:700}}>{nights} לילות </span>
                                    <span style={{color:'#777'}}>({this.getDays( this.props.data.departure.dow, this.props.data.return.dow)})</span>
                                </div>
                            </div>
                            <p className="range" style={{color:'#555',fontSize: '.9em', fontWeight:'600'}}>
                                <FontAwesomeIcon icon={faCalendar} style={{display:'inline-block', margin:'0 0 0 0.3em', fontSize:'0.9em',}} />
                                {formatDate(this.props.data.flight.inbound.arrival_date)} - {formatDate(this.props.data.flight.outbound.departure_date)}</p>
                        </div>
                        
                        <div className="price" style={{fontSize:'2.23em', color: '#444',display:'flex',flexDirection:'column',justifyContent:'center', padding:0, margin:0}}>
                            <p className="amount" ><span className="from">מ-</span>{this.getCurrencySymbol( this.props.data.currency )}{pricePP}</p>
                            <p className="amountCaption" style={{fontSize:'0.3em', borderBottom:'none',paddingBottom:0}}>לאדם בהרכב זוגי</p>
                        </div>
                    </div>

                </div>
                <div  key={'deal'+(this.counter++)}  className={"dealDetails destinationDealDetails " + (this.state.active?'active':'')} id={this.props.data.flight_uid} >  
                    <div style={{display:'flex', margin:'0 .25em', justifyContent:'space-between', border:'1px solid #bbb'}} onClick={(e)=>this.handleMoreDetailsBtn(e, '/deal/' + this.props.data.uid, 'more-details-flight', this.props.data.uid)}>
                        <CardFlights key={'cardflight'+(this.counter++)} data={this.props.data}></CardFlights>
                        <div className="title sideTab"  style={{background:'#1e5881', padding:0}}>טיסה</div>
                    </div>
                                   
                    <div  style={{display:'flex', margin:'0 .25em .25em', justifyContent:'space-between', borderBottom:'1px solid #bbb', borderLeft:'1px solid #bbb', borderRight:'1px solid #bbb'}} onClick={(e)=>this.handleMoreDetailsBtn(e, '/deal/' + this.props.data.uid, 'more-details-hotel', this.props.data.uid)}>
                        <CardHotel key={'cardhotel'+(this.counter++)} data={this.props.data} photo={this.state.property_photo}></CardHotel>
                        <div className="title sideTab" style={{background:'#02badb', padding:0}}>מלון</div>
                    </div>          

                    <BaggageIcons baggage={this.props?.baggage} ></BaggageIcons>
                    
                    {/*<hr style={{margin:'0 1em'}} />*/}
                    <div style={{padding:'0.75em .5em 1em'}}>                       
                        <div style={{fontSize:'0.8em'}}>
                            <button style={{display:'flex', justifyContent:'center', alignItems:'center',width: '100%', padding:'0.8em 0.2em', borderRadius:'5px', backgroundColor: '#fed102', fontSize:'1.2em', border:'none', fontWeight:'700',color:'#000'}} onClick={(e)=>this.handleMoreDetailsBtn(e, '/deal/' + this.props.data.uid, 'more-details', this.props.data.uid)}>פרטים נוספים
                                <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ margin:'0 .5em 0 0'}} /> 
                            </button>
                        </div>
                    </div>

              
                </div>
                

            </div>

        )
    }

    isFriend = async(event, url, source, deal_id) => {
        window.location = url
    }

    getStarsImage(num) {
        let output = "";

        for (let i=0;i<num;i++) {
            output+="★";
        }

        return output;
    }

    getDays(day1, day2) {
    
        let days = "";
        switch(day1) {
            case 0: days="ראשון"; break;
            case 1: days="שני"; break;
            case 2: days="שלישי"; break;
            case 3: days="רביעי";break;
            case 4: days="חמישי"; break;
            case 5: days="שישי"; break;
            case 6: days="שבת"; break;
            default: break;
        }
    
        days += " - ";
    
        switch(day2) {
            case 0: days+="ראשון"; break;
            case 1: days+="שני"; break;
            case 2: days+="שלישי"; break;
            case 3: days+="רביעי";break;
            case 4: days+="חמישי"; break;
            case 5: days+="שישי"; break;
            case 6: days+="שבת"; break;
            default: break;
        }
    
        return days;
    }

    /*getStarsImage(num) {
        switch(num*1) {
            case 1: return '&#xf006;';
            case 2: return '&#xf006;&#xf006;';
            case 3: return '&#xf006;&#xf006;&#xf006;';
            case 4: return '&#xf006;&#xf006;&#xf006;&#xf006;';
            case 5: return '&#xf006;&#xf006;&#xf006;&#xf006;&#xf006;';
            default: return '';
        }
    }*/

    getCurrencySymbol(cur) {
        let currency_symbol;

        switch( cur ) {
            case 'USD': currency_symbol = '$';break;
            case 'EUR': currency_symbol = 'EURO';break;
            case 'ILS': currency_symbol = 'NIS';break;
            default: currency_symbol = '$';
        }

        return currency_symbol;
    }

    lameLogin(uid, pw) {
        const { createHash } = require('crypto');
        const hashedPw = createHash('sha256').update(pw).digest('hex');
        /*if (hashedPw=='432164f22b5c124cd4971bee34cbd3e4a1c392ee46f4ed5382359ca55f53ed4a' || hashedPw=='879744f88f74c555a26252f1bba694a6d890d4bd844b9a7f7f85e12b8004dbef') {
            const cookies = new Cookies();
            cookies.set('logged',1)
            window.location= '/deal/' + this.props.data.uid;
        }*/
    }

    GetMealPlanCode(code) {
        let local_meal_plan_code='RO';
        
        switch(code) {
            case 'SA'||'SP'||'SC': local_meal_plan_code='RO';break; // Accommodation only
            case 'HD': local_meal_plan_code='BB';break;             // B&B 
            case 'HM'||'MB'||'MP': local_meal_plan_code='HB';break; // HAlf Board
            case 'PC'||'PV': local_meal_plan_code='FB';break;       // Full board
            case 'TI'||'TP': local_meal_plan_code='AI';break;       // All Inclusive
            default: local_meal_plan_code='RO'
        }

        return local_meal_plan_code;
    }

    /**
     * Calculate the number of days / weeks / months between the departure date & today
     * @param {*} departure_date 
     */
    GetTimeToDeparture(departure_date) {
        try {
            const today = new Date();
            const [datestr, timestr] = (departure_date+'').split("T");
            const [year, month, day] = datestr.split("-");
            const departureDate = new Date(+year, +month - 1, +day);

            const difference = departureDate.getTime() - today.getTime();

            const daysToDeparture = Math.ceil(difference / (1000 * 60 * 60 * 24));
            
            switch (true) {
                case daysToDeparture <= 2: return '1-2 days';break;
                case daysToDeparture <= 4: return '3-4 days';break;
                case daysToDeparture <= 7: return '5-7 days';break;
                case daysToDeparture <= 10: return '8-10 days';break;
                case daysToDeparture <= 13: return '11-13 days';break;
                case daysToDeparture <= 21: return '2 weeks';break;
                case daysToDeparture <= 28: return '3 weeks';break;
                case daysToDeparture <= 35: return '4 weeks';break;
                case daysToDeparture <= 42: return '5 weeks';break;
                case daysToDeparture <= 49: return '6 weeks';break;
                case daysToDeparture <= 56: return '7 weeks';break;
                case daysToDeparture <= 63: return '8 weeks';break;
                case daysToDeparture <= 70: return '9 weeks';break;
                case daysToDeparture <= 100: return '2 months';break;
                case daysToDeparture <= 130: return '3 months';break;
                case daysToDeparture <= 160: return '4 months';break;
                case daysToDeparture <= 190: return '5 months';break;
                case daysToDeparture <= 220: return '6 months';break;
                case daysToDeparture <= 250: return '7 months';break;
                case daysToDeparture <= 280: return '8 months';break;
                case daysToDeparture <= 310: return '9 months';break;
                case daysToDeparture > 310: return '10+ months';break;
                default: return '';
            }
        } catch(e) {
            return 'ERROR'
        }    
    }

    handleClick(event) {
        
        // show filters 
        //document.getElementsByClassName('filters_toggle')[0].className='filters_toggle visible'
        //document.getElementsByClassName('filters')[0].className='filters visible'

        event.stopPropagation()
      
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  
        
       
        // Load the property details (only once)
        if (!this.state.active) {
            // Open
            window.dataLayer.push({
                event: "select_item",
                ecommerce: {
                  item_list_id: 'deals',
                  item_list_name: 'Deals',
                  currency: this.props?.data?.currency,
                  value: this.props?.data?.price,
                  items: [
                  {
                    item_id: this.props.data.uid,
                    item_name: this.props.data?.code,
                    index: 0,
                    item_brand:  this.props.data.destinations?.uid, ////this.props.city_name,
                    item_category:  "adults:2,children:0,infants:0,rooms:1",
                    item_category2: "stars: " + this.props.data.property?.stars + " meals: " + this.GetMealPlanCode(this.props.data.property.meal_code),
                    item_category3: "nights: " + this.props.data?.nights,
                    item_category4: "departure: " + this.GetTimeToDeparture(this.props.data?.departure?.date),
                    item_category5: "month: "+ this.props.data.departure?.date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
                    item_list_id:   "deals",
                    item_list_name: "Deals",
                    item_variant:   "standard",
                    location_id: this.props.data.destination.city.id,
                    price: this.props?.data?.price,
                    quantity: 1
                  }
                  ]
                }
              });
        } 
        this.setState(prevState=> ({
            active: !prevState.active
        }));

    }

    handleMoreDetailsBtn(event, url, source, deal_id) {
        event.target.style.backgroundColor = '#aaa'
        //const cookies = new Cookies();
        //if (cookies.get('logged')!=1) {
		//    this.setState({ open_covid19: true });
            //Event("DEALS", source + " button clicked", deal_id)
        //} else {
        //
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  
        window.dataLayer.push({
            event: 'OpenDealDetailsPage',
            value: this.props?.data?.price,
            uid: this.props.data.uid,
            currency: this.props.data.currency,
            departure: this.props.data.departure.date,
            destination: this.props.data.destination.city.id,
            stars: this.props.data.property.stars,
            weekend: this.props.data.weekend_il,
            holidays: this.props.data.holidays,
            vacations: this.props.data.vacations,
            nights: this.props.data.nights,
            ecommerce: {
                currencyCode: this.props.data.currency,
                add: {
                    actionField: {
                        list: 'destination-deal-preview'
                    },
                    products: [{
                        id: this.props.data.uid,
                        name: this.props.data?.code,
                        category: this.props?.country_name+'/'+this.props.city_name+ '/' + this.props.month + '/' + this.props.stars,    
                        brand: 'afnu',
                        quantity: 1,
                        price: this.props?.data?.price,
                    }]

                }
            }
        });
       
        window.dataLayer.push({
            event: "view_item",
            ecommerce: {
              currency: this.props?.data?.currency,
              value: this.props?.data?.price,
              items: [
              {
                item_id: this.props.data.uid,
                item_name: this.props.data?.code,
                index: 0,
                item_brand: this.state?.destination_name_en, //this.props.city_name,
                item_category:  "adults:2,children:0,infants:0,rooms:1",
                item_category2: "stars: " + this.props.data.property?.stars+" meals: " + this.GetMealPlanCode(this.props.data.property.meal_code),
                item_category3: "nights: " + this.props.data?.nights,
                item_category4: "departure: " + this.GetTimeToDeparture(this.props.data.departure?.date),
                item_category5: "month: "+ this.props.data.departure?.date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
                //item_list_id: "related_products",
                //item_list_name: "Related Products",
                item_variant: "standard",
                location_id: this.props.data.destination.city.id,
                price: this.props?.data?.price,
                quantity: 1
              }
              ]
            },
            eventCallback: function() {
                window.location = url;
              }
          });

        setTimeout(function() {window.location = url}, 500); 
        
        //}
    }
    /*
    handleBookingBtn(event) {
        window.open("/deal/" + this.props.data.deal_id);
        window.open(this.props.data.flight_partner_order_url);
        window.open(this.props.data.property_landing_url);
    }
*/
    getTextualDuration(duration) {
        return (duration/60/60+"" ).split('.')[0]+ ' שעות ו-' + Math.round((duration/60/60)%1*60) + " דק'";
    }

    
    getFlightTime(ftime) {
        if (!ftime) return;

        return moment.parseZone(ftime).format("HH:mm")

        //const d = new Date(ftime);
        //const t = ftime.split(/[- :]/);
        const t = ftime.split(/[-T:]/);

        // Apply each element to the Date function
        var date_struct = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5].split('+')[0]);
        //console.log(date_struct)
        var d = new Date(date_struct);
        
        return ("0" + d.getHours()).slice(-2) + ":" +  ("0"+d.getMinutes()).slice(-2)
    }

    
}

function formatDate(date) {
    return moment.parseZone(date).format("DD/MM/YYYY");
    // Remove timezone data
    if (date.indexOf('+') > 0)
        date = date.slice(0,date.indexOf('+'))

    const d = new Date(date);


    return ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" +
    d.getFullYear();
}


export default CardLine