import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onOk, openPriceChangeUp } = props;

    const handleOk = () => {
        onOk();
    };

    const showMoreDeals = () => {
        const cityID = openPriceChangeUp?.city?.id;
        const cityUID = openPriceChangeUp?.city?.uid;

        window.location.href = '/' + cityUID + '/?destinations=' +cityID;
    }

    const price = openPriceChangeUp?.price;

    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openPriceChangeUp?.show} keepMounted>
            <div className='ugly modalBody p-4'>

                <Grid container id="PriceChangeUpModal" className="UserNotificationModal">
                    <Grid item className='text-center' xs={12}>
                        <div className="heading">
                            <img src="/images/modals/price-changed-heading.png" alt="עדכון מחיר" />
                            <h3 style={{color:'#666'}}>המחיר העדכני בדיל זה הוא {price} לנוסע</h3>
                        </div>
                    </Grid>
                    <Grid item className='mb-2 text-center heading' xs={12} >
                        <div className="content">
                            <h4>כיצד תרצו להמשיך?</h4>
                        </div>
                    </Grid>
                    <Grid item  className='text-center mb-2' xs={12}>
                        <Button onClick={handleOk} width='60%' variant='contained' color='primary' className='m-1' style={{backgroundColor:'#71a041', minWidth:'60%'}} title="המשך בביצוע ההזמנה">המשך בהזמנה</Button>
                        <Button onClick={showMoreDeals} style={{minWidth:'60%'}} variant='contained' color='inherit' className='m-1' title="הצג דילים נוספים">הצג דילים נוספים</Button>
                    </Grid>
                </Grid>
            </div>

        </Dialog>
    );
}
