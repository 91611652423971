import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onOk, openPriceChangeDown ,priceChangeMessage} = props;

    const handleOk = () => {
        onOk();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openPriceChangeDown} keepMounted>
            <div className='ugly modalBody p-4'>

                <Grid container id="PriceChangeDownModal" className="UserNotificationModal">
                    <Grid item className='text-center' xs={12}>
                        <div className="heading">
                            <img src="/images/modals/price-decreased.jpg" alt="איזה כיף, המחיר ירד!" /><h3 style={{color:'#339f7d',margin:'0'}}>איזה יופי, המחיר ירד!</h3>
                        </div>
                    </Grid>
                    <Grid item className='mb-4 text-center heading' xs={12} >
                        <div className="content">
                            <h3>מחיר החופשה התעדכן<br />הסכום העדכני לנוסע הינו {priceChangeMessage}</h3>
                        </div>
                    </Grid>
                    <Grid item  className='text-center' xs={12}><Button onClick={handleOk} variant='contained' color='secondary'>אישור</Button></Grid>
                </Grid>
            </div>

        </Dialog>
    );
}
