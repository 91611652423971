import React from "react";
import CardLine from "./_OBSOLETE/_CardLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

// eslint-disable-next-line
import { animateScroll as scroll, scroller } from 'react-scroll' 

class YearCard extends React.Component {
    
    constructor (props) {
        super(props)
        
       
        // skip this card if no flights found in this destination
        if (this.props.data == null || (this.props.data.deals==null))
            return;

               // never show 2 deals with the same checkin date & same flight (meaning we can show the same date if the flight is in different hours)
        //let seen = {};
        this.data_source = this.props.data.deals
            /*.filter(deal=>{
                 return seen.hasOwnProperty(deal.departure.date + '|' + deal.return.date + '|' + deal.flight.invetory_uid) ? false : (seen[deal.departure.date + '|' + deal.return.date + '|' + deal.flight.invetory_uid] = true);
        });*/
        
        this.state = {
            'active': false,
            'text': 'תאריכים נוספים',
            'showMore': 0,
            'fa_arrow': 'fa fa-sort-down',
            'city': this.props.data.city,
            'card_deals': this.data_source.slice(0,3),
            'open': false
        }
        this.counter = 0;
        this.cards_counter = 0;

        this.total = this.data_source.length;

        //bind
        this.handleClick = this.handleClick.bind(this); 
        this.handleMoreDetailsBtn = this.handleMoreDetailsBtn.bind(this); 

        
    }

    componentDidMount() {
        //initGA();
    }

    handleMoreDetailsBtn = (e, month) => {
        const city_name =  this.props?.destination?.city_name;
        const city_id = this.props?.destination?.city_id;
        const city_uid = this.props.destination?.sid;

        console.log(this.props)
        const month_id = this.props.data?.id;
        const month_name = this.props.data?.name_he;
        const year = this.props.data?.year;
        //const month_name = this.props?.mo

        //window.location = '/' + city_name + '/דילים ב' + month_name + ' ' + year + '/?destination=' + city_id + "&month=" + year + '-' + ((month_id*1)+1)
        if ((this.props?.stars && this.props.stars!='') || (this.props?.nights && this.props.nights!='' ) || (this.props?.meals && this.props.meals!='') || (this.props?.dow && this.props.dow!='') || this.props?.waterpark || this.props?.luxury || this.props?.casino || this.props?.near_chabad || this.props?.spa || this.props?.airlines)
            window.location = `/${city_name}/${month_name}/?destinations=${city_id}&month=${year}-${(month_id*1)+1}&stars=${this.props?.stars}&nights=${this.props?.nights}&meals=${this.props?.meals}&dow=${this.props?.dow}` + this.props?.waterpark?'&waterpark=1':'' + this.props?.luxury?'&luxury=1':'' + this.props?.casino?'&casino=1':'' + this.props?.near_chabad?'&chabad=1':''  + this.props?.spa?'&spa=1':'' + this.props?.airlines?`&airlines=${this.props.airlines}`:''
        else 
            window.location = `/${city_name}/${month_name}/?destinations=${city_id}&month=${year}-${(month_id*1)+1}`
    }
    onOpenModal = () => {

        //Event("DEALS", "Help Button Clicked", window.location.pathname);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'DealHelpIconClick'
        });
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
      };

    handleClick(event) {
        return true
        // show filters 
        //document.getElementsByClassName('filters_toggle')[0].className='filters_toggle visible'
        //document.getElementsByClassName('filters')[0].className='filters visible'

        event.stopPropagation()
        
        console.log(event.target.className)
    
    

        // Fetch deal details
        this.setState(prevState=> ({
            active: !prevState.active
        }));

        window.dataLayer = window.dataLayer || [];
        const event_name = 'ToggleMonth';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            page: 'destination',
            event: event_name,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });

       
    }

    render() {
        //const { open } = this.state;

        //console.log('card rendered')
        let counter = 0;

        // skip this card if no flights found in this destination
        if (this.props.data==null || this.data_source==null) return (<div></div>);
        if (this.counter > 9)
            this.counter = 0;
        

            //console.log(this.props.data)
        this.last_city = this.props.data.deals[0].destination.city.name;
        this.last_month = this.props.data.name_he;
        
        //const card_items_count = this.data_source.length;
        //console.log(this.state)
        let cardData = this.state.card_deals.map(item => 
            <CardLine data={item} slug={item.code} key={'cardline'+(this.counter++)} counter={(counter++)} total={this.total} type={this.props.type} country_name={this.props?.destination?.country_name} city_name={this.props?.destination?.city_name} month={this.last_month} adults={this.props?.adults} teens={this.props?.teens} children={this.props?.children} infants={this.props?.infants} waterpark={this.props?.waterpark}  luxury={this.props?.luxury} casino={this.props?.casino} near_chabad={this.props?.near_chabad} spa={this.props?.spa} />);
        
        if (this.props.type==='months') {
            cardData = this.props.data.deals
                //.filter(deal => deal.property.stars == this.props.stars)
                    .map(
                        deal =>
                            <CardLine data={deal} slug={this.props.data.code} key={(this.counter++)} counter={(counter++)} total={this.total} type={this.props.type}  country_name={this.props?.destination?.country_name} city_name={this.props?.destination?.city_name}  month={this.last_month}  adults={this.props?.adults} teens={this.props?.teens} children={this.props?.children} infants={this.props?.infants} waterpark={this.props?.waterpark} luxury={this.props?.luxury} casino={this.props?.casino} near_chabad={this.props?.near_chabad} spa={this.props?.spa} />
                )
        }

        //const item_order = this.props.data.flights[0].price;
        const qsAdults = this.props.adults?this.props.adults*1:0;
        const qsTeens = this.props?.teens?this.props?.teens*1:0;
        const qsChildren = this.props?.children?this.props?.children*1:0;
        const qsInfants = this.props?.infants?this.props?.infants*1:0;
        const query_string_pax_count = qsAdults + qsTeens + qsChildren + qsInfants;

        const best_price =  Math.ceil(this.props.data.deals[0].price/this.props.data.deals[0].passengers_count); // sort by deal price
        this.cards_counter++;

        /*const from_date = '15.05';
        const to_date = '18.05'
        const nights = 3;
        const price = '$189';*/
        //const destination_name = this.props.data.destination.city_name
        const month_name = this.props.data.name_he;
        const month_id = this.props.data.id;
        const year = this.props.data.year;
        /*const month_key = this.props.data.month;
        const month_id = this.props.data.id;
        const city_id = this.props.data.deals[0].destination.city.id; 
        const city_uid =this.props.data.deals[0].destination.city.id;
        */
        const city_id = this.props?.destination?.city_id;
        const city_name = this.props?.destination?.city_name;
        const city_uid = this.props?.destination?.sid;
        //const city_name_he = this.props.data.deals[0].destination.city.name; 
        const backgroundImage = `/images/destinations/${this.props?.destination?.city_uid}.jpg`
        //const backgroundImage = `../images/destinations/paphos.jpg` //this.props.data.destination.city_id; //encodeURI(this.props.data.image);

        
        let more_deals_of_month_url = `/${city_name}/${month_name}/?destinations=${city_id}&month=${year}-${(month_id*1)+1}`;
        //if ((this.props?.stars && this.props.stars!='') || (this.props?.nights && this.props.nights!='') || this.props?.children > 0 || this.props?.infants > 0 || (this.props?.dow && this.props.dow!='')  || (this.props?.meals && this.props.meals!='')) {
            more_deals_of_month_url+= (this.props?.stars)?'&stars='+this.props?.stars:'';
            more_deals_of_month_url+= (this.props?.nights)?'&nights='+this.props?.nights:'';
            more_deals_of_month_url+= (this.props?.waterpark)?'&waterpark=1':'';
            more_deals_of_month_url+= (this.props?.luxury)?'&luxury=1':'';
            more_deals_of_month_url+= (this.props?.casino)?'&casino=1':'';
            more_deals_of_month_url+= (this.props?.near_chabad)?'&chabad=1':'';
            more_deals_of_month_url+= (this.props?.spa)?'&spa=1':'';
            more_deals_of_month_url+= (this.props?.meals)?'&meals='+this.props?.meals:'';
            more_deals_of_month_url+= (this.props?.adults)?'&adults='+this.props?.adults:'';
            more_deals_of_month_url+= (this.props?.teens)?'&teens='+this.props?.teens:'';
            more_deals_of_month_url+= (this.props?.children)?'&children='+this.props?.children:'';
            more_deals_of_month_url+= (this.props?.infants)?'&infants='+this.props?.infants:'';
            more_deals_of_month_url+= (this.props?.dow)?'&dow='+this.props?.dow:'';
            more_deals_of_month_url+= (this.props?.airlines)?'&airlines='+this.props?.airlines:'';
        //}

        return (
            <a style={{fontWeight: 800, color: '#01bddd',fontSize:'1.15em', textDecoration:'none'}} href={more_deals_of_month_url} title={`דילים ל${city_name} ב${month_name}`}>
             <div className={'card month scrollItem ' + (this.props.member==="0" ?'locked':'unlocked')} id={'scroll-'+this.props.stars+'-card-'+this.props.id}  onClick={(e)=>this.handleClick(e)} 
                style={{order: this.props.data.month, maxWidth:'960px', minWidth:'75%', marginTop:'.5em'}}>
                <div className="monthHeader">
                    <div className="right">
                        <div className="line1">
                            <h6>{month_name}</h6>
                        </div>
                        <div className="line2">
                            {year}
                        </div>
                    </div>
                    <div className="left">
                        <div className={'line1 ' + (this.state.active?'hidden':'')}>
                            <h6>טיסה+מלון</h6>
                        </div>
                        <div className={'line2 ' + (this.state.active?'hidden':'')}>החל מ-
                            <div className="price">${best_price}</div> לנוסע
                        </div>
                    </div>
                    <div className="open">
                        <div className="icon" title={`הצג דילים בחודש ${month_name}`}>
                            {this.state.active?(
                                <FontAwesomeIcon icon={faAngleUp} style={{margin:0,padding:0}} />
                            ):(
                                <FontAwesomeIcon icon={faAngleDown} style={{margin:0,padding:0}}  title={`הצג דילים בחודש ${month_name}`}  alt={`הצג דילים בחודש ${month_name}`} />
                            )}
                        </div>
                    </div>
                </div>

                <div className={"lines " + (this.state.active?'active':'')  + " lines-"+this.props?.destination?.sid} style={{display:'none'}} >
                   
                </div>
                
                <div className={"more-results " + (this.state.active?'active':'') } style={{padding:'0.5em 2em', backgroundColor:'#eee', display:'none'}}>
                    
                    <div style={{display:'flex', cursor:'pointer', justifyContent: 'center', alignItems: 'center', fontSize: '0.85em',minWidth:'205px'}}  onClick={(e)=>this.handleMoreDetailsBtn(e)}> 
                    <a style={{fontWeight: 800, color: '#01bddd',fontSize:'1.15em'}} href={more_deals_of_month_url} title={`דילים ל${city_name} ב${month_name}`}>דילים נוספים ב{month_name} </a>
                        <div><FontAwesomeIcon icon={faAngleDoubleDown} style={{margin:'0 .5em',padding:0}} /></div>
                    
                    </div>


                </div>

            </div>
            </a>
        )
    
    }

    toggleMore = evt => {
        //Event("DEALS", "Show more button clicked", this.state.city);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ShowMoreDealsLink',
            page: 'destination-year-card',
            'pageUrl': 'https://www.afnu.co.il/',
	        //'pageTitle' : title
        });
        //evt.preventDefault();       
        //const city = this.state.city;

        this.setState(prevState => ({
            'text':     (!prevState.showMore ? "הסתר" : " תאריכים נוספים" ),
            'card_deals': prevState.showMore?this.data_source.slice(0,2):this.data_source,
            'fa_arrow': (!prevState.showMore ? "fa fa-sort-up" : "fa fa-sort-down" ),
            'showMore' : !prevState.showMore
        }));


        //console.log(this.props.data.city);
        // Somewhere else, even another file
        scroller.scrollTo('myScrollToElement', {
            duration: 1500,
            delay: 100,
            smooth: true,
            containerId: this.state.city,
            offset: 50, // Scrolls to element + 50 pixels down the page
        
        })

        //document.getElementById(this.state.city).style.backgroundColor = 'red';//location.hash = "#" + this.props.data.city + "-line-5";
    }
    
}

//ReactDOM.render(<Card />, document.getElementById('root'));

export default YearCard
