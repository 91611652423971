import React from 'react';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";


class Terms extends React.Component {
    render() {
        return(
            <div className="App Terms">
                <Header  hide='true' hideSearch={true} />
                <main>
                    <h1>מדיניות פרטיות</h1>
                    <p>מאחר שהחברה (להלן "עפנו") מכבדת את פרטיות המשתמשים באתרי האינטרנט שהיא מנהלת ומפעילה. החליטה החברה לפרסם את מדיניותה ביחס להגנת הפרטיות באתרים, והיא מתחייבת כלפי המשתמש לקיים מדיניות זו.</p>
                    <p>מטרת המדיניות להסביר מהם נוהגי החברה ביחס לפרטיות המשתמשים באתרים, וכיצד משתמשת החברה במידע, הנמסר לה על-ידי המשתמשים באתרים או הנאסף על-ידה בעת השימוש באתרים.</p>

                    <u>כללי</u>
                    <p>בעת שימוש בשירותי האתרים נאסף מידע עליך. חלק מהמידע מזהה אותך באופן אישי, כלומר בשמו ובכתובתך, מוצרים ושירותים שרכשת או ביקשת למכור, אמצעי התשלום ששימשו אותך, וכיו"ב. זהו המידע שאתה מוסר ביודעין, לדוגמה בעת שתירשם לשירותים באתרים. חלק מהמידע אינו מזהה אותך אישית ואינו נשמר ביחד עם פרטיך. זהו מידע סטטיסטי ומצטבר. לדוגמה, פרסומות שקראת באתר, העמודים שבהם צפית, ההצעות והשירותים שעניינו אותך, כתובת האינטרנט (IP ) שממנה פנית ועוד.</p>

                    <u>רישום לשירותים</u>
                    <p>ככל שנדרשים פרטים אישיים בעת רישום לשירותים באתרים או בעת רכישת מוצרים בהם, החברה תבקש ממך רק את המידע הנחוץ במישרין לאספקת השירותים או לרכישת המוצרים.</p>

                    <u>מאגר המידע</u>
                    <p>הנתונים שנאספו  יישמרו במאגר המידע של החברה ובאחריותה.</p>

                    <u>השימוש במידע</u>
                    <p>השימוש במידע שנאסף, ייעשה רק על פי מדיניות פרטיות זו או על פי הוראות כל דין, על מנת:
                        <ul>
                            <li>לאפשר להשתמש בשירותים שונים באתרים .</li>
                            <li>לשפר ולהעשיר את השירותים והתכנים המוצעים באתרים.</li>
                            <li>לשנות או לבטל שירותים ותכנים קיימים.</li>
                            <li>לצורך רכישת מוצרים ושירותים באתרים - לרבות פרסום מידע ותכנים.</li>
                            <li>כדי להתאים את המודעות שיוצגו בעת הביקור באתרים לתחומי ההתעניינות שלך</li>
                            <li>המידע שישמש את החברה יהיה בעיקרו מידע סטטיסטי, שאינו מזהה אישית.</li>
                        </ul>
                    </p>

                    <u>דיוור ישיר אלקטרוני </u>
                    <p>החברה  מעונינת לשלוח אליך מדי פעם בדואר אלקטרוני מידע בדבר שירותיה וכן מידע שיווקי ופרסומי, מידע כזה ישוגר אליך רק אם נתת הסכמה מפורשת לכך, ובכל עת תוכל לבטל את הסכמתך ולחדול מקבלתו.</p>
                    <p>החברה לא תמסור את פרטיך האישיים למפרסמים. עם זאת, היא רשאית להעביר מידע סטטיסטי על פעילות המשתמשים באתרים. מידע סטטיסטי שיועבר לא יזהה אותך אישית. </p>

                    <u>מסירת מידע לצד שלישי</u>
                    <p>החברה לא תעביר לצדדים שלישיים את פרטיך האישיים והמידע שנאסף על פעילותך באתר אלא במקרים המפורטים להלן: 
                        <ul>
                        <li>אם תרכוש מוצרים ושירותים מצדדים שלישיים המציעים אותם למכירה באמצעות האתרים, יועבר לצדדים שלישיים אלה המידע הדרוש להם לשם השלמת תהליך הרכישה </li>
                        <li>במקרה של מחלוקת משפטית בינך לבין החברה שתחייב חשיפת פרטיך;</li>
                        <li>אם תבצע באתרים, פעולות שבניגוד לדין; </li>
                        <li>אם יתקבל צו שיפוטי המורה למסור את פרטיך או המידע אודותיך לצד שלישי; </li>
                        <li>אם החברה  תמכור או תעביר בכל צורה שהיא את פעילות האתרים לתאגיד כלשהו - וכן במקרה שתתמזג עם גוף אחר או תמזג את פעילות האתרים עם פעילותו של צד שלישי, ובלבד שתאגיד זה יקבל על עצמו כלפיך את הוראות מדיניות פרטיות זו.</li>
                        </ul>
                    </p>

                    <u>שימוש Cookies</u>
                    <p>אתר החברה משתמש ב"עוגיות" (Cookies) לצורך תפעולם השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיך האישיות ולצורכי אבטחת מידע.</p>
                    <p>דפדפנים מודרניים כוללים אפשרות  להימנע מקבלת Cookies. אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
פרסומות של צדדים שלישיים 
החברה מתירה לחברות אחרות לנהל את מערך הפרסומות באתרים. המודעות שבהן אתה צופה בעת הביקור באתרי מגיעות ממחשביהן של אותן חברות. כדי לנהל את הפרסומות שלהן, חברות אלה חברות אלה מציבות Cookies במחשבך. ה-Cookies מאפשרים להן לאסוף מידע על האתרים שבהם צפית בפרסומות שהציבו ועל אילו פרסומות הקשת. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן ולא למדיניות הפרטיות של החברה.</p>

                    <u>אבטחת מידע</u>
                    <p>החברה מיישמת באתריה מערכות ונהלים עדכניים לאבטחת מידע. בעוד שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית, אין הם מעניקים בטחון מוחלט. לכן, החברה לא מתחייבת ששירותיה יהיו חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם.</p>

                    <u>זכות לעיין במידע </u>
                    <p>על-פי חוק הגנת הפרטיות, התשמ"א - 1981, כל אדם זכאי לעיין במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו.</p>
                    <p>פנייה כזאת יש להפנות למייל של שירות הלקוחות המופיע בסוף תקנון זה.
בנוסף, אם המידע שבמאגרי החברה משמש לצורך פניה אישית אליך,  אתה זכאי על-פי חוק הגנת הפרטיות, התשמ"א- 1981 לדרוש בכתב שהמידע המתייחס אליך יימחק ממאגר המידע.                    </p>

                    <u>שינויים במדיניות הפרטיות </u>
                    <p>בכל מקרה בו יבוצעו במדיניות זו שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסרת, תפורסם על-כך הודעה בתקנון זה, ועל כן נדרש עליך לבקר בתקנון זה מעת-לעת על מנת להכיר את החוקים החלים עליך.</p>

                    <u>פעילות על פי כל דין ותיקון הפרה על ידי החברה </u>
                    <p>“עפנו” מצהירה בזאת, כי הינה עושה מאמץ לפעול בהתאם להוראות כל דין. יחד עם זאת, אם וככל שיתברר שמעשי ו/או מחדלי החברה אינם עולים בקנה אחד עם הוראות כל דין כאמור ו/או האמור בתקנון זה, המשתמש יודיע על כך לחברה בכתב, וייתן לחברה 15 ימי עבודה לפחות לתקן את ההפרה, דרכי יצירת הקשר עם החברה מופיע בסוף תקנון זה. המשתמש מצהיר בזאת שהינו מוותר על כל סעד המגיע לו בגין הפרה כלשהי מצד “עפנו” ו/או מי מטעמה, אלא אם “עפנו” לא תיקנה את ההפרה תוך 25 ימי עבודה מהמועד שקיבלה הודעה בפועל ובכתב בגין ההפרה.</p>

                    <u>יצירת קשר עם החברה </u>
                    <p>
                    “עפנו” מתחייבת בזאת, לעשות מאמצים סבירים על מנת לספק תמיכה וליווי בגבולות האתר ולאורך התהליך ככל הניתן. ניתן ליצור עמנו קשר בכל עת באמצעות דף <a href="/contact.html">יצירת הקשר</a> או בדואר האלקטרוני בכתובת
                    <img src="/images/contact_us_mail.jpg" style={{display:'block',height:'1.3rem'}} alt="email"></img>
                    </p>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Terms;