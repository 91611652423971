import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import Routes from "./Routes"
import { Router } from "react-router-dom"
import {createBrowserHistory} from 'history'
import 'font-awesome/css/font-awesome.min.css'
import * as serviceWorker from "./serviceWorker"
import TagManager from 'react-gtm-module'

const history = createBrowserHistory()

const tagManagerArgs = {
  gtmId: 'GTM-KNFBGTC'
}

console.log(window.location.hostname)
if (window.location.hostname!=='afnu-dev'  && 
    window.location.hostname!=='beta.afnu.co.il'  && 
    window.location.hostname!=='dev.afnu.co.il' && 
    window.location.hostname!=='staging.afnu.co.il')
  TagManager.initialize(tagManagerArgs)

/*
const routing = (
    <Router history={new BrowserHistory}>
      <div>
        <Route exact path="/" component={App} />
        <Route path="/flights" component={Flights} />
        <Route path="/last-minute" component={LastMinute} />
        <Route path="/deal" component={Deal} />
        <Route path="/flight" component={Deal} />
        <Route exact path="/terms.html" component={Terms} />
        <Route exact path="/service.html" component={Service} />
        <Route  exact path="/contact.html" component={Contact} />
        
      </div>
    </Router>
  )

ReactDOM.render(routing, document.getElementById('root'))
*/

ReactDOM.render(
  <Router history={history}>
    <Routes />
  </Router>,
  document.getElementById("root")
)


/*
if (window.location.pathname.indexOf('terms.html')>0) 
    ReactDOM.render(<Terms />, document.getElementById('root'));
else if (window.location.pathname.indexOf('service.html')>0) 
    ReactDOM.render(<Service />, document.getElementById('root'));
else if (window.location.pathname.indexOf('contact.html')>0) 
    ReactDOM.render(<Contact />, document.getElementById('root'));
else
    ReactDOM.render(<App />, document.getElementById('root'));
*/
    
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// Set the date we're counting down to
//var countDownDate = new Date("Sep 25, 2019 23:37:25").getTime();
var tomorrow  = new Date();
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(0);
tomorrow.setMinutes(0);
tomorrow.setMilliseconds(0);


// Update the count down every 1 second
var x = setInterval(function() {

    let arr_hours = document.getElementsByClassName("hours");
    let arr_minutes = document.getElementsByClassName("minutes");
    let arr_seconds = document.getElementsByClassName("seconds");

    for (let i=0;i<arr_hours.length;i++) {
	// Get today's date and time
	var now = new Date();
        now.setSeconds( now.getSeconds() + i*97 );
        now = now.getTime();

	// Find the distance between now and the count down date
	var distance = tomorrow - now;
  
	// Time calculations for days, hours, minutes and seconds
	//var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor((distance % (1000 * 60 * 60 * 2)) / (1000 * 60 * 60));
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
	hours = zeroPad(hours,2);
	minutes = zeroPad(minutes,2);
	seconds = zeroPad(seconds,2);

        arr_hours[i].innerHTML = hours;
        arr_minutes[i].innerHTML = minutes;
        arr_seconds[i].innerHTML = seconds;

    // Display the result in the element with id="demo"
    //document.getElementById("timer").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
    //document.getElementById("timer").innerHTML = hours + ":" + minutes + ":" + seconds;

    }

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      document.getElementById("timer").innerHTML = "EXPIRED";
    }
  }, 1000);

  function zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
