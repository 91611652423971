import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onYes, onNo, openYesNo, message} = props;
    



    const handleYes = () => {
        onYes();
    };


    const handleNo = () => {
        onNo();
    };


    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openYesNo}>
            <div className='ugly modalBody p-4'>

                <Grid container spacing={2}>
                    <Grid item className='mb-4 text-center' xs={12}><h4><div dangerouslySetInnerHTML={{__html:message.title}} /></h4></Grid>
                    <Grid item className='mb-4 text-center' xs={12}><h5><div dangerouslySetInnerHTML={{__html:message.message}} /></h5></Grid>
                    <Grid item className='text-center' xs={3}></Grid>
                    <Grid item className='text-center' xs={3}><Button onClick={handleYes} variant='contained' color='secondary'>כן</Button></Grid>
                    <Grid item className='text-center' xs={3}><Button onClick={handleNo} variant='contained' color='secondary'>לא</Button></Grid>
                </Grid>
            </div>

        </Dialog>
    );
}
