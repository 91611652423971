import React from "react";
import { Grid, Select,FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

//class Room extends React.Component {

export default function Room(props) {

    const { removeRoom, modifyAdults, modifyChildren, updateChildAge } = props;

    return (
        <>
            <Grid item xs={12} style={props.room_index>0?{display:'flex', alignItems:'baseline', marginTop:'1em'}:{}}>
            <h4 style={{fontSize:'1.2em', lineHeight:'2', color:'#555', fontWeight:800}}>הרכב חדר {(props.room_index+1)}</h4>
                <span className={props.room_index===0?' hidden ':''} style={{fontWeight:700, color:'#cf5770', cursor:'pointer', textDecoration:'underline', marginRight:'.5em'}} onClick={(e) =>
                    removeRoom(props.room_index)}>(הסרה)
                </span>
            </Grid>
            <Grid item xs={7} lg={9}>
                <h4 style={{margin:0,fontWeight:'bold'}}>מבוגרים</h4>
                <small style={{color:'#999'}}>גיל 18 ומעלה</small>
            </Grid>
            <Grid item xs={5} lg={3} className="mb-3" align="center">
            <Grid container>
                <Grid item xs={5}>
                <div
                    className="plus" 
                    name="adults"
                    onClick={(e) => modifyAdults(props.room_index, 1)}
                    
                >
                    +
                </div>
                </Grid>
                <Grid item xs={2} className="caption">
                <strong>{props.data.adults.length}</strong>
                </Grid>
                <Grid item xs>
                <div className="minus" 
                    onClick={(e) => modifyAdults(props.room_index, -1)}
                >
                    -
                </div>
                </Grid>
            </Grid>
            </Grid>

            <Grid item xs={7} lg={9} className="mt-2">
                <h4 style={{margin:0,fontWeight:'bold'}}>ילדים</h4>
                <small style={{color:'#999'}}>גילאי 0-17</small>
            </Grid>
            <Grid item xs={5} lg={3} className="mb-3 mt-2" align="center">
                <Grid container>
                    <Grid item xs={5}>
                    <span
                        className="plus" 
                        name="children"
                        onClick={(e) => modifyChildren(props.room_index, 1)}
                    >
                        +
                    </span>
                    </Grid>
                    <Grid item xs={2} className="caption">
                    <strong>{props.data.children.length}</strong>
                    </Grid>
                    <Grid item xs>
                    <span
                        className="minus" 
                        onClick={(e) => modifyChildren(props.room_index, -1)}
                    >
                        -
                    </span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}  className={props.data.children.length===0?' hidden ':''}>
                <h4 style={{marginTop:'1em'}} className={props.data.children.length>1?'':' hidden '}>גילאי הילדים:</h4>
                <h4 style={{marginTop:'1em'}} className={props.data.children.length===1?'':' hidden '}>גיל הילד/ה:</h4>
                <span style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                    {
                        props.data.children.map((child, index)=>
                        <FormControl variant="outlined" style={{margin:'0 0 0 .5em'}}>
                            <Select nativ value={child} onChange={(e)=>{updateChildAge(props.room_index, index, e.target.value)}}>
                                <MenuItem value={0}>0-1</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={13}>13</MenuItem>
                                <MenuItem value={14}>14</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={16}>16</MenuItem>
                                <MenuItem value={17}>17</MenuItem>
                            </Select>
                        </FormControl>
                        )
                    }
                </span>
            </Grid>

{
/*
            <Grid item xs={7} lg={9}>
            <strong>תינוקות (0-2)</strong>
            </Grid>
            <Grid item xs={5} lg={3} className="mb-3">
            <Grid container>
                <Grid item xs={5}>
                <span 
                    className="plus" 
                    name="infants"
                    onClick={(e) =>
                    setState({
                        ...state,
                        infants:
                        state.infants + 1 < 10
                            ? state.infants + 1
                            : state.infants,
                    })
                    }
                >
                    +
                </span>
                </Grid>
                <Grid item xs={2} className="caption">
                <strong>{state.infants}</strong>
                </Grid>
                <Grid item xs>
                <span
                    className="minus" 
                    onClick={(e) =>
                    setState({
                        ...state,
                        infants:
                        state.infants > 0 ? state.infants - 1 : state.infants,
                    })
                    }
                >
                    -
                </span>
                </Grid>
            </Grid>
            </Grid>
*/
}

{/* 
            <Grid item xs={7} lg={9}>
            <strong>אזרחים ותיקים (65+)</strong>
            </Grid>
            <Grid item xs={5} lg={3} className="mb-2">
            <Grid container>
                <Grid item xs={5}>
                <span
                    className="plus" 
                    name="seniors"
                    onClick={(e) =>
                    setState({
                        ...state,
                        seniors: state.seniors + 1 < 10 ? state.seniors + 1 : state.seniors,
                    })
                    }
                >
                    +
                </span>
                </Grid>
                <Grid item xs={2} className="caption">
                <strong>{state.seniors}</strong>
                </Grid>
                <Grid item xs>
                <span
                    className="minus" 
                    onClick={(e) =>
                    setState({
                        ...state,
                        seniors: state.seniors > 0 ? state.seniors - 1 : state.seniors,
                    })
                    }
                >
                    -
                </span>
                </Grid>
            </Grid>
            </Grid>
*/}
    </>
    )
    
    
}

//export default Room