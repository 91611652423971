import React from "react";
import Modal from 'react-responsive-modal';
import './index.css';

class BaggageIcons extends React.PureComponent {
    constructor (props) {
        super(props)
        
        //console.log(this.props.baggage)
        //console.log(this.props?.airline + ' ' + this.props?.airport)
        const baggageArray = this.props?.baggage?.map(bag=>{
            switch(bag?.category_code) {
                case 'C': return {
                    code:'C',
                    name:'מזוודה',
                    priority: 3, 
                    price: bag.price, 
                    weight: bag.weight,
                    width: bag.width,
                    height: bag.height,
                    length: bag.length,
                    image: `/images/baggage/24_Suitcase.svg`};
                    
                case 'T': return {
                    code:'T',
                    name:'טרולי',
                    priority: 2, 
                    price: bag.price,  
                    weight: bag.weight,
                    width: bag.width,
                    height: bag.height,
                    length: bag.length,
                    image: `/images/baggage/24b_Trolly.svg`};

                case 'S':  return {
                    code:'S',
                    name:'תיק גב',
                    priority: 1, 
                    price: bag.price, 
                    weight: bag.weight,
                    width: bag.width,
                    height: bag.height,
                    length: bag.length,
                    image: `/images/baggage/26_Backpack3.svg`};

                default: {
                    console.log('ERROR - COULD NOT FIND BAG OF TYPE: ' + bag?.category_code); 
                    console.log(bag);
                    return {}
                    break;}
            }}).sort((a,b)=>a.priority-b.priority) ;

        this.state = {
            open: false,
            airport: this.props?.airport,
            baggage:  baggageArray// sort by priority
        }
        
    }
    
    componentDidMount() {
        
    }

    onOpenModal = (e, bag) => {
        e.preventDefault();
        e.stopPropagation();

        //this.setState((prevState) => ({ 'open': true, 'active': prevState.active}));
        this.setState({
            open: true,
            baggage: this.state?.baggage,
            bag: {
                code: bag.code,
                price: bag.price,
                weight: bag.weight,
                width: bag.width,
                height: bag.height,
                length: bag.length
            }
        })
    };

    /*onCloseModal = (e) => {
        e.preventDefault()
        //this.setState((prevState) => ({ 'open': false, 'active': prevState.active}));
        this.setState({open: false,
        baggage:this.state?.baggage})
    };*/

    onCloseModal = (e) => {
        e.preventDefault()
        e.stopPropagation();
        //this.setState((prevState) => ({ 'open': false, 'active': prevState.active}));
        this.setState((prevState)=>({
            ...prevState,
            open: false,
        }))
    };
    
    render() {
        const { open } = this.state;

        //return this.props?.baggage.length;
        /*if (bagsArray && bagsArray.length > 0)
            bagsArray =  [bagsArray[0]]*/
        return (
            <div className='baggage_options' title='לחצו לפירוט הכבודה הכלולה בהזמנת חופשה זו' >
                <div className='caption'>אפשרויות כבודה<br />(לחצו לפרטים נוספים)</div>
                <div className='icons'>{
                    this.state?.baggage?.map( (bag, index) => {
                        
                            return (
                                <a href="javascript:void()" onClick={e=>this.onOpenModal(e, bag)}>
                                    <div key={index} className={bag?.price==='0'?'bagIcon included':'bagIcon'} style={bag?.price==='0'?{color:'green'}:{color:'red'}} title={bag?.price=='0'?`${bag?.name} במשקל ${bag?.weight} קג כלול בהזמנה (לחצו לפרטים נוספים)`:`עלות תוספת ${bag?.name}: $${bag?.price} לכוון (לחצו לפרטים נוספים)`}>
                                        <img src='/images/baggage/checkmark.svg' className='checkmark' />
                                        <img src={bag?.image} alt={bag?.name} className='iconImage' />
                                    </div></a>
                            )
                    })
                }</div>

                <Modal open={this.state?.open?true:false} onClose={e=>this.onCloseModal(e)} center >
                    <div dir="rtl"  className="baggage_options_modal_content" tabIndex="0">
                        {
                            this.state?.bag?.code=='S'?
                                this.state?.bag?.price==='0'?
                                    (<div><h3>תיק גב ({this.state?.bag.weight} קג) כלול <u>בחינם</u></h3>
                                    <p className='highlight'>הזמנה זו כוללת תיק גב<br />חינם לכל נוסע</p>
                                    <p><img src='/images/baggage/backpack_slim.svg'/></p>
                                    <p>משקל: {this.state?.bag?.weight} ק"ג</p>
                                    <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ</p> </div>)
                                : 
                                    (<div><h3>תיק גב ({this.state?.bag.weight} קג) בתוספת תשלום</h3>
                                    <p className='highlight'>מחיר לנוסע לכוון: <strong>${this.state?.bag?.price}</strong></p>
                                    <p><strong>ניתן להוסיף בטופס ההזמנה (לפני התשלום)</strong></p>
                                    <p><img src='/images/baggage/backpack_slim.svg'/></p>
                                    <p>משקל: {this.state?.bag?.weight} ק"ג </p>
                                    <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ</p> </div>)
                            :''
                                        
                        }
                        
                        {
                            this.state?.bag?.code=='T'?
                                this.state?.bag?.price==='0'?

                                    (<div><h3>טרולי ({this.state?.bag.weight} קג) כלול <u>בחינם</u></h3>
                                    <p className='highlight'>הזמנה זו כוללת טרולי<br />חינם לכל נוסע</p>
                                    <p><img src='/images/baggage/trolly.svg'/></p>
                                    <p>משקל: {this.state?.bag?.weight} ק"ג </p>
                                    <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ </p></div>)
                                :
                                    
                                        (<div><h3>טרולי ({this.state?.bag.weight} קג) בתוספת תשלום</h3>
                                        <p className='highlight'>ניתן להוסיף בטופס ההזמנה <br />(לפני התשלום)</p>
                                        <p><img src='/images/baggage/trolly.svg'/></p>
                                        <p className='highlight'>מחיר לנוסע לכוון: <strong>${this.state?.bag?.price}</strong></p>
                                        <p>משקל: {this.state?.bag?.weight} ק"ג </p>
                                        <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ</p> </div>)

                            :''
                        }

                        {
                            this.state?.bag?.code=='C'?
                                this.state?.bag?.price==='0'?

                                    (<div><h3>מזוודה ({this.state?.bag.weight} קג) כלולה <u>בחינם</u></h3>
                                    <p className='highlight'>הזמנה זו כוללת מזוודה<br />חינם לכל נוסע</p>
                                    <p><img src='/images/baggage/suitcase.svg'/></p>
                                    <p>משקל: {this.state?.bag?.weight} ק"ג </p>
                                    <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ </p></div>)
                                :    
                                    (<div><h3>מזוודה ({this.state?.bag.weight} קג) בתוספת תשלום</h3>
                                    <p className='highlight'>ניתן להוסיף בטופס ההזמנה <br />(לפני התשלום)</p>
                                    <p><img src='/images/baggage/suitcase.svg'/></p>
                                    <p className='highlight'>מחיר לנוסע לכוון: <strong>${this.state?.bag?.price}</strong></p>
                                    <p>משקל: {this.state?.bag?.weight} ק"ג </p>
                                    <p>מידות: {this.state?.bag?.height}x{this.state?.bag?.width}x{this.state?.bag?.length} ס"מ</p> </div>)

                            :''
                        }
            
                        <div style={{marginTop:'1.5em'}}>
                            <button onClick={e=>this.onCloseModal(e)} style={{color:'#000', textDecoration:'underline'}} tabInex="0">סגור חלון</button></div>
                    
                    </div>
                </Modal>
            </div>
        )
    }
    //}
}
        
export default BaggageIcons