import React, { Component } from 'react'
import axios from 'axios';
import Header from "../../features/Layout/Header"
import Heading from "../../features/Heading/index"
import Footer from "../../features/Layout/Footer"
import './Article.css'

export class Articles extends Component {
   state = {
       articles: [],
       isLoaded: false
   }

   constructor (props) {
    super(props)
   }

   componentDidMount () {
        //https://blog.afnu.co.il/wp-json/wp/v2/articles?slug=about
        axios.get(`https://blog.afnu.co.il//wp-json/wp/v2/pages/?slug=${this.props.match.params.id}`)
            .then(res => this.setState({
                articles: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err))
   }
   render() {
        let strActiveMenu = "main";
        let hideMainImage=false;

       return (
        <div className="App">  
            <Header activeMenu={strActiveMenu} hide={hideMainImage} className="hidden" hideSearch={true} />
            <main className={this.props.match.params.id} style={this.state.isLoaded?{}:{background:'#fff'}}>
                <div className={this.state.isLoaded?'wave':'hidden'}>
                    <svg viewBox="0 0 1500 62" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minHeight:'40px'}}>
                        <path transform="scale(1)" fillRule="evenodd" clipRule="evenodd" d="M2748.4,38.1c-211.5-0.3-438.7,84.6-634.5-0.8c-190-82.9-394.4,85.4-609.5-0.7c-204.9-82.1-433,86.2-650.9-0.8
                C659-41.9,412.2,122.7,235.7,35c-157-78-314.4,87.7-513-0.6C-444.1-39.8-635.1,34-814,33.7v89h3562.4V38.1z" fill="#eee"></path></svg>
                </div>

                <img src='/images/Iphone-spinner-2.gif' width='64' height='64' className={this.state.isLoaded?'hidden':''}></img>
                <div style={{margin:'1em',maxWidth:'1000px', width:'96%', margin:'0 auto', textAlign:'center', padding:'1em'}}>
                    {this.state.articles.map(article =>
                        <>
                        <div dangerouslySetInnerHTML={{__html: article.content.rendered}}></div>
                        </>
                    )}
                </div>
            </main>
            <Footer />
        </div>
       )
   }
}
export default Articles