import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone} from "@fortawesome/free-solid-svg-icons";
import "./WhatsappSignup.css";
//import Dragscrolles from 'dragscrolles';

class WhatsappSignup extends React.Component {
  
    constructor (props) {
        super(props)

        // binding
        this.trackClick = this.trackClick.bind(this); 

    }


    componentDidMount() {
        /*
        scrolls.forEach((scroll) => {
            new Dragscrolles(scroll);
        });*/
    }


    trackClick = event => {
       
        const event_name = 'WhatsappGroupSignupButton';
        const url = '/whatsapp/join.htm';

        console.log('GTM event: ' + event_name)
        event.stopPropagation()
        event.preventDefault();

        // add GTM custom event
        if (window['google_tag_manager']) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event_name,
                eventTimeout: 2000,
                target_url: url,
                pageUrl: window.location,
                pageTitle : window.title,
                eventCallback: function() {
                    window.location = url;
                }
            });
        } else {
            window.location.href=url;
        }
    }

    render() {
        

            return(
                <div className='whatsapp_signup'>
                    <a href="/whatsapp/join.htm" title="צרפו אותי הצטרפו לקבוצות הדילים הסודיות שלנו ב-WhatsApp" onClick={e=> {this.trackClick(e)}}>
                        <div className='icon'>
                                <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path d="m.76 21.24 1.412-5.12A10.324 10.324 0 0 1 .76 10.93C.76 5.35 5.35.76 10.964.76 16.58.76 21.24 5.35 21.24 10.93c0 5.578-4.661 10.31-10.276 10.31-1.765 0-3.46-.565-4.978-1.413L.76 21.24Z" fill="#EDEDED"></path>
                                <path d="m6.268 17.991.318.177c1.307.812 2.825 1.306 4.414 1.306 4.626 0 8.474-3.848 8.474-8.545 0-4.696-3.848-8.404-8.51-8.404-4.66 0-8.439 3.743-8.439 8.404 0 1.624.46 3.213 1.307 4.555l.212.318-.812 2.966 3.036-.777Z" fill="#25D366"></path>
                                <path d="m8.245 6.198-.671-.036a.802.802 0 0 0-.565.212c-.318.283-.848.812-.989 1.519-.247 1.059.141 2.33 1.06 3.601.918 1.271 2.683 3.32 5.79 4.202.989.283 1.766.106 2.402-.282.494-.318.812-.812.918-1.342l.105-.494a.355.355 0 0 0-.176-.389l-2.225-1.024a.337.337 0 0 0-.423.106l-.883 1.13a.275.275 0 0 1-.283.07c-.6-.211-2.613-1.059-3.707-3.177-.036-.106-.036-.212.035-.283l.848-.953c.07-.106.105-.247.07-.353L8.527 6.41a.308.308 0 0 0-.282-.212Z" fill="#FEFEFE"></path>
                                </svg>
                        </div>
                        
                        <div className="content">
                            <span className="wa-cta-button-label font-header wa-cta-button-label-margin">
                                הצטרפו לקבוצות הדילים הסודיות שלנו ב-WhatsApp
                            </span>
                            <button>צרפו אותי עכשיו</button>
                        </div>
                    </a>                    
                </div>

            )
    }
}

export default WhatsappSignup