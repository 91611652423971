import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
//import { blue } from '@material-ui/core/colors';

import { Grid, Button } from "@material-ui/core";

/*
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
*/


export default function SimpleDialog(props) {
    const { onOk, openQuantityNotFound} = props;

    const Ok = () => {
        onOk();
    };

    // onClose={handleClose} 
    return (
        <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={openQuantityNotFound} keepMounted>
            <div className='ugly modalBody p-4'>

                <Grid container id="PackageNotFoundModal" className="UserNotificationModal">
                    <Grid item className='text-center' xs={12}>
                        <div className="heading">
                            {/*<img alt="לא הצלחנו למצוא חדרים" src="/images/modals/package-not-found-heading.png" />*/}
                            <h3>לא הצלחנו למצוא חדרים בהרכב המבוקש</h3>
                        </div>
                    </Grid>
                    <Grid item className='mb-4 text-center heading' xs={12} >
                        <div className="content">
                            <h3>אנא נסו לשנות את ההרכב או לפצל ליותר מחדר אחד</h3>
                        </div>
                    </Grid>
                    <Grid item  className='text-center' xs={12}><Button onClick={Ok} variant='contained' color='secondary'>אישור</Button></Grid>
                </Grid>
            </div>

        </Dialog>
    );
}
