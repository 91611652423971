import React from 'react';
import configData from '../../config/config.json';
import '../../assets/css/App.css';
import Header from "../../features/Layout/Header";
import Footer from "../../features/Layout/Footer";
//import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Cookies from 'universal-cookie';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import {PageView, initGA, Event} from '../../features/Tracking';

const API_ENDPOINT = configData.API_ENDPOINT;

class MembersSignup extends React.Component {
    
    constructor(props) {
        super(props);

        this.blinkInterval = null;

        this.state = {
          'isChecked' : false,
          'btnsState': 'disabled',
          'overlayState' : '',
          'open': false
        }

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this); 
        this.fbButtonClicked = this.fbButtonClicked.bind(this); 
        this.btnsHandleClick = this.btnsHandleClick.bind(this); 
        this.blink_stop = this.blink_stop.bind(this); 
        this.blink = this.blink.bind(this); 
        this.responseGoogle = this.responseGoogle.bind(this);
        this.getUserAndCreateCookie = this.getUserAndCreateCookie.bind(this);
    }

    componentDidMount() {
          initGA();
          PageView();
    
      }

    handleCheckboxChange(event) {
        const target = event.target;
        this.blink_stop()
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState((prevState, props)=> ({
          [name]: value,
          'btnsState' : prevState.isChecked?'disabled':'',
          'overlayState' : prevState.isChecked?'':'disabled'
        }));
        console.log(this.state.isChecked);
        Event("MEMBERS_ZONE", "Checkbox State Change", this.state.isChecked);
        //clearInterval(this.blinkInterval);
    }

    responseFacebook = (response) => {
        //console.log(response);
    
        if (response.accessToken!=null) {
            // extract fetched data
            const full_name = response.name.split(" ");

            // extract fetched data
            var data = new FormData();
            data.append('name', response.name);
            data.append('first_name', full_name[0]);
            data.append('last_name', full_name[1]);
            data.append('email', response.email);
            data.append('provider_user_id', response.userID);
            data.append('image_url', response.picture.data.url);
            data.append('image_width', response.picture.data.width);
            data.append('image_height', response.picture.data.height);
            data.append('provider_access_token', response.accessToken);
            data.append('provider_signed_request', response.signedRequest);
            data.append('provider', 'facebook'); 

            Event("MEMBERS_ZONE", "Facebook Signup Data save", response.name + "|" + response.userID + "|" + response.accessToken);
            // Save data to remove server
            this.getUserAndCreateCookie(data);

            Event("MEMBERS_ZONE", "Facebook Signup Completed", response.name + "|" + response.userID + "|" + response.accessToken);
        }
    }
    
    responseGoogle = (response) => {
        console.log(response);
        if (response.error==null && response.googleId!=null) {
            const googleAccessToken = response.accessToken;
            const googleProfile = response.profileObj;
    
            // extract fetched data
            var data = new FormData();
            data.append('name', googleProfile.name);
            data.append('first_name', googleProfile.givenName);
            data.append('last_name', googleProfile.familyName);
            data.append('email', googleProfile.email);
            data.append('provider_user_id', googleProfile.googleId);
            data.append('image_url', googleProfile.imageUrl);
            data.append('image_width', '');
            data.append('image_height', '');
            data.append('provider_access_token', googleAccessToken);
            data.append('provider_signed_request', '');
            data.append('provider', 'google');

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'MemberSignupCompleted',
                'pageUrl': window.location,
                'pageTitle' : window.title
            });

            Event("MEMBERS_ZONE", "Google Signup Data Save", googleProfile.name + "|" +  googleProfile.googleId + "|" + googleAccessToken);

            // Save data to remove server
            this.getUserAndCreateCookie(data);

            Event("MEMBERS_ZONE", "Google Signup Completed", googleProfile.name + "|" +  googleProfile.googleId + "|" + googleAccessToken);
        }
        else {
            console.log(response.error);    
        }
        
    }
   
    getUserAndCreateCookie = async(data) => {
        const component = this;
        
        try {
            axios.post(API_ENDPOINT + "/user/signin/", data)
            .then(response => {
                //console.log(response.data);

                // Set Cookie
                let expiration = new Date();
                const minutes = 60;
                const hours = 24;
                const days = 365; // 1 year
                expiration.setTime(expiration.getTime() + (days*hours*minutes*60*1000));
        
                const cookies = new Cookies();
 
                cookies.set('afnu_member', response.data , { path: '/', expires: expiration});
        
                // Redirect to homepage
                //window.location = '/';
                component.setState({ open: true });
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                  }
                  console.error(error.config);
            });
        }
        catch (err) {
            console.error(err);
        } 
    }    

    fbButtonClicked = (e) => {
        Event("MEMBERS_ZONE", "FB Button Click", "");
    }

    googleButtonClicked = (e) => {
        Event("MEMBERS_ZONE", "GOOGLE Button Click", "");
    }

    btnsHandleClick = (e) => {
        if (!this.state.isChecked) {
            e.preventDefault();
            alert('חובה לאשר את תנאי השימוש');
            this.blink(document.getElementById("chk_agree"));
        }
        
        Event("MEMBERS_ZONE", "Overlay Click", "");
        //console.log("FORCE CHECK");
        //console.log(this.state.isChecked)
    }

    
    blink_stop = () => {
        if (document.getElementById("chk_agree")!=null)
            document.getElementById("chk_agree").removeAttribute("style");
        clearInterval(this.blinkInterval);
    }
    
    blink = (obj) => {
        let counter = 0;
        const _parent = this;

        this.blinkInterval = 
            setInterval(function(){
                counter++;
                
                if (counter%2 === 0) {
                    obj.style.backgroundColor = '#ff0909' // highlight
                    obj.style.borderColor = '#999'

                }
                else {
                    obj.style.backgroundColor = '#fff'
                    obj.style.borderColor = '#deb20b'
                }

                if (counter > 20) {
                    _parent.blink_stop()
                    return;
                }

            },
            350);
    }

    onOpenModal = () => {
        Event("MEMBERS_ZONE", "Members Signup Completed", "");
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
        window.location = "/";
      };

    render() {
        const { open } = this.state;

        return(
            <div className="App MemberSignup">
                <Header hide="true" />
                <main>
                   <h1>VIP</h1>
                   <h2>רק החברים שלנו יכולים לראות את כל הדילים ולהנות ממגוון הטבות נוספות*</h2>
                   <h2 className="gold">ההרשמה והחברות חינם</h2>
                    <div className={"btns " + this.state.btnsState}>
                        <div className={"overlay " + this.state.overlayState}  onClick={this.btnsHandleClick}></div>
                        <GoogleLogin 
                            clientId="529662349109-pb6elflq7lnoaq8jg382au743oil5ooi.apps.googleusercontent.com" 
                            buttonText="הרשמו באמצעות גוגל"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            className="google-login-btn"
                            disabled={!this.state.isChecked}
                            accessType="online"
                            isSignedIn="false"
                            onClick={this.googleButtonClicked}

                        />
                        <br />
                        <br />
                        {/*
                        <FacebookLogin
                            onClick={this.fbButtonClicked}
                            appId="392902844713038"
                            autoLoad={false}
                            fields="name,email,picture,first_name,last_name"
                            callback={this.responseFacebook}
                            cssClass="facebook-login-btn"
                            icon="fa-facebook"
                            textButton="הרשמו באמצעות פייסבוק"
                            isDisabled={!this.state.isChecked}
                        />*/}
                    </div>
                    <Modal open={open} onClose={this.onCloseModal} center>
                    <div dir="rtl"  className="modal_content">
                    <h2>ברוך הבא למועדון ה-VIP</h2>
                    <p>תודה שנשרמתם.</p>
                    <p>בקרוב מאד גם אתם תוכלו להזמין את הדילים המדהימים שלנו!</p>
                    <p>חכו לעדכונים...</p>
                    <div className="filters"><button onClick={this.onCloseModal}>אישור</button></div>
                    </div>
                    </Modal>

                   <div className="terms">

                    <label className="checkbox">
                        <input type="checkbox" 
                                name="isChecked"
                                checked={this.state.isChecked}
                                onChange={this.handleCheckboxChange} />
                        <span className="overlay" id="chk_agree">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon">
                            <polyline points="20 6 9 17 4 12"/>
                            </svg>
                        </span>
                        בלחיצה על הכפתור, אני מאשר/ת כי קראתי וכי אני מסכימ/ה לכל <a href="/members/terms.html" target="_blank">תנאי התקנון</a> ולקבלת הודעות יחודיות לחברי המועדון (תוכלו לבקש להפסיק לקבלן בכל עת)
                        </label>                   
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                   </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default MembersSignup;
