import React, {useEffect, useRef} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button } from "@material-ui/core";

export default function SimpleDialog(props) {
    const { onClose, open, data} = props;
    const content = useRef(null)

    useEffect(async() =>{
        if (content?.current)
            content.current.focus()
    })
    const handleClose = () => {
        onClose();
    };

    // onClose={handleClose} 
    if (data > 0 && data < 1 )  {
        return (
            <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={open}>
            <div className='modalBody p-4' >

                <Grid container ref={content}>
                    <Grid item className='mb-4 text-center' xs={12} >
                    <div tabIndex={1}>
                    <p>הרוכשים שירות זה יכולים לבטל את חופשתם מכל סיבה (והלוואי והסיבות תמיד יהיו טובות!) ולקבל החזר כספי בשווי 80% מעלות ההזמנה (כולל הכבודה). </p>
                    <p>תוכלו להודיע עד {data*24} שעות לפני מועד ההמראה על רצונכם לבטל את ההזמנה ותקבלו את ההחזר הכספי. </p>
                    <p>השירות ניתן על ידי חברת טריפ גרנטי מבית הפניקס והוא כפוף ל<u><a href="/documents/tripguaranty/300203095_Flight-Cancel_120x220_07.2019_New.pdf" target="_blank" title="קובץ PDF להורדה עם תנאי הפוליסה של טריפ גרנטי">תנאי הפוליסה</a></u> המלאים</p>
                    </div>    
                        </Grid>
                        <Grid item className='text-center' xs={12}><Button tabIndex={2} onClick={handleClose} variant='contained' color='secondary'>סגור</Button></Grid>


                    </Grid>
                </div>

            </Dialog>
        )
    } else {
        return (
            <Dialog maxWidth='xs' fullWidth={true} aria-labelledby="simple-dialog-title" open={open}>
                <div className='modalBody p-4' >

                    <Grid container>
                        <Grid item className='mb-4 text-center' xs={12}>
                        <div tabIndex={1}>
                            <p tabIndex={2}>הקורונה לימדה את כולנו (בוודאי בענף התיירות) עד כמה החיים יכולים להשתנות ברגע.. מרגעים של צחוק לרגעים מצחיקים פחות (וההפך). בדיוק בשביל הרגעים האלה השקנו את שירות - הזמנה הגמישה! </p>
                            <p tabIndex={3}>הרוכשים שירות זה יוכלים לבטל את חופשתם מכל סיבה (והלוואי והסיבות תמיד יהיו טובות!) ולקבל את מרבית כספם בחזרה - בלי ניירת, בלי בירוקרטיה, בלי שאלות וטירטורים. </p>
                            <p tabIndex={4}>תוכלו להודיע לנו עד {data} ימים לפני יום ההמראה, על רצונכם לבטל את ההזמנה ותקבלו את ההחזר הכספי המובטח לכם במהירות - הכל בשקיפות ובמינימום התעסקות.</p>
                        </div>    
                        </Grid>
                        <Grid item className='text-center' xs={12}>
                            <Button tabIndex={5} onClick={handleClose} variant='contained' color='secondary' title="סגור חלון זה">סגור</Button></Grid>


                    </Grid>
                </div>

            </Dialog>
        );
    }
}