import React from "react"

class Footer extends React.Component {
    render() {
        return (
            <footer className={this.props.hide?'hide':''}>
                <span className="copyrights">עפנו  &copy;  כל הזכויות שמורות</span> <span className="copyrights-seperator "> | </span><a href="/terms.html" title="תקנון שימוש">תקנון שימוש</a> | <a href="/privacy.html" title="הצהרת פרטיות" >הצהרת פרטיות</a> | <a href="/accessibility.html" title="הצהרת נגישות">הצהרת נגישות</a> | <a href="/contact.html" title="צרו איתנו קשר">יצירת קשר</a>
            </footer>
        );
    }
}

export default Footer