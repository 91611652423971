import React from "react";
import configData from '../../../config/config.json';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import { Button, Stepper, StepButton, StepLabel, Step } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { NightsStay, SearchOutlined as SearchIcon} from "@material-ui/icons";
import { FilterListOutlined as FilterList} from "@material-ui/icons";
import { FlightTakeoff as FlightIcon } from "@material-ui/icons";
import { EventAvailable as CalendarIconOneColor } from "@material-ui/icons";
import { Star } from "@material-ui/icons";
import Pax from '../Pax';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

// Load external SVG files as icons
import { ReactComponent as FamilyRestroomIcon} from '../../../assets/images/icons/FamilyRestroomIcon.svg';
import { ReactComponent as CalendarIcon} from '../../../assets/images/icons/calendar.svg';

// Holiday icons

// NOTEICE: These icons are no longer inlined! they are loaded dynamically by the DB!

/*import { ReactComponent as RoshHashanaIcon} from '../../images/icons/rosh-hashana.svg';
import { ReactComponent as YomKipurIcon} from '../../images/icons/yom-kipur.svg';
import { ReactComponent as SukkotIcon} from '../../images/icons/sukkot.svg';
import { ReactComponent as ChristmasIcon} from '../../images/icons/christmas.svg';
import { ReactComponent as ChristmasSalesIcon} from '../../images/icons/christmas.svg';
import { ReactComponent as HanukkaIcon} from '../../images/icons/hanukkah.svg';
import { ReactComponent as PurimIcon} from '../../images/icons/purim.svg';
import { ReactComponent as PassoverIcon} from '../../images/icons/passover.svg';
import { ReactComponent as IndependenseDayIcon} from '../../images/icons/independence-day.svg';
import { ReactComponent as SabboseIcon} from '../../images/icons/sabbose.svg';
import { ReactComponent as SummerVacationIcon} from '../../images/icons/summer-vacation.svg';
*/
// buttons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//import NotificationIcon from '@material-ui/icons/NotificationImportant';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import PeopleIcon from '@material-ui/icons/People';
import Switch from '@material-ui/core/Switch';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import CommentIcon from '@material-ui/icons/Comment';

import './index.css';

import Calendar from '../../Calendar'

const API_ENDPOINT = configData.API_ENDPOINT;

// NUMBER OF MONTHS TO SHOW AHEAD
const startMonth = configData.CALENDAR?.start_month;
const endMonth   = configData.CALENDAR?.end_month;
//const nMonths = configData.CALENDAR.months_count; // date in N months from today (last day of that month)      
const skipMonthsArray = configData?.CALENDAR?.skip_months; // date in N months from today (last day of that month)      

// Calculate the start date either by using the provided start date or by subtracting the months count from the endDate
let dt = new Date();
let minDate = new Date(dt.getTime() + 2 * 24 * 60 * 60 * 1000); // date in 2 days time

if (startMonth && dt.getTime() <=new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1).getTime()) {
    minDate = new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1);
    //startMonth = dt.getFullYear() + '-' + (dt.getMonth() + 1);
}

// Calculate the end date either by using the provided end date or by adding the months count to the startDate
const endDate = new Date(endMonth.split('-')[0],endMonth.split('-')[1],1); // :new Date(dt.setMonth(currentMonth+nMonths));

let maxDate = new Date(endDate.getFullYear(), endDate.getMonth() , 0); // 0=set date to the last day of month in nMonths months (7)

// Calculate the number of months we'll show
const nMonths = Math.round((endDate.getTime() - minDate.getTime())  / (1000 * 60 * 60 * 24 * 30));

// Fetch the list of months names & years
const monthsArray = getNextNMonthNamesWithYear(minDate, maxDate, skipMonthsArray); // Get total of 6 months

 // Calculate the number of cells per row
let cellsPerRow = 3;
if ((monthsArray.length >= 6 && monthsArray.length < 8) || monthsArray.length > 8) 
    cellsPerRow = 4;


const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);

  
  


class SearchWizard extends React.Component {
        
    constructor (props) {
        super(props)

        if (!this.props.location || !this.props.location.search || this.props?.location?.search==="") {
            console.log('default')
            this.state = {
                filters : {
                    ...this.props.filters,
                    window: {
                        ...this.props.filters.window,
                        'step': 0,
                        'next_step_close': this.props?.filters?.window?.next_step_close?this.props?.filters?.window?.next_step_close:false,
                        'dates_error': false,
                        'dates_not_allowed': false,
                        'visible': this.props?.is_open,
                    },
                },
                calendar: false
            }
        }
        else if (this.props.location.search) {
            //console.log('else')
            const QS = queryString.parse(this.props.location.search);

            if (QS?.when!='dates') {
                delete(QS['from']);
                delete(QS['to']);
            }
            this.state = {
                rooms: [
                    {
                        adults: [30,30],
                        teens: [],
                        children: [],
                        infants: []
                    }
                ],
                filters : {
                    ...this.props.filters,
                    window: {
                        ...this.props.filters.window,
                        'step': 0,
                        'next_step_close': this.props?.filters?.window?.next_step_close?this.props?.filters?.window?.next_step_close:false,
                        'visible': this.props.is_open
                    },
                    QS
                    //QS.when!=undefined?'when':QS.when:''
                },
            }
        } else {
            this.state = {
                //all_destinations: (this.props.destinations)
            }
        }

        
        if (this.props.type==="flights") 
            this.HideDealFilter = ' hide ';

    }
    
    componentDidMount() {
        // Monitor scrolling to show filters after 3rd result
        document.addEventListener('scroll', this.handleScroll);
    }


    handleScroll = event => {
 
        try {
            if (!(document.getElementsByClassName('filters_toggle')) || !(document.getElementsByClassName('filters_toggle').length > 0))
                return

            //var card_lines = document.getElementsByClassName('card');
            var filters_button = document.getElementById('searchWizardButtons')
            var filters_rect = filters_button?.getBoundingClientRect();

            if (filters_rect?.bottom < 0)
                document.getElementsByClassName('filters_toggle')[0].className='filters_toggle float visible'
            else 
                document.getElementsByClassName('filters_toggle')[0].className='filters_toggle hidden'
        } catch(e) {}   
    }

   
    render(){
        //console.log('rendered search box')

        // Generate dates caption
        let when_caption = 'בכל התאריכים';
        let dates_caption = when_caption;

        if (this.props.filters.months && this.props.filters.months?.length>0) {
            if (this.props.filters.months.length>1) {
                when_caption = 'בחודשים הנבחרים'
            } else {
                when_caption = 'בחודש הנבחר'
            }
        }

        if (this.props.filters.from_date && this.props.filters.to_date) {
            when_caption = 'בתאריכים הנבחרים'
            
            const from = this.props.filters.from_date;
            const to = this.props.filters.to_date;

            // fixed according to this suggestions: https://stackoverflow.com/questions/61218243/javascript-returns-nan-only-in-ios-browsers
            const fromDate = new Date(from.split('-')[0],from.split('-')[1]-1,from.split('-')[2]);
            const toDate = new Date(to.split('-')[0],to.split('-')[1]-1,to.split('-')[2]);

          // todo: fix iphone bug showing this as NaN/NaN/NaN...
            when_caption =  (fromDate.getDate() + "/" + (fromDate.getMonth()*1+1) + '/' + (toDate.getFullYear()+'').replace('20','') + " עד " + (toDate.getDate() + "/" + (toDate.getMonth()*1+1)) + '/' + (toDate.getFullYear()+'').replace('20',''))
            dates_caption = when_caption
        }

        if (this.props.filters.holidays && this.props.filters.holidays.length > 0) {
            when_caption = 'בחגים הנבחרים'
        }


        // Prepare Stars Caption
        let stars_caption = '3 עד 5 כוכבים'

        if (this.props.filters.stars && this.props.filters?.stars.length > 0 ) {
            const stars = this.props.filters.stars;
            if (stars.find(star=>star==='3')) {
                if (!stars.find(star=>star=='4') && !stars.find(star=>star=='5')) {
                    stars_caption = '3 כוכבים'
                } else {
                    if (stars.find(star=>star==='4')) {
                        if (stars.find(star=>star==='5')) {
                            stars_caption = '3 עד 5 כוכבים'
                        } else {
                            stars_caption = '3 עד 4 כוכבים'
                        }
                    }
                    if (stars.find(star=>star==='5')) {
                        if (stars.find(star=>star==='4')) {
                            stars_caption = '3 עד 5 כוכבים'
                        } else {
                            stars_caption = '3 או 5 כוכבים'
                        }
                    }
                }
            } else {
                if (stars.find(star=>star==='4')) {
                    if (stars.find(star=>star==='5')) {
                        stars_caption = '4 עד 5 כוכבים'
                    } else {
                        stars_caption = '4 כוכבים'
                    }
                } else {
                    stars_caption = '5 כוכבים'
                }
            }
        }

        // Prepare nights caption
        let nights_caption = '3 עד 7 לילות'
        if (this.props.filters.nights && this.props.filters?.nights.length > 0 ) {
            const nights = this.props.filters.nights;
            if (nights.length==1) {
                nights_caption = `${nights[0]} לילות`
            } else {
                const min_nights = Math.min( ...nights );
                const max_nights = Math.max( ...nights );
                nights_caption = `${min_nights} עד ${max_nights} לילות`
            }
        }

        // Prepare destinations caption
        let destinations_caption = 'כל היעדים';
        //console.log(this.props.filters?.destinations)
        if (this.props.filters.destinations && this.props.filters.destinations!='' && this.props.filters.destinations?.length>0) {
            if ((Array.isArray(this.props.filters.destinations)&&this.props.filters?.destinations?.length>1 ) ||(!Array.isArray(this.props.filters.destinations) && this.props.filters?.destinations?.split(',').length>1)) {
                destinations_caption = (Array.isArray(this.props.filters.destinations)?this.props.filters.destinations.length:this.props.filters.destinations.split(',').length) + ' יעדים נבחרים'
            } else {
                destinations_caption = 'ליעד הנבחר'
            }
        }



            
        //const { open } = this.state;
        // Convert sql date format to native date that also supported by Iphone
        //const from_date = this.state.filters.from_date?new Date(this.state.filters.from_date):null;
        //console.log(from_date)
        /*const from = !from_date?
                        null:
                        from_date//new Date(from_date.split('-')[0],from_date.split('-')[1]-1,from_date.split('-')[2]);
        const to_date = this.state.filters.to_date?new Date(this.state.filters.to_date):null;
        const to = !to_date?
                        null:
                        to_date//new Date(to_date.split('-')[0],to_date.split('-')[1]-1,to_date.split('-')[2]);
          */      

        const location = this.props.location;
        this.querystring = "";
        /*if (this.state.filters)
            Object.keys(this.state.filters).map(i => this.querystring+=`&${i}=${this.state.filters[i]}` )
        this.filter_path = location.pathname + "?" +  this.querystring;*/
      
        
        //if (this.state.redirect) {

            
        //}


        
        //const fromArray = this.state.filters?.from?.split("/");
        //const fromDate = fromArray&&fromArray.length>1?(fromArray[2].substr(0, 2) + '/' + fromArray[1] + '/' + fromArray[0]):null;

        //const toArray = this.state.filters?.to?.split("/");
        //const toDate = toArray&&toArray.length>1?(toArray[2].substr(0, 2) + '/' + toArray[1] + '/' +  toArray[0]):null;

        /*let destinations = this.props?.destinations?.map((dest) => 
            <option 
                value={dest.city_id}
                key  ={dest.city_uid}
            >
                {dest.city_name}
            </option>
        )*/

        
        // If we're at step 0 then fetch all holidays once
        try {

            if ((this.props.is_open) && !this.state?.all_holidays) {
                const url =  API_ENDPOINT + '/search/holidays/?lang=he&filters=true';
                //console.log(url)
                fetch(url)
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
                        }
                    })
                    .then(response=>{
                        return response.sort((a,b) => {
                            if (a.start_date > b.start_date) {
                                return 1;
                            }
                            if (b.start_date > a.start_date) {
                                return -1;
                            }
                            return 0;
                        }
                    )})
                    .then(response=>{
                        this.setState(prevState=>({
                            ...prevState,
                            all_holidays: response.slice(0, 4) // get only 4 first results
                        }))
                    })
            }    
        } catch (e) {
            console.log('Failed to load holidays list')
            console.log(e)
        }
        
        // If we're at step 1 and the list of destinations is empty, then fetch it from the server
        if (this.props.step == 4 && !this.props?.all_destinations) {
            const url =  API_ENDPOINT + '/search/destinations/?lang=he';
            fetch(url)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
                    }
                })
                .then(response=>{
                    return response.sort((a,b) => {
                        if (a.city_name > b.city_name) {
                            return 1;
                        }
                        if (b.city_name > a.city_name) {
                            return -1;
                        }
                        return 0;
                    }
                )}).then(response=>{
                    this.setState(prevState=>({
                        ...prevState,
                        all_destinations: response
                    }))
                })
        }

        return (
        <>
            <div className="filters_toggle hidden "
                style={{position:'fixed',zIndex:1000,width:'100%', bottom: 0, borderRadius: 0, fontSize:'1.4em', padding:'.25em', fontWeight:800}}
                onClick={e=>{this.handleToggleFilters(e, 0)}}>
                <SearchIcon style={{
                    fontSize:'1.5em',
                    margin:'0 .25em',
                    fontWeight:800,
                    strokeWidth: 1,
                    stroke: '#222'
                }} />
                לחצו לחיפוש דיל</div>
            <div className={'searchWizardButtons mobile hidden'+(this.props?.hidden?' hidden ':'')} id="searchWizardButtons">
                <div>        
                    <Button
                        className="searchWizardOpenButton"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 0)}}
                        startIcon={<SearchIcon />}
                       >חפשו דילים
                    </Button>
                </div>
                {/*<Modal open={open} onClose={this.onCloseModal} style={{margin:0, width:'85%',float:'left',height:'inherit'}}>*/}
                <div>
                    <Button
                        className="searchWizardDestinationsButton"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 3, false)}}
                        startIcon={<FilterList />}
                       >סינון יעדים
                    </Button>
                    {/*<select name="sortBy" value={this.state.filters.sortby} className="sort"
                        onClick={e=>{this.handleToggleFilters(e, 2, false)}}
                        >
                        <option>סינון יעדים</option>
                    </select>*/}
                </div>
            </div>

            <div className="searchWizardButtons" style={{display:'none'}}>

                <div className="searchWizardOptionButtonWrapper">
                    <div className="label">מתי יוצאים לחופשה?</div>
                    <Button
                        className="searchWizardOptionButton"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 1, true)}}
                        startIcon={<CalendarIconOneColor />}
                       >{when_caption}
                    </Button>
                </div>

                <div className={`searchWizardOptionButtonWrapper ` + ((this.props.filters?.dates_type==='exact'/*||this.state.filters?.holidays?.length>0*/)?'hidden':'')}>
                    <div className="label">לכמה זמן?</div>
                    <Button
                        className="searchWizardOptionButton"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 2, true)}}
                        startIcon={<NightsStay />}
                       >{nights_caption}
                    </Button>
                </div>

                <div className="searchWizardOptionButtonWrapper">
                    <div className="label">רמת המלון המבוקשת</div>
                    <Button
                        className="searchWizardOptionButton"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 3, true)}}
                        startIcon={<Star />}
                       >{stars_caption}
                    </Button>
                </div>

                <div className="searchWizardOptionButtonWrapper">
                    <div className="label">לאן טסים?</div>
                    <Button
                        className="searchWizardOptionButton flight"
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.handleToggleFilters(e, 4, true)}}
                        startIcon={<FlightIcon />}
                       >{destinations_caption}
                    </Button>
                </div>

                <div className="searchWizardOptionButtonWrapper ">
                    <div className="label">&nbsp;</div>
                    <Button
                        className={this.props.blinkButton?'searchWizardOptionButton submit blinkButton':'searchWizardOptionButton submit'}
                        variant='contained'
                        color='primary'
                        onClick={e=>{this.props.handleSubmit(e, 'desktop')}}
                       >מצא לי חופשה זולה!
                    </Button>
                </div>

            </div>
            
            <Calendar 
                open={this.props?.calendar?true:false} 
                range={this.props.filters?.dates} 
                dates_type={this.props.filters?.dates_type} 
                sendCalendarData={this.props.handleCalendarChange} 
                onCloseCalendar={this.props.handleCloseCalendar}>
            </Calendar>
           
            <Dialog 
                fullScreen 
                open={this.props.open}  
                onClose={e=>{this.props.handleClose(e)}} 
                scroll="paper"
                id="search_wizard" > 
                <AppBar style={{ position: 'relative', textAlign:'center'}}>
                    <Toolbar style={{background:'#124b71'}} tabIndex={0}>
                        <IconButton edge="start" color="inherit" onClick={e=>{this.props.handleClose(e)}} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" style={{flex: 1}}>
                            חיפוש חופשה
                        </Typography>
                        <Box component="span" m={2}></Box>
                    </Toolbar>

                    <Stepper 
                          
                        activeStep={
                            this.props?.step && this.props?.step*1>0 ?
                                this.props?.step*1:
                                0
                            } 
                            className={this.props.step=='4'?'hidden':''} >
                        
                        <Step key={0} title="שלב ראשון - בחירת הרכב נוסעים"      onClick={e=>this.props.gotoStep(e,'0')}>
                            <StepButton></StepButton>
                        </Step>
                    
                        <Step key={1} title="שלב שני - בחירת מועדי החופשה"       onClick={e=>this.props.gotoStep(e,'1')}>
                            <StepButton></StepButton>
                        </Step>
                        <Step key={2} title="שלב 3 - שחירת העדפות המלון"         onClick={e=>this.props.gotoStep(e,'2')}>
                            <StepButton></StepButton>
                        </Step>
                        <Step key={3} title="שלב 4 - בחירת מאפייני חופשה נוספים" onClick={e=>this.props.gotoStep(e,'3')}>
                            <StepButton ></StepButton>
                        </Step>                                   
                    </Stepper>
                </AppBar>
                <DialogContent style={{marginBottom:'15px'}}>
                    <div className='window'>
                        <a name="step1"></a>
                        <div className={this.props?.step==='0'?'step1':'hidden'} id='step1'>
                            <Pax 
                                filters={this.props.filters}
                                open={this.props?.step==='0'}
                                ModifyAdults={this.props.ModifyAdults}
                                ModifyChildren={this.props.ModifyChildren}
                                ModifyInfants={this.props.ModifyInfants}
                                ModifyTeens={this.props.ModifyTeens}
                            ></Pax>
                        </div>

                        <a name="step2"></a>
                        <div className={this.props?.step==='1'?'step2':'hidden'} id='step2' role="group" aria-label="בחרו מתי תרצו לצאת לחופשה" >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1 tabIndex={0}>מתי תרצו לצאת לחופשה?</h1> 
                                    
                                    <div className='notification'  tabIndex={0}>
                                        <WbIncandescentIcon 
                                            className='icon'
                                        />טיפ: סמנו כמה שיותר חודשים  לקבלת התוצאות הזולות ביותר
                                    </div>


                                </Grid>

                                <Grid item  xs={cellsPerRow===4?3:4} sm={cellsPerRow===4?3:4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters?.months?.length==0&&this.props?.filters?.holidays?.length==0&&!this.props?.filters?.from_date)?'contained':'outlined'}
                                    color='primary'
                                    onClick={e=>this.props.toggleMonth('all')}
                                    style={{fontWeight:800}}
                                >כל השנה
                                </Button>
                                </Grid>
                                {
                                    monthsArray.map((month,index)=>{
                                        return (
                                            <Grid item  xs={cellsPerRow===4?3:4} sm={cellsPerRow===4?3:4} key={month+index}>
                                                <Button
                                                    className="searchToggleButton"
                                                    variant={(this.props.filters?.months?.find(item=>item===month?.value)?'contained':'outlined')}
                                                    color='primary'
                                                    onClick={e=>this.props.toggleMonth(month?.value)}
                                                    disabled={!month.name}
                                                >
                                                    <strong>{month?.name?.split(' ')[0]}</strong>{month?.name?.split(' ')[1]}
                                                    {month?.year}
                                                </Button>
                                            </Grid>
                                        )}
                                    )
                                }
                            </Grid>
                            <Grid container spacing={2}  className='mt-1'>
                                {this.state?.all_holidays?.map((holiday,index)=>{
                                    return (
                                        <Grid item xs={6} key={holiday+index}>
                                            <Button
                                                className="searchToggleButton dates"
                                                variant={this.props.filters?.holidays.find(item=>item===holiday?.uid)?'contained':'outlined'}
                                                color='primary'
                                                onClick={e=>this.props.toggleHoliday(holiday.uid,holiday.holidays_translations.name, holiday.start_date, holiday.end_date)} 
                                                startIcon={<img src={`${require('../../../assets/images/icons/'+holiday.uid+'.svg')}`} alt='' />}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <strong>{holiday.holidays_translations.name} </strong> 
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {holiday?.start_date?.split('-')[0]}
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                    )
                                })}                                

                                <Grid item xs={12} style={{marginTop:'1em'}}>
                                    <Button
                                        className="searchToggleButton dates calendar"
                                        variant={this.props.filters?.from_date!='' &&  (!this.props.filters?.holidays||this.props.filters?.holidays=='')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleCalendar(e)} 
                                        startIcon={<CalendarIcon />}
                                    >
                                        <Grid>
                                            <Grid item xs={12} style={{fontWeight:800,fontSize:'1.1em'}}><strong>{this.props.filters?.from_date && (!this.props.filters?.holidays||this.props.filters?.holidays=='')?dates_caption:'בחירת תאריכים '}</strong></Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>

                        <a name="step3"></a>
                        <div className={this.props?.step==='2'?'step3':'hidden'} id='step3'>


                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>רמת המלון</h1> 
                                    ניתן לסמן יותר מאפשרות אחת
                                </Grid>

                                <Grid item xs sm={3}>
                                <Button
                                    className="searchToggleButton pax"
                                    variant={(this.props?.filters?.stars.length==0?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleStars('all')}
                                    style={{padding:'.5em', fontWeight: 800}}
                                >
                                    <span style={{fontSize:'.8em'}}>אין לי<br />העדפה</span>
                                </Button>
                                </Grid>

                                <Grid item xs sm={3}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props?.filters?.stars.find(star=>star==='3')?'contained':'outlined')}
                                    style={{padding:'.5em'}}
                                    color='primary'
                                    onClick={e=>this.props.toggleStars('3')}
                                    title="מלונות 3 כוכבים"
                                >
                                    <span style={{color:'#b39300',fontSize:'1.1em'}}>★★★</span>
                                    <span style={{fontSize:'.8em'}}>3 כוכבים</span>
                                </Button>
                                </Grid>

                                <Grid item xs sm={3}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props?.filters?.stars.find(star=>star==='4')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleStars('4')}
                                    style={{padding:'.5em'}}
                                    title="מלונות 4 כוכבים"
                                >
                                    <span style={{color:'#b39300',fontSize:'1.1em'}}>★★★★</span>
                                    <span style={{fontSize:'.8em'}}>4 כוכבים</span>
                                </Button>
                                </Grid>

                                <Grid item xs sm={3}>
                                <Button
                                title="מלונות 5 כוכבים"
                                    className="searchToggleButton"
                                    variant={(this.props?.filters?.stars.find(star=>star==='5')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleStars('5')}
                                    style={{padding:'.5em'}}
                                >
                                    <span style={{color:'#b39300',fontSize:'1.1em'}}>★★★★★</span>
                                    <span style={{fontSize:'.8em'}}>5 כוכבים</span>
                                </Button>
                                </Grid>
                            </Grid>

                            <Box m={2}></Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>בסיס אירוח מבוקש</h1> 
                                    ניתן לסמן יותר מאפשרות אחת
                                </Grid>

                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters?.meals.length==0?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleMealCode('all')}
                                    style={{fontWeight:800}}
                                ><span style={{fontSize:'.9em'}}>אין לי העדפה</span>
                                </Button>
                                </Grid>

                                {/*<Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters.meals.find(meal=>meal==='RO')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.toggleMealCode('RO')}
                                >
                                    <strong>לינה בלבד</strong>
                                </Button>
                                </Grid>*/}

                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters.meals.find(meal=>meal==='BB')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleMealCode('BB')}
                                >
                                    <strong>ארוחת בוקר</strong>
                                </Button>
                                </Grid>
{/* 
                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters.meals.find(meal=>meal==='HB')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.toggleMealCode('HB')}
                                >
                                    <strong>חצי פנסיון</strong>
                                </Button>
                                </Grid>

                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters.meals.find(meal=>meal==='FB')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.toggleMealCode('FB')}
                                >
                                    <strong>פנסיון מלא</strong>
                                </Button>
                                </Grid>
*/}
                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(this.props.filters.meals.find(meal=>meal==='AI')?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleMealCode('AI')}
                                >
                                    <strong>הכל כלול</strong>
                                </Button>
                                </Grid>
                            </Grid>

                            <Box m={2}></Box>      

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>מאפייני המלון</h1> 
                                    {/*<div className='notification mt-1'>
                                        <WbIncandescentIcon 
                                            className='icon'
                                        />טיפ: לצפיה בדילים הכי זולים שלנו בחרו באפשרות הראשונה
                                    </div>*/}
                                </Grid>

                        
                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleResetSpecialHotel()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={!this.props?.filters?.highlights || this.props?.filters?.highlights=={} || (!this.props?.filters?.highlights?.waterpark && !this.props?.filters?.highlights?.casino && !this.props?.filters?.highlights?.luxury && !this.props?.filters?.highlights?.near_chabad && !this.props?.filters?.highlights?.spa) ?'contained':'outlined'}><strong>אין לי העדפה</strong></Button>
                                </Grid>

                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleWaterpark()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={this.props?.filters?.highlights?.waterpark?'contained':'outlined'}><strong>פארק מים</strong></Button>
                                </Grid>

                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleCasino()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={this.props?.filters?.highlights?.casino?'contained':'outlined'}><strong>קזינו</strong></Button>
                                </Grid>

                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleLuxury()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={this.props?.filters?.highlights?.luxury?'contained':'outlined'}><strong>מלון יוקרה</strong></Button>
                                </Grid>
                            
                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleNearChabad()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={this.props?.filters?.highlights?.near_chabad?'contained':'outlined'}>
                                            <strong>קרוב לבית חב"ד</strong></Button>
                                </Grid>

                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        onClick={e=>this.props.toggleSpa()}
                                        className="searchToggleButton"
                                        color='primary'
                                        variant={this.props?.filters?.highlights?.spa?'contained':'outlined'}>
                                            <strong>ספא במלון</strong></Button>
                                </Grid>
                                {/*
                                <Grid item xs={4} className="NarrowButton">
                                    <Button 
                                        disabled
                                        onClick={e=>this.props.toggleNearChabad()}
                                        className="searchToggleButton"
                                        variant={this.props?.filters?.near_chabad?'contained':'outlined'}>
                                            <span style={{color:'#fff'}}><strong>באתר סקי (בקרוב)</strong></span></Button>
                                </Grid>
                                 */}

                            </Grid>                      

                            
                        </div>

                        <a name="step4"></a>
                        <div className={this.props?.step==='3'?'step4':'hidden'} id='step4'>

                            <Grid container spacing={2} className={this.props.filters?.from_date!=='' && this.props.filters?.to_date!=='' && this.props.filters?.dates_type!=='range' ?'hidden':''}>
                                <Grid item xs={12}>
                                    <h1>מספר לילות מבוקש</h1> 
                                    ניתן לסמן יותר מאפשרות אחת
                                </Grid>

                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.length==0?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('all')} 
                                    ><strong>הכל</strong>
                                    </Button>
                                </Grid>

                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.find(night=>night==='3')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('3')} 
                                    ><strong>3</strong>
                                    לילות
                                    </Button>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.find(night=>night==='4')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('4')} 
                                    ><strong>4</strong>
                                    לילות
                                    </Button>
                                </Grid>                                        
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.find(night=>night==='5')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('5')} 
                                    ><strong>5</strong>
                                    לילות
                                    </Button>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.find(night=>night==='6')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('6')} 
                                    ><strong>6</strong>
                                    לילות
                                    </Button>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.nights?.find(night=>night==='7')?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleNights('7')} 
                                    ><strong>7</strong>
                                    לילות
                                    </Button>
                                </Grid>
                            </Grid>
                             

                            <Box m={2}></Box>

                            <Grid container spacing={2} className={this.props.filters?.from_date!=='' && this.props.filters?.to_date!=='' && this.props.filters?.dates_type!=='range' ?'hidden':''}>
                                <Grid item xs={12}>
                                    <h1>זמן בשבוע</h1> 
                                </Grid>

                                <Grid item xs sm={4}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={(!this.props.filters?.dow || this.props.filters?.dow.length==0 || this.props.filters?.dow.length==7?'contained':'outlined')}
                                        color='primary'
                                        onClick={e=>this.props.toggleDOW('all')}
                                        style={{fontWeight:800}}
                                    >כל השבוע
                                    </Button>
                                </Grid>
                                <Grid item xs sm={4}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.dow && this.props.filters?.dow.length>0 && ['sun','mon','tue','wed','thu'].every(i=>this.props.filters?.dow.includes(i))?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleDOW('mon,sun,thu,tue,wed')} >
                                        אמצע השבוע
                                    </Button>
                                </Grid>
                                <Grid item xs sm={4}>
                                    <Button
                                        className="searchToggleButton"
                                        variant={this.props.filters?.dow && this.props.filters?.dow.length>0 && ['fri','sat'].every(i=>this.props.filters.dow.includes(i))?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleDOW('fri,sat')} 
                                    >סופי שבוע
                                    </Button>
                                </Grid>                        
                            </Grid>

                            <Box m={2}></Box>
                            
                            
{/*                           
                            <Box m={2}></Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>שירותי המלון</h1>
                                    סימון אפשרות זו תצמצם את כמות היעדים והמלונות בהתאם לבחירתכם 
                                </Grid>

                                <Grid item  xs={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={(!this.props?.filters?.waterpark&&!this.props?.filters?.casino&&!this.props?.filters?.kosher?'contained':'outlined')}
                                    color='primary'
                                    onClick={e=>this.props.toggleResetSpecialHotel()}
                                    style={{fontWeight:800}}>
                                        <span style={{fontSize:'.8em'}}>אין לי העדפה</span>
                                </Button>
                                </Grid>

                                <Grid item xs sm={4}>
                                <Button
                                    className="searchToggleButton"
                                    variant={this.props?.filters?.waterpark?'contained':'outlined'}
                                    color='primary'
                                    onClick={e=>this.props.toggleWaterpark()}
                                    style={{padding:'.5em'}}>
                                        <strong><span style={{fontSize:'.8em'}}>מלונות עם פארק מים</span></strong>
                                </Button>
                                </Grid>

                                <Grid item xs sm={4}>
                                <Button
                                    disabled
                                    className="searchToggleButton"
                                    variant={this.props?.filters?.casino?'contained':'outlined'}
                                    color='primary'
                                    onClick={e=>this.props.toggleCasino()}
                                    style={{padding:'.5em'}}>
                                        <strong><span style={{fontSize:'.8em', color:'#bbb'}}>מלונות עם קזינו (בקרוב)</span></strong>
                                </Button>
                                </Grid>
                 
                                <Grid item xs sm={3}>
                                <Button
                                    disabled
                                    className="searchToggleButton"
                                    variant={this.props?.filters?.kosher?'contained':'outlined'}
                                    color='primary'
                                    onClick={e=>this.props.toggleKosher()}
                                    style={{padding:'.5em'}}>
                                        <strong><span style={{fontSize:'.8em',color:'#bbb'}}>מלונות כשרים (בקרוב)</span></strong>
                                </Button>
                                </Grid>

                            </Grid>
*/}
                            <Box m={2}></Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1>לאן תרצו לצאת לחופשה?</h1> 
                                    <div className='notification mt-1'>
                                        <WbIncandescentIcon 
                                            className='icon'
                                        />טיפ: לצפיה בדילים הכי זולים שלנו בחרו באפשרות הראשונה
                                    </div>
                                </Grid>
                                <Grid  item xs sm={3}>
                                    <Button 
                                    className="searchToggleButton"
                                    onClick={e=>this.props.resetDestinations(e)}
                                    style={{padding:'.5em'}}
                                    color='primary'
                                    variant={(!this.props?.filters?.destinations || this.props?.filters?.destinations.length==0?'contained':'outlined')}><strong>ליעדים הכי משתלמים</strong></Button></Grid>
                                 <Grid  item xs sm={3}>
                                    <Button 
                                    className="searchToggleButton"
                                    onClick={e=>this.props.toggleDestinations(e)}
                                    style={{padding:'.5em'}}
                                    color='primary'
                                    variant={this.props?.filters?.destinations && this.props?.filters?.destinations.length >0?'contained':'outlined'}><strong>
                                        {
                                            this.props?.filters?.destinations && this.props?.filters?.destinations.length > 0?
                                                    `הצג  ${this.props?.filters?.destinations.length+''} יעדים שבחרתי ` 
                                                :
                                                    'בחירת יעד'
                                        }
                                    </strong></Button>
                                </Grid>
                            </Grid>

                            <Box m={2}></Box>

                            

                            <Grid container spacing={2} 
                                className={
                                    // if
                                    (
                                        // Check if either the July & August month buttons were selected
                                        (   
                                            this.props?.filters?.months?.find(month=>month.includes('-07')) || 
                                            this.props?.filters?.months?.find(month=>month.includes('-08')) 
                                        ) &&  // But none of the other months were selected
                                            !this.props?.filters?.months?.find(month=>month.includes('-01')) &&  
                                            !this.props?.filters?.months?.find(month=>month.includes('-02')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-03')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-04')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-05')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-06')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-09')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-10')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-11')) && 
                                            !this.props?.filters?.months?.find(month=>month.includes('-12')) 
                                    ) || // OR
                                    (
                                        // If the dates range selected isbetween July & August
                                        this.props.filters.from && 
                                        this.props.filters.from.getMonth()>=6 && 
                                        this.props.filters.from.getMonth()<=7 && 
                                        this.props.filters.to && 
                                        this.props.filters.to.getMonth()>=6 && 
                                        this.props.filters.to.getMonth()<=7 
                                        /*
                                        this.props?.filters?.from && 
                                        this.props?.filters?.from?.split('-')[0]>=6 && 
                                        this.props?.filters?.from?.split('-')[0]<=7 && 
                                        this.props?.filters?.to && 
                                        this.props?.filters?.to?.split('-')[0]>=6 && 
                                        this.props?.filters?.to?.split('-')[0]<=7
                                        */
                                    ) || // OR
                                    (
                                        this.props.filters.holidays.find(holiday=>holiday.includes('school-summer-vacation'))
                                    )
                                    ?
                                    'hidden': // Show pax selection - <<<<< 2023-05-20 we've changed it to hidden by default in any case, because we no longer want to show this option
                                    'hidden'
                                }>
                                <Grid item xs={12}>
                                    <h1>הרכב הנוסעים</h1> 
                                </Grid>
                                <Grid item xs sm={4}>
                                    <Button
                                        className="searchToggleButton pax"
                                        variant={this.props.filters?.adults==2&&this.props.filters?.children==0?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleChildren(0)}
                                        startIcon={<PeopleIcon />}
                                    >
                                        <strong>זוג</strong>
                                    </Button>
                                    </Grid>

                                    <Grid item xs sm={3}>
                                    <Button
                                        className="searchToggleButton pax"
                                        variant={this.props.filters?.adults==2&&this.props.filters?.children==2?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleChildren(2)}
                                        startIcon={<FamilyRestroomIcon />}
                                    >
                                        <strong>זוג + 2</strong>
                                    </Button>
                                    </Grid>

                                    <Grid item xs sm={3}>
                                    <Button
                                        className="searchToggleButton pax"
                                        variant={this.props.filters?.adults==2&&this.props.filters?.children==3?'contained':'outlined'}
                                        color='primary'
                                        onClick={e=>this.props.toggleChildren(3)}
                                        startIcon={<FamilyRestroomIcon />}
                                    >
                                        <strong>זוג + 3</strong>
                                    </Button>
                                    </Grid>
                            </Grid>
                        </div>
                        
                        <a name="step5"></a>
                        <div className={this.props?.step==='4'?'step5':'hidden'} id='step5'>        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1>איפה תרצו לנפוש?</h1> 
                                <div className='notification' style={{margin:'1em 0 0'}}>
                                <WbIncandescentIcon 
                                    className='icon'
                                />טיפ: סמנו "כל היעדים" לקבלת התוצאות הזולות ביותר
                            </div>
                                
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <List>
                                <ListItem key={'all'} role={undefined} dense button >
                                <FormControlLabel
                                    className="list_all_items"
                                    value='all'
                                    control={<Checkbox color="primary" />}
                                    //label={`כל היעדים (מומלץ)`}
                                    label={<><Typography className='label_select_all_cities'>כל היעדים </Typography><Typography className='label_select_all_cities_recommended'>(מומלץ!)</Typography></>}
                                    labelPlacement="start"
                                    onClick={e=>this.props.toggleDestinationSelection(e, 'all')}
                                    checked={this.props.filters?.destinations.length==0}
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                {
                                    (this.props?.all_destinations&&this.props?.all_destinations?.length>0)?this.props?.all_destinations?.map((value,index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                    <div key={value+index} >
                                        <ListItem role={undefined} dense button >
                                            <FormControlLabel
                                                value={value.city_id}
                                                onClick={e=>this.props.toggleDestinationSelection(e, value.city_id+'')}
                                                control={<Checkbox color="primary" />}
                                                label={<><Typography className='label_city_name'>{value.city_name}</Typography>, {value.country_name}</>}
                                                labelPlacement="start"
                                                tabIndex={-1}
                                                //inputProps={{ 'aria-labelledby': labelId }}
                                                checked={this.props.filters?.destinations.includes(value.city_id+'')}
                                                />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </div>
                                    );
                                }):(<center><CircularProgress /></center>)}
                            </List>
                            </Grid>
                        </Grid>
                        </div>
                    </div>                                  

                </DialogContent>
                <DialogActions>
                    <Button onClick={e=>this.props.handleNext(e)} className='submit-button'>
                        {this.props.step==='3' && !this.props.filters?.window?.next_step_close===true?('הצג תוצאות'):('המשך')}
                    </Button>
                    
                </DialogActions>
            </Dialog>
            
        </>
        )
    }
}

function getNextNMonthNamesWithYear(startDate, endDate, skipMonths=[]) {

    const res = [];

    const monthNames = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
                    'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];                    

    let currentDate = new Date(startDate); // Copy date object (so it won't modify original date by reference)

    while (currentDate <= endDate) {
        const month = currentDate.getMonth();
        const monthName = monthNames[month];
        const year = currentDate.getFullYear();

        if (!skipMonths || skipMonths.length==0 || (skipMonths.length>0 && !(skipMonths.find(m=>((m.split('-')[1]-1)==month && m.split('-')[0]==year))))) {
            res.push({ 
                'name'  : monthName + ' ' + year, 
                'value' : year + '-' + ('0' + (month+1)).slice(-2),
                'month' : month
            });
        }
        currentDate.setMonth(month + 1);
    }    

    // Calculate the number of cells per row
    let cellsPerRow = 3;
    if ((res.length >= 6 && res.length < 8) || res.length > 8) 
        cellsPerRow = 4;

    // make sure the returned items in the array can always be divided by 4 (if not, add empty ones)
    const emptyCells = cellsPerRow-(res.length+1)%cellsPerRow;
 
    if (emptyCells!=cellsPerRow && emptyCells>0)
        for (var i=0 ; i<emptyCells ; i++)
            res.push({
                'name'  : null,
                'value' : null,
                'month' : null
            });

    return res;
}






export default SearchWizard