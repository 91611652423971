import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils,faMapMarkerAlt, faCoffee, faMedal, faDiceFive, faTint, faStarOfDavid, faSpa } from "@fortawesome/free-solid-svg-icons";
import 'react-responsive-modal/styles.css';

class CardLineHotel extends React.Component {
    constructor (props) {
        super(props);
        /*this.state = {
            'active' : false,
            'open': false,
			'open_covid19': false,
            'data': this.props.data,
            'property_photo': null
        }*/
        //this.handleClick = this.handleClick.bind(this); 
        //this.handleMoreDetailsBtn = this.handleMoreDetailsBtn.bind(this); 
    }

    componentDidMount() {
        // initGA();
     }

    GetMealPlanName = (code) => {
        switch(code) {
          case 'HM': return 'חצי פנסיון'; // Breakfast and half board
          case 'MB': return 'חצי פנסיון + משקאות'; //Half board with drinks
          case 'MP': return 'חצי פנסיון'; //Half board
          case 'PC': return 'פנסיון מלא'; //Full board
          case 'PV': return 'פנסיון מלא + משקאות'; //Full board with drinks
          case 'SA': return ''; //Accommodation only
          case 'SP': return ''; //According to programme
          case 'TI': return 'הכל כלול'; //All inclusive
          case 'TP': return 'הכל כלול פרימיום'; //All inclusive premium
          case 'SC': return ''; //Self Catering
          case 'HD': return 'ארוחת בוקר '; //Bed and breakfast
          default: return '';
        }
    };


     render() {
        //const backgroundImage = `../images/destinations/${this.props.data.property.city_name}.jpg`; //this.props.data.destination.city_id; //encodeURI(this.props.data.image);
        const hotel_name = this.props.data.property.name
        let hotel_address = this.props.data.property.address
        if (hotel_address.length > 35)
            hotel_address = hotel_address.substring(0,30) + '...'
        const stars_count = this.props.data.property.stars
        let distance_from_center = Number(this.props.data.property.distance_from_center).toFixed(1) + ' ק"מ'
        if (Number(this.props.data.property.distance_from_center)<1)
            distance_from_center = Number(this.props.data.property.distance_from_center)*1000 + ' מטר'
        //const hotel_stars_caption = this.props.data.property.stars +' כוכבים'        

        return ( 
            <div  style={{textAlign:'right', color:'#333', direction:'ltr', padding: '0.8em', width:'100%', backgroundColor:'#eee'}} title={`מלון ${hotel_name} (${stars_count} כוכבים)`}>
                {/*<h3 style={{fontSize:'1.35em', fontWeight:800}}>מלון {hotel_stars_caption}</h3>*/}
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <h4 style={{fontSize:'1.1em', lineHeight:1, margin:'0 0 0.5em', maxWidth:'220px', textAlign:'left'}}>{hotel_name}</h4>
                    {/*<p style={{color:'#777',maxWidth:'90%', lineHeight:1, fontSize: '0.8em', fontWeight:400}}>{hotel_address}</p>*/}
                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                        <div className='hotelPhoto' style={{backgroundImage: "url(" + (this.props.data.property.photos?`https://cdn.cookiecrumbz.com/images/properties/400/${this.props.data.property.photos[0]}`:'') + ")"}}></div>
                        <div style={{direction: 'rtl', width:'100%', paddingLeft:'0.25em', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <div className="stars" style={{color:'#daa520', fontSize: '1.2em'}} title={stars_count+' כוכבים'}>{this.getStarsImage(stars_count)}</div>
                            <div className={this.props.data.property.room.breakfast_included?'':'hidden'}>
                                <div style={{fontSize:'0.95em', display:'inline-block', color: '#02badb', fontWeight: 700}} className={'div_breakfast ' + (this.GetMealPlanName(this.props.data.property.meal_code)!==''?'':'hide')}>
                                    <FontAwesomeIcon icon={this.props.data.property?.meal_code=='HD'?faCoffee:faUtensils} style={{display:'inline-block', margin:'0 0 0 .3em', fontSize:'0.9em'}} alt={this.GetMealPlanName(this.props.data.property.meal_code)} title={this.GetMealPlanName(this.props.data.property.meal_code)} /> 
                                    {this.GetMealPlanName(this.props.data.property.meal_code)}
                                </div>
                            </div>
                            <div className={this.props.data.property?.has_waterpark?'':'hidden'} style={{color:'#32658a'}}>
                                <FontAwesomeIcon icon={faTint} style={{display:'inline-block', margin:'0 .2em 0 .3em', fontSize:'0.9em'}} alt="פארק מים במלון" title="פארק מים במלון" /> 
                                <div style={{fontSize:'0.9em', display:'inline-block', fontWeight: 700}}>פארק מים במלון</div>
                            </div>
                            <div className={this.props.data.property?.has_casino?'':'hidden'} style={{color: '#994545'}}>
                                <FontAwesomeIcon icon={faDiceFive} style={{display:'inline-block',  margin:'0 .2em 0 .3em', fontSize:'0.9em'}} alt="קזינו במלון" title="קזינו במלון" /> 
                                <div style={{fontSize:'0.9em', display:'inline-block', fontWeight: 700}}>קזינו במלון</div>
                            </div>
                            <div className={this.props.data.property?.near_chabad?'':'hidden'} style={{color: '#0d0d7b'}}>
                                <FontAwesomeIcon icon={faStarOfDavid} style={{display:'inline-block', color: '#0d0d7b', margin:'0 .2em 0 .3em', fontSize:'0.9em'}} alt="בקרבת בית חבד" title="בקרבת בית חבד" /> 
                                <div style={{fontSize:'0.9em', display:'inline-block', fontWeight: 700}}>בקרבת בית חב"ד</div>
                            </div>
                            <div className={this.props.data.property?.has_spa?'':'hidden'} style={{color: '#198754'}}>
                                <FontAwesomeIcon icon={faSpa} style={{display:'inline-block', color: '#198754', margin:'0 .2em 0 .3em', fontSize:'0.9em'}} alt="ספא במלון" title="ספא במלון" /> 
                                <div style={{fontSize:'0.9em', display:'inline-block', fontWeight: 700}}>ספא במלון</div>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faMapMarkerAlt} style={{display:'inline-block', margin:'0 .2em 0 .3em', fontSize:'0.9em'}} alt={distance_from_center+' מהמרכז'} title={distance_from_center+' מהמרכז'} /> 
                                <div style={{fontSize:'0.9em', display:'inline-block'}}>{distance_from_center} מהמרכז</div>
                            </div>
                            <div className={this.props.data.property.room.breakfast_included?'hidden':''}>
                                <FontAwesomeIcon icon={faMedal}  style={{display:'inline-block', margin:'0 .2em 0 .3em', fontSize:'0.85em'}}  alt="מלון מבוקש" title="מלון מבוקש" />
                                <div style={{fontSize:'0.9em', display:'inline-block'}}> מלון מבוקש</div>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div> 
        )
     }

     getStarsImage(num) {
        let output = "";

        for (let i=0;i<num;i++) {
            output+="★";
        }

        return output;
    }

}

export default CardLineHotel