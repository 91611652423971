import ReactGA from "react-ga";

const productionServer = (window.location.host==="www.afnu.co.il");
const site_admins = "98f13708210194c475687be6106a3b84|1f0e3dad99908345f7439f8ffabdffc4|c20ad4d76fe97759aa27a0c99bff6710|3c59dc048e8850243be8079a5c74d079";
let is_admin = false;

 try {

  const afnu_uid = JSON.parse(getCookie("afnu_member")).afnu_uid;
    const admins_regex = new RegExp( site_admins );

    if (afnu_uid.match(admins_regex))
      is_admin = true;

 } catch(e){}
 
 let ga_skip_reason = 'Valid in production only!';
 if (is_admin) ga_skip_reason='User is admin!';
 export const initGA = () => {
    if (!productionServer || is_admin) {
      //console.log("GA INIT CANCELED (not production)")
      return;
    }

    ReactGA.initialize('UA-146395719-1');
 }

// trigger the pageview method from ReactGa
export const PageView = () => {  
    /*ReactGA.pageview(window.location.pathname +  
                     window.location.search); */
    if (!productionServer || is_admin) {
      console.log('GA PAGEVIEW OF "'+window.location.pathname+'" IGNORED ('+ga_skip_reason+')');
      return;
    }

    ReactGA.pageview(window.location.pathname); 
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (category, action, label) => {
    if (!productionServer || is_admin) {
      console.log("GA EVENT ["+category+", "+action+", "+label+"] IGNORED ("+ga_skip_reason+")");
      return;
    }
    
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }